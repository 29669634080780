<template>
	<validation-provider
		:ref="`button_group_${toSnakeCase(label)}`"
		v-slot="{ errors }"
		:immediate="immediate"
		:name="label"
		:rules="rules"
		class="col-md-12 mb-2"
		tag="div"
	>
		<b-form-group :label="isRequired ? `${label}*` : label" :state="!errors[0]" :invalid-feedback="errors[0]">
			<b-button-group>
				<ktv-button
					v-for="button in options"
					:key="button.value"
					:color="value === button.value ? 'primary' : 'gray'"
					:text="button.text"
					@click="onClick(button.value)"
				>
				</ktv-button>
			</b-button-group>
		</b-form-group>
	</validation-provider>
</template>
<script>
	import KtvButton from "./Button"
	import { ValidationProvider } from "vee-validate"

	export default {
		name: "KtvButtonGroup",
		components: { KtvButton, ValidationProvider },
		props: {
			immediate: {
				default: false,
				required: false,
				type: Boolean,
			},
			label: {
				default: "",
				required: false,
				type: String,
			},
			options: {
				default: () => [],
				required: true,
				type: Array,
			},
			rules: {
				default: "",
				required: false,
				type: String,
			},
			value: {
				default: null,
				required: false,
				type: [Boolean, Number, String],
			},
		},
		emits: ["on-click"],
		data() {
			return {
				isMounted: false,
				isRequired: this.rules.search("required") >= 0,
			}
		},
		watch: {
			value: {
				handler(value) {
					if (value && this.isMounted) {
						this.onClick(value)
					}
				},
				immediate: true,
			},
		},
		mounted() {
			this.isMounted = true
		},
		beforeDestroy() {
			this.isMounted = false
		},
		methods: {
			async onClick(value) {
				const isValid = await this.$refs[`button_group_${this.toSnakeCase(this.label)}`].validate(value)

				if (isValid) {
					this.$emit("on-click", value)
				}
			},
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>

<style lang="sass">
	.btn-group > .btn, .btn-group-vertical > .btn
		padding: 6px 12px
</style>
