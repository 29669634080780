import { basicAuth, urlApi } from "@/constants/urlApi"
import { headers } from "@/libs/axios/axiosCall"
import axios from 'axios'
import router from '@/router/routes'
import Vue from 'vue'

export default {
	addProductAddAOPackageSetting({ commit }, product = null) {
		commit('ADD_PRODUCT_ADD_AO_PACKAGE_SETTING', product)
	},
	createProduct({ rootGetters }, {
		districtId = null,
		maximumRecomendation = null,
		price = null,
		priority = null,
		productDescription = null,
		productImage = null,
		productName = null,
		unitInfo = null,
	}) {
		return new Promise((resolve, reject) => {
			const entityOrganizationId = rootGetters['AUTHDATA/userEntityOrganizationID']
			const entityId = rootGetters['AUTHDATA/userId']

			axios
				.post(urlApi.premiumPoint.createProduct, {
					dateModified: Date.now(),
					districtId: districtId,
					maximumRecomendation: maximumRecomendation,
					modifiedBy: entityId,
					partnerEntityId: entityOrganizationId,
					pricePerUnit: price,
					priority: priority,
					productDescription: productDescription,
					productImage: productImage,
					productName: productName,
					unitInfo: unitInfo,
				}, {
					...basicAuth,
					...headers
				})
				.then((res) => {
					const statusMessage = res?.statusText || `${productName} ${Vue.prototype.$t("has been created", "has been created")}`

					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, statusMessage, "success")

					resolve(districtId)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || `${Vue.prototype.$t("Unable to create", "Unable to create")} ${productName}`

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	async createDisbursePremiumPoint({ commit }, {
		districtId = null,
		endDate = null,
		hubArea = null,
		startDate = null,
		unit = null,
	}) {
		const disburseList = await axios
			.post(urlApi.premiumPoint.generate, {
				districtId: districtId,
				endDate: endDate,
				hubArea: hubArea,
				startDate: startDate,
				unit: unit,
			}, {
				...basicAuth,
				...headers
			})
			.then((res) => res)
			.catch((error) => {
				const err = error.toJSON()
				const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

				Vue.prototype.$swal(err.name, errorMessage, "error")

				return null
			})

		if (disburseList.status === 200 && disburseList.data.data.length > 0) {
			commit('SET_DISBURSE_LIST', {
				...disburseList.data,
				endDate: endDate,
				hubArea: hubArea,
				startDate: startDate,
				unit: unit,
			})

			router.push({
				name: "Premium Points Disburse Generated",
			})
		} else {
			const errorMessage = disburseList?.statusText || Vue.prototype.$t("Please make sure input the right filter to generate datas", "Please make sure input the right filter to generate datas")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_DISBURSE_LIST', disburseList.data || null)
		}
	},
	createDisbursePremiumPointChecked(_, invoices = null) {
		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.premiumPoint.generateChecked, invoices, {
					...basicAuth,
					...headers
				})
				.then(res => {
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to disburse premium points", "Unable to disburse premium points")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	createProductPriority(_, product = null) {
		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.premiumPoint.createProductPriority, product, {
					...basicAuth,
					...headers
				})
				.then(res => {
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to create product priority", "Unable to create product priority")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	deleteAOPackageSetting({ dispatch }, {
		params,
		product: {
			ptPremiumPriorityId,
			ptPremiumProductId,
		}
	}) {
		return new Promise((resolve, reject) => {
			axios
				.delete(urlApi.premiumPoint.deleteProductPriority({
					priorityId: ptPremiumPriorityId,
					productId: ptPremiumProductId,
				}), {
					...basicAuth,
					...headers
				})
				.then(res => {
					const successMessage = res?.statusText || Vue.prototype.$t("AO package setting has been deleted", "AO package setting has been deleted")
					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")

					dispatch('getAOPackageSettingProductDetail', params)
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to delete AO package setting", "Unable to delete AO package setting")
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")

					reject()
				})
		})
	},
	deletePremium({ dispatch }, id = null) {
		return new Promise((resolve, reject) => {
			axios
				.delete(urlApi.premiumPoint.deletePremium(id), {
					...basicAuth,
					...headers
				})
				.then(res => {
					const successMessage = res?.statusText || Vue.prototype.$t("Premium has been deleted", "Premium has been deleted")
					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")

					dispatch('getPremiumPointList', {
						limit: 10,
						order: null,
						page: 1,
						search: null,
					})
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to delete premium", "Unable to delete premium")
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")

					reject()
				})
		})
	},
	deleteProduct({ dispatch }, { districtId, partnerEntityId, productId }) {
		return new Promise((resolve, reject) => {
			axios
				.delete(urlApi.premiumPoint.deleteProduct(productId), {
					...basicAuth,
					...headers
				})
				.then(res => {
					const successMessage = res?.statusText || Vue.prototype.$t("Product has been deleted", "Product has been deleted")
					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")

					dispatch('getProductList', {
						district_id: districtId,
						partner_entity_id: partnerEntityId,
					})
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to delete product", "Unable to delete product")
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")

					reject()
				})
		})
	},
	deleteProductAddAOPackageSetting({ commit }, productId = null) {
		commit('DELETE_PRODUCT_ADD_AO_PACKAGE_SETTING', productId)
	},
	async getAgriInputFarmerDetail({ commit }, { disburseId = null, farmerId = null }) {
		const farmerDetail = await axios
			.get(urlApi.premiumPoint.farmerDetail(disburseId, farmerId), {
				...basicAuth,
				...headers
			})
			.then((res) => res)
			.catch((error) => {
				const err = error.toJSON()
				const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

				Vue.prototype.$swal(err.name, errorMessage, "error")

				return null
			})

		if (farmerDetail.status === 200) {
			commit('SET_AGRI_INPUT_FARMER_DETAIL', farmerDetail.data)
		} else {
			const errorMessage = farmerDetail?.statusDescription || Vue.prototype.$t("No available agri input farmer detail data", "No available agri input farmer detail data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_AGRI_INPUT_FARMER_DETAIL', farmerDetail.data || null)
		}
	},
	getAgriInputHandoverReport({ commit }, params = null) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.report.agriInputHandover.data,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((agriInputHandoverReport) => {
					commit('SET_AGRI_INPUT_HANDOVER_REPORT', agriInputHandoverReport.data)

					resolve(agriInputHandoverReport.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.statusDescription}` : Vue.prototype.$t("No available agri input handover data", "No available agri input handover data")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					commit('SET_AGRI_INPUT_HANDOVER_REPORT', null)

					reject(error)
				})
		})
	},
	getAgriInputHandoverReportEvidence(_, {
		disburseId = null,
		farmerId = null,
	}) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.report.agriInputHandover.evidence({
					disburseId: disburseId,
					farmerId: farmerId
				}),
					{
						...basicAuth,
						...headers
					},
				)
				.then((agriInputHandoverReportEvidence) => {
					resolve(agriInputHandoverReportEvidence.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.statusDescription}` : Vue.prototype.$t("No available agri input handover evidence", "No available agri input handover evidence")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")

					reject(error)
				})
		})
	},
	getAOPackageSettingList({ commit }, params) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.aoPackageSettingList,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((aoPackageSettingList) => {
					commit('SET_AO_PACKAGE_SETTING_LIST', {
						data: aoPackageSettingList.data.data,
						totalRows: aoPackageSettingList.data.totalRow,
					})

					resolve(aoPackageSettingList.data.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

					Vue.prototype.$swal(err.name, errorMessage, "error")

					commit('SET_AO_PACKAGE_SETTING_LIST', {
						data: [],
						totalRows: [],
					})

					reject(error)
				})
		})
	},
	getAOPackageSettingProductDetail({ commit }, params) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.aoPackageSettingProductDetail,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((aoPackageSettingProductDetail) => {
					commit('SET_AO_PACKAGE_SETTING_PRODUCT_DETAIL', aoPackageSettingProductDetail.data.data)
					resolve(aoPackageSettingProductDetail)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

					Vue.prototype.$swal(err.name, errorMessage, "error")

					commit('SET_AO_PACKAGE_SETTING_PRODUCT_DETAIL', [])
					reject(error)
				})
		})
	},
	async getCVCHead({ commit }, districtId = null) {
		const cvcHead = await axios
			.get(urlApi.premiumPoint.cvcHead(districtId), {
				...basicAuth,
				...headers
			})
			.then((res) => res)
			.catch((error) => {
				const err = error.toJSON()
				const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

				Vue.prototype.$swal(err.name, errorMessage, "error")

				return null
			})

		if (cvcHead.status === 200) {
			commit('SET_CVC_HEAD', cvcHead.data.data)
		} else {
			const errorMessage = cvcHead?.statusDescription || Vue.prototype.$t("No available CVC head data", "No available CVC head data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_CVC_HEAD', cvcHead.data.data || null)
		}
	},
	getCVCTransactionReport({ commit }, params = null) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.report.cvcTransaction,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((cvcTransactionReport) => {
					commit('SET_CVC_TRANSACTION_REPORT', cvcTransactionReport.data)

					resolve(cvcTransactionReport.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.statusDescription}` : Vue.prototype.$t("No available cvc transaction report data", "No available cvc transaction report data")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					commit('SET_CVC_TRANSACTION_REPORT', null)

					reject(error)
				})
		})
	},
	getDisburseDetail({ commit }, params = null) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.disburseDetail,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((disburseDetail) => {
					commit('SET_DISBURSE_DETAIL', disburseDetail.data)

					resolve(disburseDetail.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.statusDescription}` : Vue.prototype.$t("No available disburse detail data", "No available disburse detail data")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					commit('SET_DISBURSE_DETAIL', null)

					reject(error)
				})
		})
	},
	getDisburseDetailAgriInput({ commit }, params = null) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.disburseDetailAgriInput,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((disburseDetail) => {
					commit('SET_DISBURSE_DETAIL', disburseDetail.data)

					resolve(disburseDetail.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.statusDescription}` : Vue.prototype.$t("No available disburse detail data", "No available disburse detail data")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					commit('SET_DISBURSE_DETAIL', null)

					reject(error)
				})
		})
	},
	getPremiumPaymentReport({ commit }, params = null) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.report.premiumPayment,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((premiumPaymentReport) => {
					commit('SET_PREMIUM_PAYMENT_REPORT', premiumPaymentReport.data)

					resolve(premiumPaymentReport.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.statusDescription}` : Vue.prototype.$t("No available premium payment report data", "No available premium payment report data")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					commit('SET_PREMIUM_PAYMENT_REPORT', null)

					reject(error)
				})
		})
	},
	async getPremiumPointList({ commit }, params) {
		const premiumPointList = await axios
			.get(
				urlApi.premiumPoint.list,
				{
					params: params,
					...basicAuth,
					...headers,
				},
			)
			.then((res) => res)
			.catch((error) => {
				const err = error.toJSON()
				const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

				Vue.prototype.$swal(err.name, errorMessage, "error")

				return null
			})

		if (premiumPointList.status === 200) {
			commit('SET_PREMIUM_POINT_LIST', {
				premiumPointList: premiumPointList.data.data,
				totalRows: premiumPointList.data.total
			})
		} else {
			const errorMessage = premiumPointList?.statusText || Vue.prototype.$t("No available premium point list data", "No available premium point list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PREMIUM_POINT_LIST', {
				premiumPointList: premiumPointList.data.data || [],
				totalRows: 0
			})
		}
	},
	getProductList({ commit }, params = null) {
		return new Promise((resolve, reject) => {
			axios
				.get(
					urlApi.premiumPoint.productList,
					{
						params: params,
						...basicAuth,
						...headers,
					},
				)
				.then((productList) => {
					commit('SET_PRODUCT_LIST', {
						productList: productList.data.data,
						totalRows: productList.data.data.length
					})

					resolve(productList.data.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

					Vue.prototype.$swal(err.name, errorMessage, "error")

					commit('SET_PRODUCT_LIST', {
						productList: [],
						totalRows: 0
					})

					reject(error)
				})
		})
	},
	getReport(_, { disburseId, type }) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.report.agriInput({ disburseId: disburseId, type: type }), {
					...basicAuth,
					...headers
				})
				.then((report) => {
					resolve(report.data.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

					Vue.prototype.$swal(err.name, errorMessage, "error")

					reject(error)
				})
		})
	},
	getReportExport(_, { disburseId, extension = null, type }) {
		return new Promise((resolve, reject) => {
			if (extension === 'pdf') {
				axios
					.get(urlApi.premiumPoint.report.pdf({ disburseId: disburseId, type: type }), {
						...basicAuth,
						...headers
					})
					.then((report) => {
						resolve(report.data.data)
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						Vue.prototype.$swal(err.name, errorMessage, "error")

						reject(error)
					})
			} else if (extension === 'xlsx') {
				axios
					.get(urlApi.premiumPoint.report.xlsx({ disburseId: disburseId, type: type }), {
						...basicAuth,
						...headers
					})
					.then((report) => {
						resolve(report.data.data)
					})
					.catch((error) => {
						const err = error.toJSON()
						const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

						Vue.prototype.$swal(err.name, errorMessage, "error")

						reject(error)
					})
			} else {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('Please select export file type', 'Please select export file type'), "error")
				reject()
			}
		})
	},
	getTransactionReport(_, disburseId) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.premiumPoint.report.transaction(disburseId), {
					...basicAuth,
					...headers
				})
				.then((transactionReport) => {
					resolve(transactionReport.data.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

					Vue.prototype.$swal(err.name, errorMessage, "error")

					reject(error)
				})
		})
	},
	resetAgriInputFarmerDetail({ commit }) {
		commit("RESET_AGRI_INPUT_FARMER_DETAIL")
	},
	resetAgriInputHandoverReport({ commit }) {
		commit("RESET_AGRI_INPUT_HANDOVER_REPORT")
	},
	resetAOPackageSettingProductDetail({ commit }) {
		commit("RESET_AO_PACKAGE_SETTING_PRODUCT_DETAIL")
	},
	resetCVCHead({ commit }) {
		commit("RESET_CVC_HEAD")
	},
	resetCVCTransactionReport({ commit }) {
		commit("RESET_CVC_TRANSACTION_REPORT")
	},
	resetDisburseDetail({ commit }) {
		commit("RESET_DISBURSE_DETAIL")
	},
	resetDisburseList({ commit }) {
		commit("RESET_DISBURSE_LIST")
	},
	resetPremiumPaymentReport({ commit }) {
		commit("RESET_PREMIUM_PAYMENT_REPORT")
	},
	resetProductAddAOPackageSetting({ commit }) {
		commit("RESET_ADD_AGRI_INPUT_LIST")
	},
	async sendNotifToFC(_, { amount, disburseId }) {
		const sendNotifFc = await axios
			.get(urlApi.premiumPoint.sendNotifFc(disburseId), {
				...basicAuth,
				...headers
			})
			.then((res) => res)
			.catch((error) => {
				const err = error.toJSON()
				const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

				Vue.prototype.$swal(err.name, errorMessage, "error")

				return null
			})

		if (sendNotifFc.status === 200) {
			const statusMessage = sendNotifFc?.statusDescription || `${amount} ${Vue.prototype.$t('premium points successfuly disbursed to farmers', 'premium points successfuly disbursed to farmers')}`

			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, statusMessage, "success")
		} else {
			const errorMessage = sendNotifFc?.statusDescription || Vue.prototype.$t("Something bad happen when disburse. Try again later", "Something bad happen when disburse. Try again later")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}
	},
	updateAOPackageSettingProductPriority({ dispatch }, { aoQuestionNumber, params, priority, productName, productPriorityId }) {
		return new Promise((resolve, reject) => {
			axios
				.put(urlApi.premiumPoint.updateAOPackageSettingProductPriority, {
					aoQuestionNumber: String(aoQuestionNumber),
					priority: String(priority),
					productPriorityId: String(productPriorityId),
				}, {
					...basicAuth,
					...headers
				})
				.then(res => {
					const statusMessage = res?.statusDescription || `${productName} ${Vue.prototype.$t('priority has been updated', 'priority has been updated')}`

					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, statusMessage, "success")

					dispatch('getAOPackageSettingProductDetail', params)
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to update product priority", "Unable to update product priority")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	updateDisburseStatus(_, { disburseId, flag, virtualAccount }) {
		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.premiumPoint.updateDisburseStatus, {
					premiumDisburseId: String(disburseId),
					flag: flag,
					vaid: String(virtualAccount),
				}, {
					...basicAuth,
					...headers
				})
				.then(res => {
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to update disburse status", "Unable to update disburse status")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	updateDisburseStatusFee(_, { disburseId, flag, virtualAccount }) {
		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.premiumPoint.updateDisburseStatusFee, {
					premiumDisburseId: String(disburseId),
					flag: flag,
					vaid: String(virtualAccount),
				}, {
					...basicAuth,
					...headers
				})
				.then(res => {
					resolve(res.data)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || Vue.prototype.$t("Unable to update disburse status", "Unable to update disburse status")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	updateProduct(_, {
		districtId = null,
		maximumRecomendation = null,
		price = null,
		priority = null,
		productDescription = null,
		productId = null,
		productImage = null,
		productName = null,
		unitInfo = null,
	}) {
		return new Promise((resolve, reject) => {
			axios
				.put(urlApi.premiumPoint.updateProduct, {
					maximumRecomendation: maximumRecomendation,
					premiumName: productName,
					premiumProductId: productId,
					priority: priority,
					productDescription: productDescription,
					productImage: productImage,
					productUnitName: unitInfo,
					salePrice: price,
				}, {
					...basicAuth,
					...headers
				})
				.then((res) => {
					const statusMessage = res?.statusText || `${productName} ${Vue.prototype.$t('has been updated', 'has been updated')}`

					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")} !`, statusMessage, "success")

					resolve(districtId)
				})
				.catch((err) => {
					const errorMessage = err?.statusText || `${Vue.prototype.$t("Unable to update", "Unable to update")} ${productName}`

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	uploadProductImage(
		_,
		file = null,
	) {
		let formData = new FormData()
		formData.append('bucket_name', "koltiva-loan")
		formData.append('file_data', file)
		formData.append('s3_dest_path', `premium-point/product/image/`)

		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.others.uploadImage, formData)
				.then(res => {
					resolve(res.data.results.s3_url)
				})
				.catch((err) => {
					const errorMessage = err?.error_message || Vue.prototype.$t("Unable to upload product image", "Unable to upload product image")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	updateProductAddAOPackageSetting({ commit }, product = null) {
		commit('UPDATE_PRODUCT_ADD_AO_PACKAGE_SETTING', product)
	},
}