import { headers } from "@/libs/axios/axiosCall"
import { urlApi } from "@/constants/urlApi"
import axios from 'axios'
import router from '@/router/routes'
import Vue from 'vue'

export default {
	async createInstitution({ rootGetters }, institution) {
		const userId = rootGetters['AUTHDATA/userId']
		const createInstitution = await axios
			.post(urlApi.institution.create, {
				loanInstitution: {
					...institution,
					createdBy: parseInt(userId, 10)
				},
				loanInstitutionStaff: [{ ...institution.institutionStaffList, createdBy: parseInt(userId, 10) }],
			}, headers)
			.then((res) => res)
			.catch(() => null)
		if (createInstitution.status === 200) {
			const successMessage = createInstitution?.statusText || Vue.prototype.$t("Institution has been created", "Institution has been created")

			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")

			router.push({
				name: "Loan Institution List",
			})
		} else {
			const errorMessage = createInstitution?.statusText || Vue.prototype.$t("Unable to create institution", "Unable to create institution")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}
	},
	async createInstitutionStaff({ dispatch }, {
		birthDate,
		birthPlace,
		createdBy,
		loanInstitutionId,
		organisationRole,
		personName,
	}) {
		const institutionStaff = await axios
			.post(urlApi.institution.staff.create, {
				birthDate: birthDate,
				birthPlace: birthPlace,
				createdBy: createdBy,
				loanInstitutionId: loanInstitutionId,
				organisationRole: organisationRole,
				personName: personName,
			}, headers)
			.then((res) => res)
			.catch(() => null)

		if (institutionStaff.status === 200) {
			const successMessage = institutionStaff?.statusText || Vue.prototype.$t("Staff has been created", "Staff has been created")

			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")
		} else {
			const errorMessage = institutionStaff?.statusText || Vue.prototype.$t("Unable to create staff", "Unable to create staff")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}

		if (loanInstitutionId) {
			dispatch('getInstitutionStaffs', {
				loanInstitutionId: loanInstitutionId,
				params: {
					limit: 10,
					order: null,
					page: 1,
					search: null,
				},
			})
		}

	},
	async deleteInstitution({ dispatch }, institutionId = null) {
		const institution = await axios
			.delete(urlApi.institution.delete(institutionId), headers)
			.then((res) => res)
			.catch(() => null)

		if (institution.status === 200) {
			const successMessage = institution?.statusText || Vue.prototype.$t("Institution has been deleted", "Institution has been deleted")

			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")
		} else {
			const errorMessage = institution?.statusText || Vue.prototype.$t("Unable to delete institution", "Unable to delete institution")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}

		dispatch('getInstitutionList')
	},
	async deleteInstitutionStaff({ dispatch, getters }, staffId = null) {
		const institutionStaff = await axios
			.delete(urlApi.institution.staff.delete(staffId), headers)
			.then((res) => res)
			.catch(() => null)

		if (institutionStaff.status === 200 && institutionStaff.data.statusDescription === "Success") {
			const successMessage = institutionStaff?.statusText || Vue.prototype.$t("Staff has been deleted", "Staff has been deleted")

			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")
		} else {
			const errorMessage = institutionStaff.data.statusDescription || Vue.prototype.$t("Unable to delete staff", "Unable to delete staff")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}

		dispatch('getInstitutionStaffs', {
			loanInstitutionId: getters.institutionId,
			params: {
				limit: 10,
				order: null,
				page: 1,
				search: null,
			},
		})
	},
	async getInstitution({ commit }, id = null) {
		const institution = await axios
			.get(`${urlApi.institution.view}/${id}`, headers)
			.then((res) => res)
			.catch(() => null)

		if (institution.status === 200) {
			commit('SET_INSTITUTION', institution.data.data)
		} else {
			const errorMessage = institution?.statusText || Vue.prototype.$t("No available institution data", "No available institution data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_INSTITUTION', institution.data.data)
		}
	},
	async getInstitutionList({ commit }, params = null) {
		const institutionList = await axios
			.get(urlApi.institution.list, {
				params: params,
				...headers,
			})
			.then((res) => res)
			.catch(() => null)

		if (institutionList.status === 200) {
			commit('SET_INSTITUTION_LIST', {
				institutionList: institutionList.data.data || [],
				totalRows: institutionList.data.count
			})
		} else {
			const errorMessage = institutionList?.statusText || Vue.prototype.$t("No available institution list data", "No available institution list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_INSTITUTION_LIST', {
				institutionList: institutionList.data.data || [],
				totalRows: 0
			})
		}
	},
	async getInstitutionPackages({ commit }, { institutionId = null, params = null }) {
		const institutionPackages = await axios
			.get(urlApi.institution.package.view(institutionId), {
				params: params,
				...headers,
			})
			.then((res) => res)
			.catch(() => null)

		if (institutionPackages.status === 200) {
			commit('SET_INSTITUTION_PACKAGES', {
				data: institutionPackages.data.data,
				totalRows: institutionPackages.data.count,
			})
		} else {
			const errorMessage = institutionPackages?.statusText || Vue.prototype.$t("No available institution packages data", "No available institution packages data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_INSTITUTION_PACKAGES', {
				data: institutionPackages,
				totalRows: 0,
			})
		}
	},
	async getInstitutionStaffs({ commit }, { loanInstitutionId = null, params = null }) {
		const institutionStaffs = await axios
			.get(urlApi.institution.staff.view(loanInstitutionId), {
				params: params,
				...headers,
			})
			.then((res) => res)
			.catch(() => null)

		if (institutionStaffs.status === 200) {
			commit('SET_INSTITUTION_STAFFS', institutionStaffs.data.data)
		} else {
			const errorMessage = institutionStaffs?.statusText || Vue.prototype.$t("No available institution staffs data", "No available institution staffs data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_INSTITUTION_STAFFS', institutionStaffs)
		}
	},
	resetInstitution({ commit }) {
		commit('RESET_INSTITUTION')
	},
	async updateInstitution({ dispatch }, institution) {
		const yearEstablished = institution.yearEstablished.split("-")

		const updateInstitution = await axios
			.put(urlApi.institution.update, {
				loanInstitution: {
					loanInstitutionId: institution.loanInstitutionId,
					entityId: institution.entityId,
					institutionName: institution.institutionName,
					legalStatus: institution.legalStatus,
					yearEstablished: yearEstablished[0],
					provinceId: institution.provinceId,
					districtId: institution.districtId,
					subDistrictId: institution.subDistrictId,
					villageId: institution.villageId,
					address: institution.address,
					npwp: institution.npwp,
					photo: institution.photo,
					companyLogo: institution.companyLogo,
					email: institution.email,
					phoneNumber: institution.phoneNumber,
					modifiedBy: institution.modifiedBy,
					parentId: institution.parentId,
				},
				loanInstitutionStaff: [
					{
						loanInstitutionStaffId: institution.institutionStaffList.loanInstitutionStaffId,
						personName: institution.institutionStaffList.personName,
						entityId: institution.institutionStaffList.entityId,
						organisationRole: institution.institutionStaffList.organisationRole,
						birthDate: institution.institutionStaffList.birthDate,
						gender: institution.institutionStaffList.gender,
						useremail: institution.institutionStaffList.useremail,
						phoneNumber: institution.institutionStaffList.phoneNumber,
						photo: institution.institutionStaffList.photo,
						modifiedBy: institution.institutionStaffList.modifiedBy
					}
				],
			}, headers)
			.then((res) => res)
			.catch(() => null)

		if (updateInstitution.status === 200) {
			const successMessage = updateInstitution?.statusText || Vue.prototype.$t("Institution has been updated", "Institution has been updated")

			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")

			router.push({
				name: "Loan Institution List",
			})
		} else {
			const errorMessage = updateInstitution?.statusText || Vue.prototype.$t("Unable to update institution", "Unable to update institution")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}

		dispatch('getInstitution', institution.loanInstitutionId)
	},
	async updateInstitutionStaff({ dispatch, getters }, staff) {
		const updateInstitutionStaff = await axios
			.put(urlApi.institution.staff.update, staff, headers)
			.then((res) => res)
			.catch(() => null)

		if (updateInstitutionStaff.status === 200) {
			const successMessage = updateInstitutionStaff?.statusText || Vue.prototype.$t("Staff has been updated", "Staff has been updated")

			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, successMessage, "success")
		} else {
			const errorMessage = updateInstitutionStaff?.statusText || Vue.prototype.$t("Unable to update institution staff", "Unable to update institution staff")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}

		dispatch('getInstitutionStaffs', {
			loanInstitutionId: getters.institutionId,
			params: {
				limit: 10,
				order: null,
				page: 1,
				search: null,
			},
		})
	},
	uploadInstitutionImage(
		_,
		{ file = null, folder = null } = null,
	) {
		let formData = new FormData()
		formData.append('bucket_name', "koltiva-loan")
		formData.append('file_data', file)
		formData.append('s3_dest_path', `loan-institution/image/${folder}/`)

		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.others.uploadImage, formData, headers)
				.then(res => {
					resolve(res.data.results.s3_url)
				})
				.catch((err) => {
					const errorMessage = err?.error_message || `${Vue.prototype.$t("Unable to upload")} ${folder} ${Vue.prototype.$t("Image", "Image").toLowerCase()}`

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
}
