export default {
	setOpsiDisplay(state, data) {
		state.opsiDisplay = data
	},
	setCustomerID(state, data) {
		state.customerID = data
	},
	setError(state, data) {
		state.error = data
	},
}
