import { filter, findWhere } from "underscore"
import Vue from 'vue'

export default {
	ADD_PRODUCT_ADD_AO_PACKAGE_SETTING(state, product) {
		const duplicatePriority = findWhere(state.addProductAOPackageSettingList, {
			priority: product.priority
		})

		if (!duplicatePriority) {
			state.addProductAOPackageSettingList = [
				...state.addProductAOPackageSettingList,
				product
			]
		} else {
			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t("Priority already exist. Something bad happen when saving data. Try again later", "Priority already exist. Something bad happen when saving data. Try again later"), "error")
		}
	},
	DELETE_PRODUCT_ADD_AO_PACKAGE_SETTING(state, productId) {
		state.addProductAOPackageSettingList = filter(state.addProductAOPackageSettingList, (product) => product.premiumProductId !== productId)
	},
	SET_AGRI_INPUT_FARMER_DETAIL(state, farmerDetail) {
		state.agriInputFarmerDetail = farmerDetail
	},
	SET_AGRI_INPUT_HANDOVER_REPORT(state, agriInputHandoverReport) {
		state.agriInputHandoverReport = {
			...agriInputHandoverReport,
			totalRow: agriInputHandoverReport.count || 0
		}
	},
	SET_AO_PACKAGE_SETTING_LIST(state, {
		data = [], totalRows = 0
	}) {
		state.aoPackageSettingList = data
		state.aoPackageSettingTotalRows = totalRows
	},
	SET_AO_PACKAGE_SETTING_PRODUCT_DETAIL(state, aoPackageSettingProductDetail) {
		state.aoPackageSettingProductDetail = aoPackageSettingProductDetail
	},
	SET_CVC_HEAD(state, cvcHead) {
		state.cvcHead = cvcHead
	},
	SET_CVC_TRANSACTION_REPORT(state, cvcTransactionReport) {
		state.cvcTransactionReport = {
			...cvcTransactionReport,
			totalRow: cvcTransactionReport.count || 0
		}
	},
	SET_DISBURSE_DETAIL(state, disburseDetail) {
		state.disburseDetail = disburseDetail
	},
	SET_DISBURSE_LIST(state, disburseList) {
		state.disburseList = disburseList
	},
	SET_DISBURSE_CHECKED_LIST(state, disburseCheckedList) {
		state.disburseCheckedList = disburseCheckedList
	},
	SET_PREMIUM_PAYMENT_REPORT(state, premiumPaymentReport) {
		state.premiumPaymentReport = {
			...premiumPaymentReport,
			totalRow: premiumPaymentReport.count || 0
		}
	},
	SET_PREMIUM_POINT_LIST(state, { premiumPointList, totalRows }) {
		state.premiumPointList = premiumPointList
		state.totalRows = totalRows
	},
	SET_PRODUCT_LIST(state, { productList, totalRows }) {
		state.productList = productList
		state.productTotalRows = totalRows
	},
	RESET_ADD_AGRI_INPUT_LIST(state) {
		state.addProductAOPackageSettingList = []
	},
	RESET_AGRI_INPUT_FARMER_DETAIL(state) {
		state.agriInputFarmerDetail = null
	},
	RESET_AGRI_INPUT_HANDOVER_REPORT(state) {
		state.agriInputHandoverReport = null
	},
	RESET_AO_PACKAGE_SETTING_PRODUCT_DETAIL(state) {
		state.aoPackageSettingProductDetail = []
	},
	RESET_CVC_HEAD(state) {
		state.cvcHead = null
	},
	RESET_CVC_TRANSACTION_REPORT(state) {
		state.cvcTransactionReport = null
	},
	RESET_DISBURSE_DETAIL(state) {
		state.disburseDetail = null
	},
	RESET_DISBURSE_LIST(state) {
		state.disburseList = null
	},
	RESET_PREMIUM_PAYMENT_REPORT(state) {
		state.premiumPaymentReport = null
	},
	UPDATE_PRODUCT_ADD_AO_PACKAGE_SETTING(state, product) {
		let matchedProduct = findWhere(state.addProductAOPackageSettingList, {
			premiumProductId: product.premiumProductId
		})

		if (matchedProduct) {
			matchedProduct.priority = product.priority
		}
	},
}
