import AxiosCall from "../../../libs/axios/axiosCall"
import { urlApi } from "@/constants/urlApi"

export default {
	pushData({ commit }, payload) {
		commit("setTransactionCode", payload.transactionCode)
		commit("setOpsiDisplay", payload.opsiDisplay)
	},
	clearData({ commit }) {
		commit("setTransactionCode", null)
		commit("setOpsiDisplay", null)
	},
	async getDataDetail({ getters, commit }) {
		let payload = {
			transactionCode : getters.getTransactionCode
		}
		if (getters.getOpsiDisplay === 'View' || getters.getOpsiDisplay === 'Update') {
			return new Promise((resolve, reject) => { 
				AxiosCall.Get(payload, urlApi.transaction.detail)
					.then((response) => {  
						if (response.success) {
							// commit("setSuccess", response.data) 
							resolve(response.results)  
						} else {
							commit("setError", { message: response.error_message })
							reject(response)
						}
					})
					.catch((error) => {
							commit("setError", error)
						reject(error)
					})
			})
		} 
		return null;
	}

}
