// Farm Retail Routes

const routesFR = [
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		redirect: "/app/sessions/signIn",
		children: [
			// sme
			{
				path: "/sme",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 61,
							requiresUser: true,
						},
						path: "list",
						component: () =>
							import("@/views/modules/sme/SmeGrid"),
					},
					{
						meta: {
							ParentId: 61,
							requiresUser: true,
						},
						path: "mainForm",
						component: () =>
							import("@/views/modules/sme/MainForm"),
					}
				],
			},
			// transaction
			{
				path: "/transaction",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 63,
							requiresUser: true,
						},
						path: "list",
						component: () =>
							import("@/views/modules/transaction/TransactionGrid"),
					},
					{
						meta: {
							ParentId: 63,
							requiresUser: true,
						},
						path: "mainform",
						component: () =>
							import("@/views/modules/transaction/MainForm"),
					}
				],
			},
			// customer
			{
				path: "/customer",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 65,
							requiresUser: true,
						},
						path: "list",
						component: () =>
							import("@/views/modules/customer/CustomerGrid"),
					},
					{
						meta: {
							ParentId: 65,
							requiresUser: true,
						},
						path: "mainform",
						component: () =>
							import("@/views/modules/customer/MainForm"),
					}
				],
			},
			// dashboard
			{
				path: "/frdboard",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 65,
							requiresUser: true,
						},
						path: "dboardmain",
						component: () =>
							import("@/views/modules/dashboard-farmretail/Dashboard"),
					},
				],
			},
			{
				path: "/usrdboard",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 65,
							requiresUser: true,
						},
						path: "list",
						component: () =>
							import("@/views/modules/user-dashboard/UserDashboardList"),
					},
					{
						meta: {
							ParentId: 61,
							requiresUser: true,
						},
						path: "detail",
						component: () =>
							import("@/views/modules/user-dashboard/UserDashboardDetail"),
					}, 
					{
						meta: {
							ParentId: 61,
							requiresUser: true,
						},
						path: "form",
						component: () =>
							import("@/views/modules/user-dashboard/UserDashboardForm"),
					}
				],
			},
			// Notification
			{
				path: "/notification",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 65,
							requiresUser: true,
						},
						path: "broadcast-notif",
						component: () =>
							import("@/views/modules/notification/BroadcastNotif"),
					},
				],
			},
		]
	}

]

export default routesFR
