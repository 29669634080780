import { BootstrapVue, BootstrapVueIcons, SkeletonPlugin } from "bootstrap-vue"
// import vuePerfectScrollbar from "vue-perfect-scrollbar";
import VueTour from "vue-tour"
import VueTagsInput from "@johmun/vue-tags-input"
import VueTheMask from "vue-the-mask"
import Vuelidate from "vuelidate"
import VueFormWizard from "vue-form-wizard"
import VueLazyload from "vue-lazyload"
import Meta from "vue-meta"
import FlagIcon from "vue-flag-icon"

import "@/assets/styles/sass/themes/lite-purple.scss"
import "@/plugins/echarts"
import "@/plugins/apexChart.js"
import "@/plugins/sweetalert2.js"
// import DateRangePicker from "vue2-daterange-picker"
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
import "v2-datepicker/lib/index.css" // v2 need to improt css
import V2Datepicker from "v2-datepicker"
import "vue-navigation-bar/dist/vue-navigation-bar.css"

// import the library
import VueNavigationBar from "vue-navigation-bar"

import VeeValidate from "vee-validate"
import CKEditor from "@ckeditor/ckeditor5-vue2"

export default {
	install(Vue) {
		Vue.use(BootstrapVue)
		Vue.component("VueNavigationBar", VueNavigationBar)
		Vue.component(
			"LargeSidebar",
			// The `import` function returns a Promise.
			() => import("../containers/layouts/largeSidebar"),
		)
		Vue.component("VuePerfectScrollbar", () => import("vue-perfect-scrollbar"))
		// Vue.component(VueCropper);
		Vue.use(Meta, {
			keyName: "metaInfo",
			attribute: "data-vue-meta",
			ssrAttribute: "data-vue-meta-server-rendered",
			tagIDKeyName: "vmid",
			refreshOnceOnNavigation: true,
		})
		Vue.use(V2Datepicker)
		Vue.use(FlagIcon)
		// vueslider
		// Vue.component("VueSlider", () => import("vue-slider-component"));
		// vuelidate
		Vue.use(Vuelidate)
		// maskForm
		Vue.use(VueTheMask)
		// form wizard globally

		Vue.use(VueFormWizard)

		// tags input plugin
		Vue.use(VueTagsInput)

		// smart table plugin
		// Vue.use(SmartTable);

		// import VueCropper from "./plugins/imageCropper";

		// Use v-calendar & v-date-picker components
		// Vue.use(VCalendar, {
		//   componentPrefix: "v" // Use <vc-calendar /> instead of <v-calendar />
		//   // ...other defaults
		// });

		Vue.use(VueTour)
		Vue.use(VueLazyload, {
			observer: true,
			// optional
			observerOptions: {
				rootMargin: "0px",
				threshold: 0.1,
			},
		})
		Vue.use(VeeValidate, {
			inject: true,
			fieldsBagName: "veeFields",
			errorBagName: "veeErrors",
		})
		VeeValidate.Validator.extend('verify_password', {
			// eslint-disable-next-line no-unused-vars
			getMessage: field => `Password must contain at least: capital letter, number, and symbol`,
			validate: value => {
				// eslint-disable-next-line no-useless-escape
				var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
				return strongRegex.test(value);
			}
		});
		Vue.use(CKEditor)
		Vue.use(BootstrapVueIcons)
		Vue.use(SkeletonPlugin)
	},
}
