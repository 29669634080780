<template>
	<validation-observer :ref="`form_${toSnakeCase(label)}`" v-slot="{ invalid }" tag="form" @submit.prevent="submit()">
		<slot name="content" :invalid="invalid" />
	</validation-observer>
</template>

<script>
	import { ValidationObserver } from "vee-validate"

	export default {
		name: "KtvForm",
		components: {
			ValidationObserver,
		},
		props: {
			label: {
				default: null,
				required: true,
				type: String,
			},
		},
		emits: ["submit"],
		methods: {
			async submit() {
				const isValid = await this.$refs[`form_${this.toSnakeCase(this.label)}`].validate()

				if (isValid) {
					this.$emit("submit")
				} else {
					window.scrollTo({ top: 0, behavior: "smooth" })
				}
			},
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>
