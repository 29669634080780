export default {
	clearError(state) {
		state.error = null
	},
	setError(state, data) {
		state.error = data
		state.loggedInUser = null
		state.loading = false
	},
	setIsUserLoggedIn(state, data) {
		state.isUserLoggedIn = data
	},
	setLoading(state, data) {
		state.loading = data
		state.error = null
	},
	setLogout(state) {
		state.loggedInUser = null
		state.isUserLoggedIn = false
		state.loading = false
		state.error = null
		// this.$router.go("/");
	},
	setPage(state, data) {
		state.page = data.fullPath
	},
	setUser(state, data) {
		state.loggedInUser = data
		state.isUserLoggedIn = true
		state.loading = false
		state.error = null
	},
}
