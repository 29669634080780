export default {
	changeThemeMode({ commit }) {
		commit("toggleThemeMode")
	},
	changeThemeLayout({ commit }, data) {
		commit("toggleThemeLayout", data)
	},
	changeThemeRtl({ commit }) {
		commit("toggleThemeRtl")
	},
}
