/**
 * Getters Example
  export function getExample(state){
  return state.ExampleData;
  }
*/
export function getTotalRecords(state) {
	return state.TotalRecords
} 
export function getLoanID(state) {
	return state.loanID
}
export function getCashProductApproved(state) {
	return state.CashProductApproved
}
export function getSummaryResult(state) {
	return state.SummaryResult
} 



