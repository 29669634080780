import { findWhere, reject } from 'underscore'

export default {
	ADD_PACKAGE_PRODUCT(state, product) {
		if (!state.package || !state.package.products) {
			state.package = {
				...state.package,
				products: []
			}
		}

		const existingProduct = findWhere(state.package.products, { ProductID: product.ProductID })

		if (existingProduct) {
			existingProduct.quantity = parseInt(existingProduct.quantity, 10)
			existingProduct.quantity += parseInt(product.quantity, 10)
			existingProduct.totalPrice = existingProduct.quantity * existingProduct.SalePrice
		} else {
			state.package.products = [
				...state.package.products,
				product
			]
		}
	},
	DELETE_PACKAGE_PRODUCT(state, productId) {
		state.package.products = reject(state.package.products, (product) => product.ProductID === productId)
	},
	SET_PACKAGE(state, pkg) {
		state.package = pkg
	},
	SET_PACKAGE_LIST(state, { data, totalRows }) {
		state.packageList = data
		state.totalRows = totalRows
	},
	SET_PACKAGE_PRODUCT_LIST(state, products) {
		state.package = {
			...state.package,
			products: products
		}
	},
	RESET_PACKAGE(state) {
		state.package = {
			countryId: null,
			createdBy: null,
			createdDate: null,
			currencyId: null,
			districtId: null,
			documentRequired: [],
			kioskId: null,
			kioskName: null,
			loanCostumerTypeId: null,
			loanInstitutionId: null,
			loanPackageId: null,
			loanPackageTypeId: null,
			margin: null,
			packageName: null,
			provinceId: null,
			provision: null,
		}
	},
	RESET_PACKAGE_PRODUCT_LIST(state) {
		state.package = {
			...state.package,
			products: []
		}
	},
	UPDATE_PACKAGE_PRODUCT(state, product) {
		const existingProduct = findWhere(state.package.products, { ProductID: product.ProductID })

		if (existingProduct) {
			existingProduct.quantity = parseInt(product.quantity, 10)
			existingProduct.totalPrice = existingProduct.quantity * existingProduct.SalePrice
		}
	},
}
