const urlAuth = process.env.VUE_APP_URL_API
const urlLoan = process.env.VUE_APP_URL_API_LOAN
const urlPayment = process.env.VUE_APP_URL_API_PAYMENT
const urlPremiumPoint = process.env.VUE_APP_URL_API_PREMIUM_POINT

const basicAuth = {
	auth: {
		username: process.env.VUE_APP_BASIC_AUTH_USERNAME,
		password: process.env.VUE_APP_BASIC_AUTH_PASSWORD
	}
}

const urlApi = {
	auth: {
		login: urlAuth + `signin`,
	},
	contract: {
		agreement: (contractId) => `${urlLoan}ContractAgreement/${contractId}`,
		disburse: {
			checkOrderStatus: (contractId) => `${urlLoan}loanContract/${contractId}/productStatus`,
			create: `${urlLoan}loanContract/disburse/create`,
			sendToRetailer: `${urlLoan}loanContract/sendToRetailer`,
		},
		list: `${urlLoan}loanContract`,
		view: (contractId) => `${urlLoan}loanContract/${contractId}`,
	},
	customer: {
		list: urlAuth + `customer/list`,
		detail: urlAuth + `customer/get-detail`,
		getProvince: urlAuth + `customer/get-province`,
		getDistrict: urlAuth + `customer/get-district`,
		getSubDistrict: urlAuth + `customer/get-sub-district`,
	},
	dboard: {
		dboardValue: urlAuth + `dashboard`,
	},
	group: {
		list: urlAuth + `group/getList`,
		detail: urlAuth + `group/getDetail`,
		listUserGroup: urlAuth + `group/getUserList`,
		create: urlAuth + `group/create`,
		update: urlAuth + `group/update`,
		delete: urlAuth + `group/delete`,
		getAksi: urlAuth + `group/getAksi`,
	},
	institution: {
		create: `${urlLoan}loanInstitution/create`,
		delete: (institutionId) => `${urlLoan}loanInstitution/delete/${institutionId}`,
		list: `${urlLoan}loanInstitution`,
		package: {
			view: (institutionId) => `${urlLoan}loanInstitution/${institutionId}/package`,
		},
		staff: {
			create: `${urlLoan}loanInstitution/staff/create`,
			delete: (staffId) => `${urlLoan}loanInstitution/staff/delete/${staffId}`,
			update: `${urlLoan}loanInstitution/staff/update`,
			view: (loanInstitutionId) => `${urlLoan}loanInstitution/${loanInstitutionId}/staff`,
		},
		update: `${urlLoan}loanInstitution/update`,
		view: `${urlLoan}loanInstitution`,
	},
	lang: {
		language: urlAuth + `language/data`,
	},
	loanapplication: {
		lender: urlLoan + `listOfValue/orgName`,
		status: urlLoan + `listOfValue/loanStatus`,
		category: urlLoan + `listOfValue/category`,
		deliveryType: urlLoan + `listOfValue/deliveryType`,
		listOfBank: urlLoan + `listOfValue/listOfBank`,
	},
	menu: {
		list: urlAuth + `group/getMenu`,
	},
	notification: {
		createNotif: urlAuth + `notification/send`,
	},
	others: {
		categories: `${urlLoan}listOfValue/category`,
		countries: `${urlPremiumPoint}getCountry`,
		currencies: `${urlLoan}currency`,
		customerTypes: `${urlLoan}listOfValue/costumerType`,
		documentTypes: `${urlLoan}listOfValue/docType`,
		districts: (id) => `${urlPremiumPoint}getDistrict?ProvinceCode=${id}`,
		genders: `${urlLoan}listOfValue/gender`,
		getCountries: urlAuth + `user/getCountry`,
		getLanguanges: urlAuth + `user/getLanguage`,
		hubArea: `${urlPremiumPoint}premium/getHubArea`,
		kiosKList: (districtId) => `${urlAuth}transaction/premium-point/kiosbydistrict?DistrictID=${districtId}`,
		legalStatusList: `${urlLoan}listOfValue/legalStatus`,
		organisationNameList: `${urlLoan}listOfValue/orgName`,
		organisationRoleList: `${urlLoan}listOfValue/orgRole`,
		packageTypes: `${urlLoan}listOfValue/packageType`,
		paymentMethods: `${urlLoan}listOfValue/paymentMethod`,
		premiumDistricts: `${urlPremiumPoint}premium/getDistrict`,
		premiumStatuses: (type) => `${urlPremiumPoint}premium/getStatusPremiumList?valueType=${type}`,
		productList: (kiosKId) => `${urlAuth}transaction/loan/get-product?EntityID=${kiosKId}`,
		productNames: `${urlPremiumPoint}premium/product/getProductName`,
		provinces: (countryCode) => `${urlPremiumPoint}getProvince?CountryCode=${countryCode}`,
		statusPremium: (valueType) => `${urlPremiumPoint}premium/getStatusPremiumList?valueType=${valueType}`,
		subDistricts: (id) => `${urlPremiumPoint}getSubDistrict?DistrictCode=${id}`,
		unitInfos: `${urlPremiumPoint}premium/product/getUnitInfo`,
		units: `${urlPremiumPoint}premium/getUnit`,
		uploadImage: `${urlAuth}upload-to-s3`,
		villages: (id) => `${urlPremiumPoint}getVillage?SubDistrictCode=${id}`,
	},
	package: {
		create: `${urlLoan}loanPackage/create`,
		list: `${urlLoan}loanPackage`,
		update: `${urlLoan}loanPackage/update`,
		view: (packageId) => `${urlLoan}loanPackage/${packageId}`,
	},
	payment: {
		check: `${urlPayment}check-payment-status`,
		reference: `${urlPayment}get-payment-reference`,
		serviceCharge: `${urlPayment}check-service-charge`,
		submit: `${urlPayment}submit-payment`,
	},
	premiumPoint: {
		aoPackageSettingList: `${urlPremiumPoint}premium/product/getAoPackageSetting`,
		aoPackageSettingProductDetail: `${urlPremiumPoint}premium/product/getProductDetail`,
		createProduct: `${urlPremiumPoint}premium/product/createProduct`,
		createProductPriority: `${urlPremiumPoint}premium/product/createProductPriority`,
		cvcHead: (districtId) => `${urlPremiumPoint}premium/cvc/getCvcHeadData?districtId=${districtId}`,
		deleteCvcData: (cvcid) => `${urlPremiumPoint}premium/cvc/delete/${cvcid}`,
		deletePremium: (id) => `${urlPremiumPoint}premium/premiumPoint/${id}`,
		deleteProduct: (productId) => `${urlPremiumPoint}premium/product/deleteProduct/?premiumProductId=${productId}`,
		deleteProductPriority: ({ productId, priorityId }) => `${urlPremiumPoint}premium/product/deleteProductPriority/?prodId=${productId}&priorityId=${priorityId}`,
		disburseDetail: `${urlPremiumPoint}premium/getDisburseDetail`,
		disburseDetailAgriInput: `${urlPremiumPoint}premium/getDisburseDetailAgriInput`,
		farmerDetail: (disburseId, farmerId) => `${urlPremiumPoint}premium/getRecomenPackageFarmer?farmerUid=${farmerId}&disburseId=${disburseId}`,
		generate: `${urlPremiumPoint}premium/generatePremium`,
		generateChecked: `${urlPremiumPoint}premium/generatePremiumChecked`,
		getCvcData: `${urlPremiumPoint}premium/cvc/getCvcData`,
		getRecomenPackageFarmer: `${urlPremiumPoint}premium/getRecomenPackageFarmer`,
		insertCvcData: `${urlPremiumPoint}premium/cvc/insert`,
		list: `${urlPremiumPoint}premium/getPremiumList`,
		productList: `${urlPremiumPoint}premium/getPremiumProductWeb`,
		productPriorityList: `${urlPremiumPoint}premium/product/getProductPriority`,
		sendNotifFc: (disburseId) => `${urlPremiumPoint}premium/sendNotifFc?disburseId=${disburseId}`,
		sentToCvc: `${urlPremiumPoint}premium/sendOrderPremium`,
		report: {
			agriInput: ({ disburseId, type }) => `${urlPremiumPoint}premium/getReportAgriInput?disburseId=${disburseId}&type=${type}`,
			agriInputHandover: {
				data: `${urlPremiumPoint}premium/getAgriInputHandover`,
				evidence: ({ disburseId, farmerId }) => `${urlAuth}transaction/premium-point/detail?PremiumDisburseID=${disburseId}&FarmerUid=${farmerId}`,
			},
			cvcTransaction: `${urlPremiumPoint}premium/getCvcHandover`,
			pdf: ({ disburseId, type }) => `${urlPremiumPoint}premium/getReportPdf?disburseId=${disburseId}&type=${type}`,
			premiumPayment: `${urlPremiumPoint}premium/getPremiumPaymentHandover`,
			transaction: (disburseId) => `${urlPremiumPoint}premium/getReportTransaction?disburseId=${disburseId}`,
			xlsx: ({ disburseId, type }) => `${urlPremiumPoint}premium/getReportXlsx?disburseId=${disburseId}&type=${type}`,
		},
		updateAOPackageSettingProductPriority: `${urlPremiumPoint}premium/product/updateProductPriority`,
		updateDisburseStatus: `${urlPremiumPoint}premium/updateDisburseStatus`,
		updateDisburseStatusFee: `${urlPremiumPoint}premium/updateDisburseStatusFee`,
		updateProduct: `${urlPremiumPoint}premium/updateProduct`,
	},
	role: {
		detail: urlAuth + `role/getDetail`,
		list: urlAuth + `role/getList`,
		create: urlAuth + `role/create`,
		update: urlAuth + `role/update`,
		groupList: urlAuth + `role/getGroupList`,
		delete: urlAuth + `role/delete`,
	},
	sme: {
		list: urlAuth + `sme/list`,
		create: urlAuth + `sme/save`,
		update: urlAuth + `sme/update`,
		delete: urlAuth + `sme/delete`,
		detail: urlAuth + `sme/get-detail`,
		getPartner: urlAuth + `sme/get-partner`,
		getYearEst: urlAuth + `sme/get-year-established`,
		getLegalStatus: urlAuth + `sme/get-legal-status`,
		getRole: urlAuth + `sme/get-role`,
		getObjectType: urlAuth + `sme/get-object-type`,
		getEducation: urlAuth + `sme/get-education`,
		listRegion: urlAuth + `sme/get-region`,
		getGender: urlAuth + `sme/get-gender`,
		getYesNo: urlAuth + `sme/get-yes-no`,
		createCognito: urlAuth + `cognito/create`,
		resetPasswordCognito: urlAuth + `cognito/forgot-password`,
		listProductSme: urlAuth + `transaction/product`,
		listTransactionSme: urlAuth + `transaction/sales`,
		getCountry: urlAuth + `region/country`,
		getProvince: urlAuth + `region/province`,
		getDistrict: urlAuth + `region/district`,
		getSubDistrict: urlAuth + `region/subdistrict`,
		getVillage: urlAuth + `region/village`,
		getPhoneRegSME: urlAuth + `sme/validation-phone`,
		getReferralCodeSME: urlAuth + `sme/validation-referral`,
		createUserLogin: urlAuth + `sme/user-web`,
	},
	userDashboard: {
		list: urlAuth + 'user-dashboard/list',
		create: urlAuth + 'user-dashboard/add',
		update: urlAuth + 'user-dashboard/update',
		delete: urlAuth + 'user-dashboard/delete',
		detail: {
			get: urlAuth + 'user-dashboard/detail',
		},
	},
	training: {
		list: `${urlAuth}training/get-training-list`,
		commodity: `${urlAuth}training/get-commodity`,
		language: `${urlAuth}training/get-language`,
		category: {
			get: `${urlAuth}training/get-category`,
			getList: `${urlAuth}training/get-category-list`,
			create: `${urlAuth}training/create-category`,
			update: `${urlAuth}training/update-category`,
			delete: `${urlAuth}training/delete-category`,
		},
		subCategory: {
			get: `${urlAuth}training/get-sub-category`,
			create: `${urlAuth}training/create-sub-category`,
			getList: `${urlAuth}training/get-sub-category-list`,
			delete: `${urlAuth}training/delete-sub-category`,
		},
		header: {
			create: `${urlAuth}training/header/submit`,
			update: `${urlAuth}training/header/update`,
			get: `${urlAuth}training/header/get`,
			delete: `${urlAuth}training/header/delete`,
		},
		detail: {
			create: `${urlAuth}training/detail/submit`,
			get: `${urlAuth}training/get-training-detail`,
		},
		uploadImage: `${urlAuth}training/upload-image`,
		partner: {
			get: `${urlAuth}training/get-partner`,
		},
	},
	transaction: {
		list: urlAuth + `transaction/list`,
		detail: urlAuth + `transaction/get-detail`,
		getPayMethod: urlAuth + `transaction/get-payment-method`,
		getProdCateg: urlAuth + `transaction/get-product-category`,
		getSMElist: urlAuth + `transaction/get-sme-list`,
		getProductTrans: urlAuth + `transaction/product-bysme`,
		uploadFile: urlAuth + `transaction/import-transaction`,
	},
	user: {
		detail: urlAuth + `user/getDetail`,
		list: urlAuth + `user/getList`,
		create: urlAuth + `user/create`,
		update: urlAuth + `user/update`,
		delete: urlAuth + `user/delete`,
		getRole: urlAuth + `user/getRole`,
		listEntity: urlAuth + `user/getEntityList`,
		getPartner: urlAuth + `user/getPartner`,
	},
	weather: {
		getSelectionList: `${urlAuth}weather/selection/list`,
		get: `${urlAuth}weather/list`,
		create: `${urlAuth}weather/create`,
		update: `${urlAuth}weather/update`,
		detail: {
			get: `${urlAuth}weather/detail`,
		},
		delete: `${urlAuth}weather/delete`,
	},
	farmer: {
		detail: urlAuth + `farmer/getDetail`,
		detailFarm: urlAuth + `garden/getDetail`,
		getActiveStatus: urlAuth + `farmer/getActiveStatus`,
		getIDtype: urlAuth + `farmer/getIdType`,
		list: urlAuth + `farmer/getList`,
		register: urlAuth + `farmer/register`,
		registerBulk: urlAuth + `farmer/register-bulk`,
		getMaritalStatus: urlAuth + `farmer/getMaritalStatus`,
		getBank: urlAuth + `farmer/getBank`,
		getBankBranch: urlAuth + `farmer/getBankBranch`,
		getBankRelation: urlAuth + `farmer/getBankRelation`,
		resetPassword: urlAuth + `farmer/reset-password`,
		unregister: urlAuth + `farmer/delete-cognito`,
		update: urlAuth + `farmer/update`,
	},
	fieldAgent: {
		list: urlAuth + `field_agent/getList`,
		detail: urlAuth + `field_agent/getMapping`,
		getPartnerFA: urlAuth + `field_agent/getPartner`,
		createRole: urlAuth + `field_agent/createMapping`,
		getDetail: urlAuth + `field_agent/getDetail`,
		update: urlAuth + `field_agent/updateMapping`,
		getFarmer: urlAuth + `field_agent/getFarmer`,
		getCountries: urlAuth + `field_agent/location/getCountry`,
		getProvinces: urlAuth + `field_agent/location/getProvince`,
		getDistricts: urlAuth + `field_agent/location/getDistrict`,
		uploadFarmerAssignment: urlAuth + `field_agent/upload`,
	},
}

export { basicAuth, urlApi, urlAuth, urlLoan, urlPayment }
