// loan
const routesLoan = [
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		// redirect: "/app/sessions/signIn",
		children: [
			{
				path: "/loan",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						path: "application",
						component: () =>
							import(
								"@/views/modules/loan/application/application"
							),
						meta: {
							requiresUser: true,
						}
					},
					{
						path: "addform",
						component: () =>
							import(
								"@/views/modules/loan/application/form/_newform"
							),
						meta: {
							requiresUser: true,
						}
					},
					{
						path: "detail/:id",
						component: () =>
							import(
								"../views/modules/loan/application/form/Form"
							),
						meta: {
							requiresUser: true,
						}
					},
					{
						path: "applicationApproval",
						component: () =>
							import(
								"@/views/modules/loan/applicationapproval/applicationApproval"
							),
						meta: {
							requiresUser: true,
						}
					},
					{
						path: "approval-detail/:id",
						component: () =>
							import(
								"../views/modules/loan/applicationapproval/form/Form"
							),
						meta: {
							requiresUser: true,
						}
					},
					{
						name: "Loan Contract List",
						path: "contract",
						component: () =>
							import("@/views/modules/loan/contract/contract"),
						meta: {
							requiresUser: true,
						}
					},
					{
						name: "Loan Contract Detail",
						path: "contract/:id/:applicationId?",
						component: () =>
							import("@/views/modules/loan/contract/view/Index"),
						meta: {
							requiresUser: true,
						}
					},
					{
						path: "contractLender",
						component: () =>
							import(
								"@/views/modules/loan/contractLender/contractLender"
							),
						meta: {
							requiresUser: true,
						}
					},
					{
						name: "Loan Institution List",
						path: "institution",
						component: () => import("@/views/modules/loan/institution/list/Index"),
						meta: {
							parent: "institution",
							requiresPermissions: ["view_list_all_loan_institution", "view_list_loan_institution"],
							requiresUser: true,
						},
					},
					{
						name: "Loan Institution Add",
						path: "institution/add",
						component: () => import("@/views/modules/loan/institution/list/add/Index"),
						meta: {
							parent: "institution",
							requiresPermissions: ["add"],
							requiresUser: true,
						}
					},
					{
						name: "Loan Institution Update",
						path: "institution/update/:id",
						component: () => import("@/views/modules/loan/institution/list/add/Index"),
						meta: {
							parent: "institution",
							requiresPermissions: ["update"],
							requiresUser: true,
						}
					},
					{
						name: "Loan Institution Data",
						path: "institution/:id",
						component: () => import("@/views/modules/loan/institution/view/Index"),
						meta: {
							parent: "institution",
							requiresPermissions: ["detail"],
							requiresUser: true,
						}
					},
					{
						name: "Loan Package List",
						path: "package",
						component: () => import("@/views/modules/loan/package/list/Index"),
						meta: {
							parent: "institution",
							requiresUser: true,
						},
					},
					{
						name: "Loan Package Add",
						path: "package/add/:institutionId",
						component: () => import("@/views/modules/loan/package/list/add/Index"),
						meta: {
							parent: "institution",
							requiresPermissions: ["add_package"],
							requiresUser: true,
						}
					},
					{
						name: "Loan Package Update",
						path: "package/update/:institutionId/:id",
						component: () => import("@/views/modules/loan/package/list/add/Index"),
						meta: {
							parent: "institution",
							requiresPermissions: ["add_package"],
							requiresUser: true,
						}
					},
					{
						name: "Loan Package Data",
						path: "package/:institutionId/:id",
						component: () => import("@/views/modules/loan/package/view/Index"),
						meta: {
							parent: "institution",
							requiresUser: true,
						}
					},
				]
			}
		]
	}
]

export default routesLoan
