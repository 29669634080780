 
export function getLoanFXRecords(state) {
	return state.LoanFXRecords
}   
export function getLoanFXProduct(state) {
	return state.LoanProductFX
}
export function getLoanPetaniFX(state) {
	return state.LoanPetaniFX
}
export function getLoanBusinnessFX(state) {
	return state.LoanBusinnessFX
}
export function getAmbilBarangFX(state) {
	return state.LoanAmbilBarangFX
} 
export function getPlatformList(state) {
	return state.PlatformList
}
export function getSearchResult(state) {
	return state.SearchResult
}
export function getDocumentList(state) {
	return state.documentTypeList
} 
export function getdistrictName(state) {
	return state.districtName
} 




 


