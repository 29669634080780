<template>
	<b-button class="btn btn-back" @click="$emit('click')">
		<b-row class="d-flex mx-1" align-h="center" align-v="center">
			<img class="imgback" />
			<span class="textback">{{ label }}</span>
		</b-row>
	</b-button>
</template>

<script>
	import IconBack from "@/assets/images/icon/iconbackgreen.png"

	export default {
		name: "KtvBack",
		props: {
			label: {
				default: "Back to List",
				required: false,
				type: String,
			},
		},
		emits: ["click"],
		data() {
			return {
				iconback: IconBack,
			}
		},
	}
</script>
<style>
	.textback {
		margin: 2px 0 0 6px;
	}
	.imgback {
		content: url("./../assets/images/icon/iconbackgreen.png");
	}
	.btn-back {
		border: 0;
		background: #fff;
		margin-left: 0;
		padding-left: 0;
		color: #2bbe72 !important;
	}
	.btn-back:hover {
		box-shadow: none;
		border: 0;
		color: #2e6c4d !important;
		transition: none;
	}
	.btn-back:hover .imgback {
		content: url("./../assets/images/icon/icongreenhover.png");
	}
	.btn-back:active:focus {
		box-shadow: none !important;
		border: 0 !important;
	}
</style>
