import { contains, find } from "underscore"
import defaultRouter from "@/router/default"
import NProgress from "nprogress"
import Router from "vue-router"
import store from "../store"
import Vue from "vue"

Vue.use(Router)

const router = new Router({
	mode: "history",
	linkActiveClass: "open",
	routes: [
		...defaultRouter
	],
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
})

router.beforeEach((to, _from, next) => {
	const isLoggedIn = localStorage.getItem("accessToken")
		&& localStorage.getItem("tokenExpiryKey")
		&& new Date(Date.now()) < new Date(localStorage.getItem("tokenExpiryKey"))

	const loanInstitutionActions = store.getters['AUTHDATA/loanInstitutionActions'] || []
	const getActionPermission = (actions = []) => {
		return find(loanInstitutionActions, (action) => {
			return contains(actions, action.function)
		})
	}

	// If this isn't an initial page load.
	if (to.path) {
		// Start the route progress bar.

		NProgress.start()
		NProgress.set(0.1)
	}

	if (isLoggedIn) {
		const loggedInUser = store.getters["AUTHDATA/loggedInUser"] || null
		const firstMenu = loggedInUser?.menu[0]

		if (to.meta.parent === "institution" && to.meta.requiresPermissions) {
			const isAllowed = getActionPermission(to.meta.requiresPermissions)

			if (isAllowed) {
				next()
			} else if (firstMenu.Child && firstMenu.Child.length > 0) {
				const firstChild = firstMenu.Child[0]

				if (firstChild.MenuModule) {
					next(firstChild.MenuModule)
				} else {
					next({
						name: "dashboard.v1",
					})
				}
			} else if (firstMenu.MenuModule) {
				next(firstMenu.MenuModule)
			} else {
				next({
					name: "dashboard.v1"
				})
			}
		} else if (to.meta.requiresGuest) {
			if (firstMenu && firstMenu.MenuModule && (firstMenu.MenuModule === "/premium-point" || firstMenu.MenuModule === "/loan")) {
				if (firstMenu.Child && firstMenu.Child.length > 0) {
					const firstChild = firstMenu.Child[0]

					if (firstChild.MenuModule) {
						next(firstChild.MenuModule)
					} else {
						next({
							name: "dashboard.v1",
						})
					}
				} else {
					next(firstMenu.MenuModule)
				}
			} else {
				next({
					name: "dashboard.v1",
				})
			}
		} else {
			next()
		}
	} else if (to.meta.requiresUser) {
		store.dispatch("AUTHDATA/logoutJWT")

		next({
			name: "Sign In",
		})
	} else {
		next()
	}
})

router.afterEach(() => {
	// Remove initial loading
	const gullPreLoading = document.getElementById("loading_wrap")
	if (gullPreLoading) {
		gullPreLoading.style.display = "none"
	}

	// Complete the animation of the route progress bar.
	setTimeout(() => NProgress.done(), 500)

	if (window.innerWidth <= 1200) {
		store.dispatch("LARGESIDEBAR/changeSidebarProperties")
		if (store.getters["LARGESIDEBAR/getSideBarToggleProperties"].isSecondarySideNavOpen) {
			store.dispatch("LARGESIDEBAR/changeSecondarySidebarProperties")
		}
	} else if (
		store.getters["LARGESIDEBAR/getSideBarToggleProperties"].isSecondarySideNavOpen
	) {
		store.dispatch("LARGESIDEBAR/changeSecondarySidebarProperties")
	}
})

export default router
