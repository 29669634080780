 
export const setAgriinputRequest = (state, value) => {
	state.AgriinputRequest = value
} 
export const setAgriinputProgress = (state, value) => {
	state.AgriinputProgress = value
} 
export const setAgriinputProgressDetail = (state, value) => {
	state.AgriinputProgressDetail = value
} 
export const setTotalRecordsCvc = (state, value) => {
	state.TotalRecordsCvc = value
} 

