export default {
	getPartner(state) {
        return state.optionPartner
    },
	getProdCateg(state) {
        return state.optionProdCateg
    },
	getPayMethod(state) {
        return state.optionPayMethod
    },
	getProvince(state) {
        return state.optionProvince
    },
	getDistrict(state) {
        return state.optionDistrict
    },
	getSubDistrict(state) {
        return state.optionSubDistrict
    },
    getMasterLender(state) {
        return state.masterLender
    },
    getMasterCategory(state) {
        return state.masterCategory
    },
    getMasterStatus(state) {
        return state.masterStatus
    },
	getGender(state) {
        return state.optionGender
    },
	getCountry(state) {
        return state.optionCountry
    },
	getLanguage(state) {
        return state.optionLanguage
    },
	getYesNo(state) {
        return state.optionYesNo
    },
	getRole(state) {
        return state.optionRole
    },
    getPaymentLoanMethodLists(state) {
        return state.optionLoanMethodList
    },
    getOptionlistOfBank(state) {
        return state.optionlistOfBank
    },
	getPartnerUser(state) {
        return state.optionPartnerUser
    },
} 
