/* eslint-disable no-unused-vars */
import AxiosCall from "../../../libs/axios/axiosCall"
import { urlApi } from "@/constants/urlApi"
import Vue from "vue"

export default {
	pushData({ commit }, payload) {
		commit("setUserID", payload.UserId)
		commit("setOpsiDisplay", payload.opsiDisplay)
	},
	clearData({ commit }) {
		commit("setUserID", null)
		commit("setOpsiDisplay", null)
	},
	async actGetDetail({ getters }) {
		let payload = {
			userId : getters.getUserID
		}
		if (getters.getOpsiDisplay === 'View' || getters.getOpsiDisplay === 'Update') {
			return new Promise((resolve, reject) => { 
				AxiosCall.Get(payload, urlApi.user.detail)
					.then((response) => {  
						if (response.success) {
							resolve(response)  
						} else {
							Vue.prototype.$swal("Failed!", response.error_message, "error")
							reject(response)
						}
					})
					.catch((error) => {
						Vue.prototype.$swal("Failed!", error.response.data.error_message, "error")
					})
			})
		} 
		return null;
	},
	// eslint-disable-next-line no-unused-vars
	async actPutData({ commit }, payload) {
		return new Promise((resolve, reject) => { 
			AxiosCall.Put(payload, urlApi.user.update)
				.then((response) => {  
					if (response.success) {
						resolve(response)  
					} else {
                    	Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
                    Vue.prototype.$swal("Failed!", error.response.data.error_message, "error")
				})
		})
	},
	async actPostData({ commit }, payload) {
		return new Promise((resolve, reject) => { 
			AxiosCall.Post(payload, urlApi.user.create)
				.then((response) => {  
					if (response.success) {
						resolve(response)  
					} else {
                    	Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
                    Vue.prototype.$swal("Failed!", error.response.data.error_message, "error")
				})
		})
	},
	async actDeleteData({ commit }, payload) {
		return new Promise((resolve, reject) => { 
			AxiosCall.Delete(payload, urlApi.user.delete)
				.then((response) => {  
					if (response.success) {
						resolve(response)  
					} else {
                    	Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
                    Vue.prototype.$swal("Failed!", error.response.data.error_message, "error")
				})
		})
		
	},

}
