<template>
	<validation-provider
		:ref="`checkbox_group_${toSnakeCase(label)}`"
		v-slot="{ errors }"
		:immediate="immediate"
		:name="label"
		:rules="rules"
		class="col-md-12 mb-2"
		tag="div"
	>
		<b-form-group :label="isRequired ? `${label}*` : label" :state="!errors[0]" :invalid-feedback="errors[0]">
			<b-form-checkbox-group v-model="localValue" :disabled="disabled" :options="options" @change="onChange" />
		</b-form-group>
	</validation-provider>
</template>
<script>
	import { ValidationProvider } from "vee-validate"

	export default {
		name: "KtvButtonGroup",
		components: { ValidationProvider },
		props: {
			disabled: {
				default: false,
				required: false,
				type: Boolean,
			},
			immediate: {
				default: false,
				required: false,
				type: Boolean,
			},
			label: {
				default: "",
				required: false,
				type: String,
			},
			options: {
				default: () => [],
				required: true,
				type: Array,
			},
			rules: {
				default: "",
				required: false,
				type: String,
			},
			value: {
				default: () => [],
				required: false,
				type: Array,
			},
		},
		emits: ["on-change"],
		data() {
			return {
				isRequired: this.rules.search("required") >= 0,
				localValue: null,
			}
		},
		watch: {
			value: {
				handler(value) {
					this.localValue = value
				},
				immediate: true,
			},
		},
		methods: {
			async onChange(value) {
				const isValid = await this.$refs[`checkbox_group_${this.toSnakeCase(this.label)}`].validate(value)

				if (isValid) {
					this.$emit("on-change", value)
				}
			},
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>
