import { urlApi } from "@/constants/urlApi"
import axios from 'axios'
import Vue from 'vue'

const headers = {
	"application-id": null,
	"token": null,
	"trace-id": process.env.VUE_APP_TRACE_ID,
}

const getApplicationId = (type) => {
	if (type === "loan-to-retailer") {
		return process.env.VUE_APP_LOAN_TO_RETAILER_PAYMENT_APPLICATION_ID
	} if (type === "loan-to-farmer") {
		return process.env.VUE_APP_LOAN_TO_FARMER_PAYMENT_APPLICATION_ID
	} if (type === "premium-point") {
		return process.env.VUE_APP_PREMIUM_POINT_PAYMENT_APPLICATION_ID
	}

	return null
}

const getLanguageId = (locale) => {
	let languageIds = {
		'en': 1,
		'id': 2,
	}

	return languageIds[locale]
}

export default {
	checkServiceCharge({ rootGetters }, { amount, type, paymentMethodId }) {
		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.payment.serviceCharge, {
					data: {
						PaymentMethodID: paymentMethodId,
						TotalPaid: amount
					}
				}, {
					headers: {
						...headers,
						"application-id": getApplicationId(type),
						"token": rootGetters['AUTHDATA/token'],
					}
				})
				.then(res => {
					resolve(res.data.data)
				})
				.catch((err) => {
					const errorMessage = err?.error_message || Vue.prototype.$t("Unable to check payment service charge", "Unable to check payment service charge")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	async getPaymentReference({ commit, rootGetters }, type) {
		const paymentReference = await axios
			.get(urlApi.payment.reference, {
				headers: {
					...headers,
					"application-id": getApplicationId(type),
					"token": rootGetters['AUTHDATA/token'],
				}
			})
			.then((res) => res)
			.catch((error) => {
				let message = ''

				if (error.response?.data.message) {
					message = error.response.data.message
				} else {
					const err = error.toJSON()
					message = err.message
				}

				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, message, "error")

				return error
			})

		if (paymentReference.status === 200) {
			commit('SET_PAYMENT_LANGUAGE_LIST', { data: paymentReference.data.data.Language, type: type })
			commit('SET_PAYMENT_METHOD_LIST', { data: paymentReference.data.data.PaymentMethod, type: type })
			commit('SET_PAYMENT_STATUS_LIST', { data: paymentReference.data.data.PaymentStatus, type: type })
		}
	},
	getPaymentStatus({ rootGetters }, { paymentMethodId, type, uid }) {
		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.payment.check, {
					data: {
						LanguageID: getLanguageId(rootGetters['LANG/locale']),
						PaymentMethodID: paymentMethodId,
						uid: uid,
					}
				}, {
					headers: {
						...headers,
						"application-id": getApplicationId(type),
						"token": rootGetters['AUTHDATA/token'],
					}
				})
				.then(res => {
					resolve(res.data.data)
				})
				.catch((err) => {
					const errorMessage = err?.error_message || Vue.prototype.$t("Unable to check payment status", "Unable to check payment status")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
	submitPayment({ rootGetters }, {
		accountName,
		accountNumber,
		amount,
		bankCode,
		bankName,
		destinationId,
		destinationName,
		destinationType,
		id,
		note,
		paymentMethodId,
		serviceCharge,
		serviceChargeType,
		totalServiceCharge,
		type
	}) {
		return new Promise((resolve, reject) => {
			axios
				.post(urlApi.payment.submit, {
					data: {
						BankCode: bankCode,
						BankName: bankName,
						FCMToken: 0,
						LanguageID: getLanguageId(rootGetters['LANG/locale']),
						Notes: note,
						PaymentDetail: [
							{
								uid: id,
								PaymentMethodID: paymentMethodId,
								ServiceChargeType: serviceChargeType,
								ServiceCharge: serviceCharge,
								TotalServiceCharge: totalServiceCharge,
								TotalPaid: amount,
								TotalPaidWithServiceCharge: amount + serviceCharge,
								DetailNotes: `${note}-detail`,
							}
						],
						PaymentMethodID: paymentMethodId,
						TotalPaid: amount,
						TransactionDate: Date.now(),
						TransactionNumber: id,
						uid: id,

						// SENDER
						SupplyTransID: rootGetters['AUTHDATA/userEntityOrganizationID'], // SENDER entity organization id
						PartnerName: rootGetters['AUTHDATA/userEntityOrganizationName'], // SENDER entity organization name

						// RECEIVER
						SupplierID: destinationId, // RECEIVER id
						SupplierName: destinationName, // RECEIVER name
						SupplierType: destinationType, // RECEIVER type ["Retailer", "Farmer", "CVCHead", "..."]
						AccountNumber: accountNumber, // RECEIVER account number
						AccountName: accountName, // RECEIVER account name
					}
				}, {
					headers: {
						...headers,
						"application-id": getApplicationId(type),
						"token": rootGetters['AUTHDATA/token'],
					}
				})
				.then(res => {
					resolve(res.data.data)
				})
				.catch((err) => {
					const errorMessage = err?.error_message || Vue.prototype.$t("Unable to generate Virtual Account", "Unable to generate Virtual Account")

					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
					reject()
				})
		})
	},
}
