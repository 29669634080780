// import createPersistedState from "vuex-persistedstate"
import Vue from "vue"
import Vuex from "vuex"

import LANG from "./modules/LANG"
import AUTHDATA from "./modules/AUTHDATA/index"
import CONFIG from "./modules/CONFIG/index"
import CONTRACT from "./modules/CONTRACT"
import CUSTOMER from "./modules/CUSTOMER/index"
import INSTITUTION from "./modules/INSTITUTION/index"
import LARGESIDEBAR from "./modules/LARGESIDEBAR/index"
import LOAN from "./modules/LOAN"
import MASTER from "./modules/MASTER/index"
import OTHERS from "./modules/OTHERS"
import PACKAGE from "./modules/PACKAGE"
import PAYMENT from "./modules/PAYMENT"
import PREMIUMPOINT from "./modules/PREMIUMPOINT/index"
import SME from "./modules/SME/index"
import TRAINING from "./modules/TRAINING/index"
import TRANSACTION from "./modules/TRANSACTION/index"
import USERS from "./modules/USERS/index"
import WEATHER from "./modules/WEATHER/index"
import LOANFX from "./modules/LOANFX"
import AGRIINPUTREQUEST from "./modules/AGRIINPUTREQUEST"
import FARMER from "@/store/modules/FARMER"
import FIELDAGENT from "@/store/modules/FIELDAGENT"
// Load Vuex
Vue.use(Vuex)

// Create storee
export default new Vuex.Store({
	modules: {
		LANG,
		AUTHDATA,
		CONFIG,
		CONTRACT,
		CUSTOMER,
		INSTITUTION,
		LARGESIDEBAR,
		LOAN,
		MASTER,
		OTHERS,
		PACKAGE,
		PAYMENT,
		PREMIUMPOINT,
		SME,
		TRAINING,
		TRANSACTION,
		USERS,
		WEATHER,
		LOANFX,
		AGRIINPUTREQUEST,
		FARMER,
		FIELDAGENT,
	},
	// plugins: [createPersistedState()],
})
