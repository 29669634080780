export default {
	RESET_INSTITUTION(state) {
		state.institution = null
	},
	SET_INSTITUTION(state, institution) {
		state.institution = {
			...state.institution,
			data: institution,
		}
	},
	SET_INSTITUTION_LIST(state, { institutionList, totalRows }) {
		state.institutionList = institutionList
		state.totalRows = totalRows
	},
	SET_INSTITUTION_PACKAGES(state, { data, totalRows }) {
		state.institution = {
			...state.institution,
			packages: {
				data: data,
				totalRows: totalRows,
			}
		}
	},
	SET_INSTITUTION_STAFFS(state, institutionStaffs) {
		state.institution = {
			...state.institution,
			staffs: institutionStaffs
		}
	},
}
