/* eslint-disable no-unused-vars */
import AxiosCall from "../../../libs/axios/axiosCall"
import { urlApi } from "@/constants/urlApi"
import Vue from "vue"

export default {
	pushData({ commit }, payload) {
		commit("setEntityID", payload.entityID)
		commit("setObjectID", payload.objectID)
		commit("setOpsiDisplay", payload.opsiDisplay)
		commit("setOptions", payload.options)
	},
	clearData({ commit }) {
		commit("setEntityID", null)
		commit("setObjectID", null)
		commit("setOpsiDisplay", null)
		// commit("setOptions", null)
	},
	async getDataDetail({ getters, commit }) {
		let payload = {
			objectId : getters.getObjectID,
			entityId : getters.getEntityID
		}
		if (getters.getOpsiDisplay === 'View' || getters.getOpsiDisplay === 'Update') {
			return new Promise((resolve, reject) => {
				AxiosCall.Get(payload, urlApi.sme.detail)
					.then((response) => {
						if (response.success) {
							resolve(response.results)
						} else {
							Vue.prototype.$swal("Failed!", response.error_message, "error")
							reject(response)
						}
					})
					.catch((error) => {
						commit("setError", error)
						reject(error)
					})
			})
		}
		return null;
	},
	async postData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(payload, urlApi.sme.create)
				.then((response) => {
					if (response.success) {
						resolve(response)
					} else {
						Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
					Vue.prototype.$swal("Failed!", error, "error")
					reject(error)
				})
		})

	},
	async putData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Put(payload, urlApi.sme.update)
				.then((response) => {
					if (response.success) {
						// commit("setSuccess", response.data)
						resolve(response)
					} else {
						Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
					Vue.prototype.$swal("Failed!", error, "error")
					reject(error)
				})
		})

	},
	async deleteData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Delete(payload, urlApi.sme.delete)
				.then((response) => {
					if (response.success) {
					// commit("setSuccess", response.data)
						resolve(response)
					} else {
						Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
					// if want to show : error.response
					commit("setError", { message: error.response.data.error_message })
					reject(error.response)
				})
		})

	},
	async createCognito({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(payload, urlApi.sme.createCognito)
				.then((response) => {
					if (response.success) {
						// commit("setSuccess", response.data)
						resolve(response)
					} else {
						Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
					// if want to show : error.response
					commit("setError", { message: error.response.data.error_message })
					reject(error.response)
				})
		})

	},
	async resetPasswordCognito({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(payload, urlApi.sme.resetPasswordCognito)
				.then((response) => {
					if (response.success) {
						// commit("setSuccess", response.data)
						resolve(response)
					} else {
						Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
					// if want to show : error.response
					Vue.prototype.$swal("Failed!", error.response, "error")
					commit("setError", { message: error.response.data.error_message })
					reject(error.response)
				})
		})
	},
	async createUserLogin({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(payload, urlApi.sme.createUserLogin)
				.then((response) => {
					if (response.success) {
						// commit("setSuccess", response.data)
						resolve(response)
					} else {
						Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
					// if want to show : error.response
					commit("setError", { message: error.response.data.error_message })
					reject(error.response)
				})
		})

	},

}
