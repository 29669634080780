export default {
	addProductAOPackageSettingList: [],
	agriInputFarmerDetail: null,
	agriInputHandoverReport: null,
	aoPackageSettingList: [],
	aoPackageSettingProductDetail: [],
	aoPackageSettingTotalRows: 0,
	cvcHead: null,
	cvcTransactionReport: null,
	disburseCheckedList: null,
	disburseDetail: null,
	disburseList: null,
	premiumPaymentReport: null,
	premiumPointList: [],
	productList: [],
	productTotalRows: 0,
	totalRows: 0,
}
