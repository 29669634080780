export default {
	toggleThemeMode: (state) => {
		state.themeMode.dark = !state.themeMode.dark
	},
	toggleThemeLayout(state, data) {
		state.themeMode.layout = data
	},
	toggleThemeRtl(state) {
		state.themeMode.rtl = !state.themeMode.rtl
	},
}
