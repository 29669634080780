<template>
	<img :src="iconselect" />
</template>
<script>
	import IconSelect from "@/assets/images/icon/iconselect.png"

	export default {
		data() {
			return {
				iconselect: IconSelect,
			}
		},
	}
</script>
<style>
	.vl {
		border-left: 1px solid green;
		height: 25px;
	}
</style>
