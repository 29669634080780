export default {
	getEntityID(state) {
		return state.entityID
	},
	getOpsiDisplay(state) {
		return state.opsiDisplay
	},
	getError(state) {
		return state.error
	},
	getPartnerFA(state) {
		return state.optionPartnerFA
	}
}