export default {
	RESET_CONTRACT(state) {
		state.contract = null
	},
	SET_CONTRACT(state, contract) {
		state.contract = Object.assign({}, contract)
	},
	setContractID(state, value) {
		state.contractID = value
	},
	setSuccess(state, value) {
		state.success = value
	},
	setTotalRecords(state, value) {
		state.TotalRecords = value
	},
}
