<template>
	<validation-provider
		:ref="`select_${toSnakeCase(label)}`"
		v-slot="{ errors }"
		:immediate="immediate"
		:name="label"
		:rules="rules"
		class="col-md-12 mb-2"
		tag="div"
	>
		<b-form-group :label="isRequired ? `${label}*` : label" :state="!errors[0]" :invalid-feedback="errors[0]" :style="formGroupStyle">
			<v-select
				v-if="reduce"
				v-model="localValue"
				:disabled="disabled"
				:options="options"
				:placeholder="loading ? `${$t('Loading', 'Loading')}...` : `${$t('Select', 'Select')} ${label}`"
				:reduce="(text) => text.value"
				label="text"
				@input="onInput"
			/>
			<v-select
				v-else
				v-model="localValue"
				:disabled="disabled"
				:options="options"
				:placeholder="`${$t('Select', 'Select')} ${label}`"
				:push-tags="true"
				:taggable="taggable"
				label="text"
				@input="onInput"
			/>
		</b-form-group>
	</validation-provider>
</template>

<script>
	import { ValidationProvider } from "vee-validate"

	export default {
		name: "KtvSelect",
		components: {
			ValidationProvider,
		},
		props: {
			disabled: {
				default: false,
				required: false,
				type: Boolean,
			},
			formGroupStyle: {
				default: "",
				required: false,
				type: String,
			},
			immediate: {
				default: false,
				required: false,
				type: Boolean,
			},
			label: {
				default: null,
				required: false,
				type: String,
			},
			loading: {
				default: false,
				required: false,
				type: Boolean,
			},
			options: {
				default: () => [],
				required: true,
				type: Array,
			},
			rules: {
				default: "",
				required: false,
				type: String,
			},
			reduce: {
				default: true,
				required: false,
				type: Boolean,
			},
			taggable: {
				default: false,
				required: false,
				type: Boolean,
			},
			value: {
				default: null,
				required: false,
				type: [String, Number],
			},
		},
		emits: ["input"],
		data() {
			return {
				isRequired: this.rules.search("required") >= 0,
				localValue: null,
			}
		},
		watch: {
			value: {
				handler(value) {
					this.localValue = value
				},
				immediate: true,
			},
		},
		methods: {
			async onInput($event) {
				const isValid = await this.$refs[`select_${this.toSnakeCase(this.label)}`].validate($event)

				if (isValid) {
					this.$emit("input", $event)
				}
			},
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>

<style lang="sass">
	.vs__selected-options
		flex-basis: auto !important
</style>
