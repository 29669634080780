/**
 * State register
 */

export default {
	TotalRecords: 0,
	optionMonth: [],
	optionPlantPhase: [],
	optionHumidity: [],
	optionEvent: [],
	optionRainfall: [],
	optionTemp: [],
}
