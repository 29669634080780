// farmer

const fieldAgentRouter = [
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		redirect: "/app/sessions/signIn",
		children: [
			{
				path: "/field_agent",
				component: () => import("../containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 42,
						},
						path: "list",
						component: () => import("@/views/modules/field_agent/list/listAgent"),
					},
					{
						meta: {
							ParentId: 42,
						},
						path: "detailFieldAgent",
						component: () => import("@/views/modules/field_agent/list/FormAgent"),
					},
				]
			}
		]
	}
]

export default fieldAgentRouter