export default {
	setOption(state, value) {
		if (value.type === "weather_adv_month") state.optionMonth = value.res
		if (value.type === "weather_adv_plant_phase") state.optionPlantPhase = value.res
		if (value.type === "weather_adv_humidity") state.optionHumidity = value.res
		if (value.type === "weather_adv_event") state.optionEvent = value.res
		if (value.type === "weather_adv_rainfall") state.optionRainfall = value.res
		if (value.type === "weather_adv_temp") state.optionTemp = value.res
	},
	setTotalRecords(state, value) {
		state.TotalRecords = value
	},
}
