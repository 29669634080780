/**
 * State register
 */

export default function () {
	return { 
		LoanFXRecords : [],
		LoanProductFX:[],
		LoanPetaniFX:[],
		LoanBusinnessFX:[],
		LoanAmbilBarangFX:[],
		PlatformList:[],
		SearchResult:[],
		documentTypeList:[],
		districtName :[]
	}
}
