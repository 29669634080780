import { each } from "underscore"
import { urlApi, urlLoan, urlAuth } from "@/constants/urlApi"
import axios from 'axios'
import AxiosCall, { headers } from "@/libs/axios/axiosCall"
import router from '@/router/routes'
import Vue from 'vue'

export default {
	/**
 * Action ActionTablesGrid
 * @param payload
 * Tables Grid
 * Method GET
 */
	ActionTablesGrid({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Get(
				Object.assign(payload), urlLoan + 'loanContract/')
				.then((response) => {
					if (response.statusDescription === 'Success' && response.data !== null) {
						commit("setTotalRecords", response.count)
						resolve(response.data)
					} else {
						Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
					}
				})
				.catch((err) => {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
					router.push('/loan/contract')
					reject(err)
				})
		})
	},
	/**
	 * Action ActionUploadS3
	 * @param payload
	 * Method POST
	 */
	ActionUploadS3(_, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(Object.assign(payload), urlAuth + 'upload-to-s3')
				.then((response) => {
					if (response.success === true) {
						resolve(response.results)
					} else {
						Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.error_message, "error")
					}
				})
				.catch((err) => {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.error_message, "error")
					reject(err)
				})
		})
	},
	/**
	 * Action ActionRepaymentGrid
	 * @param payload
	 * Tables Grid
	 * Method GET
	 */
	ActionGetRepayment(_, id = null) {
		return new Promise((resolve, reject) => {
			AxiosCall.GetNoQueryParams(
				id, urlLoan + 'loanInstallment/')
				.then((response) => {
					if (response.statusDescription === 'Success' && response.data !== null) {
						resolve(response.data)
					} else {
						Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
					}
				})
				.catch((err) => {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
					reject(err)
				})
		})
	},
	/**
	 * Action GetDetail
	 * @param payload
	 * Method GET
	 */
	ActionGetDetail(_, id) {
		return new Promise((resolve, reject) => {
			AxiosCall.GetNoQueryParams(id, urlLoan + 'loanContract/')
				.then((response) => {
					if (response.statusDescription === 'Success' && response.data !== null) {
						resolve(response.data)
					} else {
						Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No detail data', 'No detail data'), "error")
						router.push('/loan/contract')
					}
				})
				.catch((err) => {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
					router.push('/loan/contract')
					reject(err)
				})
		})
	},
	/**
	 * Action ActionRepaymentSave
	 * @param payload
	 * Method POST
	 */
	ActionRepaymentSave(_, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(Object.assign(payload), urlLoan + 'loanInstallment/create')
				.then((response) => {
					if (response.statusDescription === "Success") {
						Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, Vue.prototype.$t("Data saved successfully", "Data saved successfully"), "success")
						resolve(response.statusDescription)
					} else {
						Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.error, "error")
					}
				})
				.catch((err) => {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.error, "error")
					reject(err)
				})
		})
	},
	/**
	 * Action ActionDocumentContractSave
	 * @param payload
	 * Method POST
	 */
	ActionDocumentContractSave(_, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(Object.assign(payload), urlLoan + 'loanContract/uploadDocument')
				.then((response) => {
					if (response.statusDescription === "Success") {
						Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, Vue.prototype.$t("Data saved successfully", "Data saved successfully"), "success")
						resolve(response.statusDescription)
					} else {
						Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.error, "error")
					}
				})
				.catch((err) => {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.error, "error")
					reject(err)
				})
		})
	},

	/**
	 * Action ActionCloseContract
	 * @param payload
	 * Method PUT
	 */
	ActionCloseContractSave(_, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Put(Object.assign(payload), urlLoan + 'loanContract/status')
				.then((response) => {
					if (response.statusDescription === "Success") {
						Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, Vue.prototype.$t("Data saved successfully", "Data saved successfully"), "success")
						resolve(response.statusDescription)
					} else {
						Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.error, "error")
					}
				})
				.catch((err) => {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.error, "error")
					reject(err)
				})
		})
	},

	async checkOrderStatus(_, loanContractId) {
		await axios
			.get(urlApi.contract.disburse.checkOrderStatus(loanContractId), headers)
			.then((res) => {
				const openTag = `<b-list-group class="m-4 px-8">`
				const closeTag = `</b-list-group>`
				let list = ''

				if (res.data?.data) {
					each(res.data.data, (product) => {
						list += `
								<b-list-group-item class="d-flex justify-content-between align-items-center">
									<span>${Vue.prototype.$t('Order Status Update', 'Order Status Update')}</span>
									<span>${product.date}</span>
									<b>${product.status}</b>
								</b-list-group-item>
							`
					})
				}
				const html = openTag + list + closeTag

				Vue.prototype.$swal(Vue.prototype.$t("Order Status", "Order Status"), list ? html : Vue.prototype.$t("There is no available order status data", "There is no available order status data"), "info")
			})
	},
	async createDisburse({ rootGetters }, {
		disburseAmount,
		disburseTrxId,
		disburseType,
		loanContractId,
		paymentMethodId,
		response,
	}) {
		const userId = rootGetters['AUTHDATA/userId']

		await axios
			.post(urlApi.contract.disburse.create, {
				createdBy: userId,
				disburseAmount: disburseAmount,
				disburseTrxId: disburseTrxId,
				disburseType: disburseType,
				loanContractId: loanContractId,
				paymentMethod: paymentMethodId,
				response: response,
			}, headers)
			.then((res) => res)
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
			})
	},
	async getContract({ commit }, id = null) {
		const contract = await axios
			.get(urlApi.contract.view(id), headers)
			.then((res) => res)
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				router.push('/loan/contract')
			})

		if (contract.status === 200) {
			commit('SET_CONTRACT', contract.data.data[0])
		} else {
			const errorMessage = contract?.statusText || Vue.prototype.$t("No available contract data", "No available contract data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_CONTRACT', contract)
		}
	},
	getContractAgreement(_, contractId) {
		return new Promise((resolve, reject) => {
			axios
				.get(urlApi.contract.agreement(contractId), headers)
				.then((agreement) => {
					resolve(agreement.data.data)
				})
				.catch((error) => {
					const err = error.toJSON()
					const errorMessage = err.code ? `${err.code}: ${err.message}` : err.message

					Vue.prototype.$swal(err.name, errorMessage, "error")

					reject(error)
				})
		})
	},
	resetContract({ commit }) {
		commit('RESET_CONTRACT')
	},
	async sendToRetailer(_, {
		loanContractId,
		paymentMethod
	}) {
		await axios
			.post(urlApi.contract.disburse.sendToRetailer, {
				loanContractId: loanContractId,
				paymentMethod: paymentMethod,
			}, headers)
			.then((res) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, res.data.data, "success")
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
			})
	},
}
