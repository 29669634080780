const Mixin = {
    methods: {
        makeToast(title, variant, msg) {
            this.$bvToast.toast(msg, {
                title: ` ${title || "default"}`,
                variant: variant,
                solid: true,
            })
        },
		validateState(ref) {
			if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
				return !this.veeErrors.has(ref);
			}
			return null;
		},
        formatDate(thisdate) {
            const newDate = new Date(thisdate)
            let retdate = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear()
            return retdate
        },
        formatNumber(thisdata) {
            return thisdata.toLocaleString('en-US');
        }
    }
};

export default Mixin