// farmer

const routesFarmer = [
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		redirect: "/app/sessions/signIn",
		children: [
			{
				path: "/farmer",
				component: () => import("../containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 40,
						},
						path: "list",
						component: () => import("@/views/modules/farmer/table/FarmerGrid"),
					},

					{
						meta: {
							ParentId: 40,
						},
						path: "detailFarmer",
						component: () => import("@/views/modules/farmer/table/detailFarmer"),
					},
					{
						meta: {
							ParentId: 40,
						},
						path: "registered_list",
						component: () => import("@/views/modules/farmer/registered-list/registered"),
					},
				]
			}
		]
	}
]

export default routesFarmer