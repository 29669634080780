/**
 * Mutate List example
  export const setExample= (state, value) => {
  state.ExampleData = value;
  };
*/
export const setTotalRecords = (state, value) => {
	state.TotalRecords = value
}
export const setLoanID = (state, value) => {
	state.loanID = value
}
export const setSuccess = (state, value) => {
	state.success = value
}

export const setCashProductApproved = (state, value) => {
	state.CashProductApproved = value
}

export const setSummaryResult = (state, value) => {
	state.SummaryResult = value
}
