export function getAgriinputRequest(state) {
	return state.AgriinputRequest
}

export function getAgriinputProgress(state) {
	return state.AgriinputProgress
} 

export function getAgriinputProgressDetail(state) {
	return state.AgriinputProgressDetail
} 

export function getTotalRecordsCvc(state) {
	return state.TotalRecordsCvc
} 






 


