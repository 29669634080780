import { urlApi } from "@/constants/urlApi"
import auth from "@/auth/authService"
import AxiosCall from "@/libs/axios/axiosCall"
import jwt from "jsonwebtoken"

export default {
	isUserLogin({ commit }) {
		let isAuthenticated = false
		if (auth.isAuthenticated()) {
			isAuthenticated = true
		} else {
			isAuthenticated = false
		}

		commit("setIsUserLoggedIn", isAuthenticated)
	},
	loginJWT({ commit }, payload) {
		commit("clearError")
		commit("setLoading", true)

		return new Promise((resolve, reject) => {
			AxiosCall.Post(payload, urlApi.auth.login)
				.then((response) => {
					if (response.success) {
						let userInfo = response.results
						const token = response.results.JWTToken
						const decodedJWT = jwt.decode(token)
						const tokenExpiry = new Date(decodedJWT.exp * 1000)

						localStorage.setItem("accessToken", token)
						localStorage.setItem(
							"userInfo",
							JSON.stringify(userInfo),
						)
						localStorage.setItem("tokenExpiryKey", tokenExpiry)
						localStorage.setItem("loggedIn", true)
						localStorage.setItem('language', JSON.stringify({ id: 1, label: 'en' }))
						localStorage.setItem("ext", 'no')

						commit("setUser", userInfo)

						resolve(response)
					} else {
						commit("setError", { message: response.error_message })
						reject(response)
					}
				})
				.catch((error) => {
					commit("setError", error.response.data)
					reject(error)
				})
		})
	},
	logoutJWT({ commit }) {
		localStorage.removeItem("accessToken")
		localStorage.removeItem("userInfo")
		localStorage.removeItem("tokenExpiryKey")
		localStorage.removeItem("loggedIn")
		localStorage.removeItem("vuex")
		commit("setLogout")
	},
}
