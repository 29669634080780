export default {
	loggedInUser:
		localStorage.getItem("userInfo") != null
			? JSON.parse(localStorage.getItem("userInfo"))
			: null,
	loading: false,
	error: null,
	page: "",
	isUserLoggedIn: false,
}
