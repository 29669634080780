<template>
	<div>
		<download-excel v-if="data && data.length > 0" :data="data" :fields="columns" :name="filename" :type="fileType" :worksheet="sheetname">
			<slot name="button" />
		</download-excel>
		<download-excel v-else :fetch="fetchData" :fields="columns" :name="filename" :type="fileType" :worksheet="sheetname">
			<slot name="button" />
		</download-excel>
	</div>
</template>

<script>
	export default {
		name: "KtvExportXlsx",
		props: {
			columns: {
				default: () => {},
				required: true,
				type: Object,
			},
			data: {
				default: () => [],
				required: false,
				type: Array,
			},
			fetchData: {
				default: () => {},
				required: false,
				type: Function,
			},
			filename: {
				default: null,
				required: true,
				type: String,
			},
			sheetname: {
				default: null,
				required: false,
				type: String,
			},
			fileType: {
				default: "xls",
				required: false,
				type: String,
			},
		},
	}
</script>
