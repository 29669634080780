<template>
	<enlargeable-image :ref="`image_viewer_${toSnakeCase(fileName)}`" :src_large="image" :src="thumbnail ? thumbnail : image" />
</template>

<script>
	import { each } from "underscore"
	import EnlargeableImage from "@diracleo/vue-enlargeable-image"

	export default {
		name: "KtvImageViewer",
		components: { EnlargeableImage },
		props: {
			image: {
				default: null,
				required: false,
				type: String,
			},
			fileName: {
				default: "",
				required: false,
				type: String,
			},
			thumbnail: {
				default: null,
				required: false,
				type: String,
			},
			thumbnailClass: {
				default: "",
				required: false,
				type: String,
			},
		},
		mounted() {
			if (this.thumbnailClass) {
				const imageViewer = this.$refs[`image_viewer_${this.toSnakeCase(this.fileName)}`].$el
				const thumbnails = imageViewer.querySelectorAll(".enlargeable-image > .slot")

				each(thumbnails, (thumbnail) => {
					thumbnail.classList.add(this.thumbnailClass)
				})
			}
		},
		methods: {
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>

<style lang="sass">
	.enlargeable-image
		display: flex!important
		flex-direction: row!important
		justify-content: center!important
		align-items: center!important
		margin: 4px!important

		.full
			&.enlarging, &.enlarged, &.delarging
				align-items: center!important
				background-image: none!important
				background-position: center center!important
				background-repeat: no-repeat!important
				background-size: contain!important
				justify-content: center!important
				z-index: 99999!important
				padding: 10%!important

			&.enlarged
				background-color: rgba(0, 0, 0, 0.60)!important
</style>
