export default {
	changeSidebarProperties({ commit }) {
		commit("toggleSidebarProperties")
	},
	changeSecondarySidebarProperties({ commit }) {
		commit("toggleSecondarySidebarProperties")
	},
	changeSecondarySidebarPropertiesViaMenuItem({ commit }, data) {
		commit("toggleSecondarySidebarPropertiesViaMenuItem", data)
	},
	changeSecondarySidebarPropertiesViaOverlay({ commit }) {
		commit("toggleSecondarySidebarPropertiesViaOverlay")
	},
}
