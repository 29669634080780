export default {
	getUserID(state) {
		return state.userID
	},
	getOpsiDisplay(state) {
		return state.opsiDisplay
	},
	getError(state) {
		return state.error
	}
}
