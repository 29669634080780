export default {
	setPartner(state, value) {
        state.optionPartner = value;
    },
	setProdCateg(state, value) {
        state.optionProdCateg = value;
    },
	setPayMethod(state, value) {
        state.optionPayMethod = value;
    },
	setProvince(state, value) {
        state.optionProvince = value;
    },
	setDistrict(state, value) {
        state.optionDistrict = value;
    },
	setSubDistrict(state, value) {
        state.optionSubDistrict = value;
    },
	clearRegion(state, payload = 'all') {
        if (payload === 'district') {
            state.optionDistrict = [];
        } else if (payload === 'subdistrict') {
            state.optionSubDistrict = [];
        } else {
            state.optionSubDistrict = [];
            state.optionDistrict = [];
        }
    },
    /**
     * Aprianto
     * Loan Application
     */
    setMasterLender(state, value){
        state.masterLender = value
    },
    setMasterCategory(state, value){
        state.masterCategory = value
    },
    setMasterStatus(state, value){
        state.masterStatus = value
    },
    setPaymentLoanMethodList(state, value){
        state.optionLoanMethodList = value
    }, 
    setGender(state, value){
        state.optionGender = value
    },
    setCountry(state, value){
        state.optionCountry = value
    },
    setLanguage(state, value){
        state.optionLanguage = value
    },
    setYesNo(state, value){
        state.optionYesNo = value
    },
    setRole(state, value){
        state.optionRole = value
    },
    setDeliveryType(state, value){
        state.optionDeliveryType = value
    },
    setlistOfBank(state, value){
        state.optionlistOfBank = value
    },
    setPartnerUser(state, value){
        state.optionPartnerUser = value
    }
}
