import Vue from 'vue'
import { urlLoan } from "@/constants/urlApi"
import AxiosCall from "@/libs/axios/axiosCall"
import router from '@/router/routes'


/**
 * Action ActionTablesGrid
 * @param payload
 * Tables Grid
 * Method GET
 */
export const ActionTablesGrid = async ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Get(
			Object.assign(payload), urlLoan + 'loanApplication/')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setTotalRecords", response.count)
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}

/**
 * Action GetDetail
 * @param payload
 * Method GET
 */
export const ActionGetDetail = async ({ rootGetters }) => {
	const loadID = rootGetters['LOAN/getLoanID']
	return new Promise((resolve, reject) => {
		AxiosCall.GetNoQueryParams(`${loadID}`, urlLoan + 'loanApplication/')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No detail data', 'No detail data'), "error")
					router.push('/loan/application')
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				router.push('/loan/application')
				reject(err)
			})
	})
}

export const ActionGetDetailApproval = async ({ rootGetters }) => {
	const loadID = rootGetters['LOAN/getLoanID']
	return new Promise((resolve, reject) => {
		AxiosCall.GetNoQueryParams(`${loadID}`, urlLoan + 'loanApplication/')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No detail data', 'No detail data'), "error")
					router.push('/loan/applicationApproval')
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				router.push('/loan/applicationApproval')
				reject(err)
			})
	})
}


/**
 * Action ActionReject
 * @param payload
 * Method POST
 */
export const ActionReject = ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Put(Object.assign(payload), urlLoan + 'loanApplication/status')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setSuccess", response.data)
					Vue.prototype.$swal(`${Vue.prototype.$t("Loan application rejected", "Loan application rejected")}!`,
						Vue.prototype.$t("We have send notification to applicant regarding the reason of why the application is rejected", "We have send notification to applicant regarding the reason of why the application is rejected"),
						"success")
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}

/**
 * Action ActionReject
 * @param payload
 * Method POST
 */
export const ActionVerify = ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Put(Object.assign(payload), urlLoan + 'loanApplication/status')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setSuccess", response.data)
					Vue.prototype.$swal(`${Vue.prototype.$t("Loan application verified", "Loan application verified")}!`,
						Vue.prototype.$t("Loan application has send into leader web portal for profile scoring purpose", "Loan application has send into leader web portal for profile scoring purpose"),
						"success")
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}


/**
 * Action ActionApproval
 * @param payload
 * Method POST
 */
export const ActionApproval = ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Put(Object.assign(payload), urlLoan + 'loanApplication/status')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setSuccess", response.data)
					Vue.prototype.$swal(`${Vue.prototype.$t("Loan application approved", "Loan application approved")}!`,
						Vue.prototype.$t("Upload loan contract document and do loan disbursement", "Upload loan contract document and do loan disbursement"),
						"success")
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}

// ROLES
export function ActionGettersRoles(_, parameters) {
	const { modulename, urlinfo, aksifungsi } = parameters
	let userinfo = JSON.parse(localStorage.getItem('userInfo')).menu

	let Parents = userinfo.find(o => o.MenuModule === modulename)
	let Child = Parents.Child.find(o => o.MenuModule === urlinfo)
	let regex = new RegExp(aksifungsi) 	// Create a regex object (a constructor).
	let filtered = Child.Aksi.filter(item => regex.test(item.AksiFungsi))	// Tests for a match.
	if (filtered.length > 0)
		return filtered[0].AksiFungsi
}


/**
 * Action Credit Score
 * @param payload
 * Method GET
 */
export const ActionCreditScore = async ({ rootGetters }, applicationId = null) => {
	const loadID = applicationId || rootGetters['LOAN/getLoanID']
	return new Promise((resolve, reject) => {
		AxiosCall.GetNoQueryParams(`${loadID}`, urlLoan + 'loanScoring/')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					// commit("setSuccess", response.data)
					resolve(response.data)
				} else {
					resolve(response.data)
				}
			})
			.catch((err) => {
				reject(err)
			})
	})
}
