<template>
	<div>
		{{ recordInfo }}
	</div>
</template>

<script>
	export default {
		name: "VgtPaginationPageInfo",
		components: {},
		props: {
			/* eslint-disable vue/require-prop-types */
			/* eslint-disable vue/require-default-prop */
			currentPage: {
				default: 1,
			},
			totalRecords: {
				default: 0,
				type: Number,
			},
			currentPerPage: {},
		},
		data() {
			return {
				id: this.getId(),
			}
		},
		computed: {
			firstRecordOnPage() {
				return (this.currentPage - 1) * this.currentPerPage + 1
			},
			lastRecordOnPage() {
				// if the setting is set to 'all'
				if (this.currentPerPage === -1) {
					return this.totalRecords
				}
				return Math.min(this.totalRecords, this.currentPage * this.currentPerPage)
			},
			recordInfo() {
				let first = this.firstRecordOnPage
				const last = this.lastRecordOnPage

				if (last === 0) {
					first = 0
				}

				return `${this.$t("Showing", "Showing")} ${first} ${this.$t("to", "to")} ${last} ${this.$t("of", "of")} ${this.totalRecords} ${this.$t(
					"data",
					"data",
				)}`
			},
		},
		mounted() {},
		methods: {
			getId() {
				return `vgt-page-input-${Math.floor(Math.random() * Date.now())}`
			},
		},
	}
</script>
