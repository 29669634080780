// loan
const routesAgriInput = [
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		redirect: "/app/sessions/signIn",
		children: [
			{
				path: "/agriinputrequest",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						path: "detail/:premiumDisburseId",
						component: () =>
							import(
								"@/views/modules/agriinputrequest/detail"
							),
						meta: {
							requiresUser: true,
						}
					}
				]
			},
			{
				path: "/cvc",
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						path: "index",
						component: () =>
							import(
								"@/views/modules/cvc/index"
							),
						meta: {
							requiresUser: true,
						}
					}
				]
			}
		]
	}
]

export default routesAgriInput
