export default {
	setOpsiDisplay(state, data) {
		state.opsiDisplay = data
	},
	setEntityID(state, data) {
		state.entityID = data
	},
	setError(state, data) {
		state.error = data
	},
	setPartnerFA(state, value) {
		state.optionPartnerFA = value
	},
}
