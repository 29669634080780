export default {
	setOpsiDisplay(state, data) {
		state.opsiDisplay = data
	},
	setObjectID(state, data) {
		state.objectID = data
	},
	setEntityID(state, data) {
		state.entityID = data
	},
	setOptions(state, data) {
		state.options = data
	},
	setError(state, data) {
		state.error = data
	},
}
