<template>
	<div>
		<router-view />
	</div>
</template>

<script>
	import { mapGetters } from "vuex"

	export default {
		data() {
			return {}
		},
		computed: {
			...mapGetters({ getThemeMode: "CONFIG/getThemeMode" }),
			themeName() {
				return this.getThemeMode.dark ? "dark-theme" : " "
			},
			rtl() {
				return this.getThemeMode.rtl ? "rtl" : " "
			},
		},
		metaInfo() {
			return {
				// if no subcomponents specify a metaInfo.title, this title will be used
				title: "Koltipay Web Portal",
				// all titles will be injected into this template
				titleTemplate: "%s",
				// titleTemplate: "%s | KCP",
				bodyAttrs: {
					class: [this.themeName, "text-left"],
				},
				htmlAttrs: {
					dir: this.rtl,
				},
			}
		},
		beforeCreate() {
			document.querySelector("meta[name=viewport]").setAttribute("content", "width=device-width, initial-scale=" + 1 / window.devicePixelRatio)
		},
	}
</script>
