import { basicAuth, urlApi } from "@/constants/urlApi"
import { headers } from "@/libs/axios/axiosCall"
import axios from 'axios'
import Vue from 'vue'

export default {
	getAONumberList({ commit }) {
		const aoNumberList = [
			{
				text: "1",
				value: 1,
			},
			{
				text: "2",
				value: 2,
			},
			{
				text: "3",
				value: 3,
			},
			{
				text: "4",
				value: 4,
			},
			{
				text: "5",
				value: 5,
			},
			{
				text: "6",
				value: 6,
			},
			{
				text: "7",
				value: 7,
			},
			{
				text: "8",
				value: 8,
			},
			{
				text: "9",
				value: 9,
			},
			{
				text: "10",
				value: 10,
			},
			{
				text: "11",
				value: 11,
			},
			{
				text: "12",
				value: 12,
			},
			{
				text: "13",
				value: 13,
			},
			{
				text: "14",
				value: 14,
			},
		]

		commit('SET_AO_NUMBER_LIST', aoNumberList)
	},
	async getCategoryList({ commit }) {
		const categoryList = await axios
			.get(urlApi.others.categories, headers)
			.then((res) => res)
			.catch(() => null)

		if (categoryList.status === 200) {
			commit('SET_CATEGORY_LIST', categoryList.data.data)
		} else {
			const errorMessage = categoryList?.statusText || Vue.prototype.$t("No available category list data", "No available category list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_CATEGORY_LIST', categoryList.data.data || [])
		}
	},
	async getCountryList({ commit }) {
		const countryList = await axios
			.get(
				urlApi.others.countries, {
				...basicAuth,
				...headers
			},
			)
			.then((res) => res)
			.catch(() => null)

		if (countryList.status === 200) {
			commit('SET_COUNTRY_LIST', countryList.data.data)
		} else {
			const errorMessage = countryList?.error_message || Vue.prototype.$t("No available country list data", "No available country list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_COUNTRY_LIST', countryList.data.data || [])
		}
	},
	async getCurrencyList({ commit }) {
		const currencyList = await axios
			.get(urlApi.others.currencies, headers)
			.then((res) => res)
			.catch(() => null)

		if (currencyList.status === 200) {
			commit('SET_CURRENCY_LIST', currencyList.data.data)
		} else {
			const errorMessage = currencyList?.error_message || Vue.prototype.$t("No available currency list data", "No available currency list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_CURRENCY_LIST', currencyList.data.data || [])
		}
	},
	async getCustomerTypeList({ commit }) {
		const customerTypeList = await axios
			.get(urlApi.others.customerTypes, headers)
			.then((res) => res)
			.catch(() => null)

		if (customerTypeList.status === 200) {
			commit('SET_CUSTOMER_TYPE_LIST', customerTypeList.data.data)
		} else {
			const errorMessage = customerTypeList?.statusText || Vue.prototype.$t("No available customer type list data", "No available customer type list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_CUSTOMER_TYPE_LIST', customerTypeList.data.data || [])
		}
	},
	async getDistrictList({ commit }, provinceId = null) {
		let districtList = []

		if (provinceId) {
			districtList = await axios
				.get(
					urlApi.others.districts(provinceId), {
					...basicAuth,
					...headers
				},
				)
				.then((res) => res)
				.catch(() => null)

			if (districtList.status === 200) {
				commit('SET_DISTRICT_LIST', districtList.data.data)
			} else {
				const errorMessage = districtList?.error_message || Vue.prototype.$t("No available district list data", "No available district list data")

				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
				commit('SET_DISTRICT_LIST', districtList.data.data || [])
			}
		} else {
			districtList = await axios
				.get(urlApi.others.premiumDistricts, { ...basicAuth, ...headers })
				.then((res) => res)
				.catch(() => null)

			if (districtList.status === 200) {
				commit('SET_DISTRICT_LIST', districtList.data.data)
			} else {
				const errorMessage = districtList?.error_message || Vue.prototype.$t("No available district list data", "No available district list data")

				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
				commit('SET_DISTRICT_LIST', districtList.data.data || [])
			}
		}
	},
	async getDocumentTypeList({ commit }) {
		const documentTypeList = await axios
			.get(urlApi.others.documentTypes, headers)
			.then((res) => res)
			.catch(() => null)

		if (documentTypeList.status === 200) {
			commit('SET_DOCUMENT_TYPE_LIST', documentTypeList.data.data)
		} else {
			const errorMessage = documentTypeList?.statusText || Vue.prototype.$t("No available document type list data", "No available document type list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_DOCUMENT_TYPE_LIST', documentTypeList.data.data || [])
		}
	},
	async getGenderList({ commit }) {
		const genderList = await axios
			.get(urlApi.others.genders, headers)
			.then((res) => res)
			.catch(() => null)

		if (genderList.status === 200) {
			commit('SET_GENDER_LIST', genderList.data.data)
		} else {
			const errorMessage = genderList?.statusText || Vue.prototype.$t("No available gender list data", "No available gender list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_GENDER_LIST', genderList.data.data || [])
		}
	},
	async getHubAreaList({ commit }) {
		const hubAreaList = await axios
			.get(urlApi.others.hubArea, { ...basicAuth, ...headers })
			.then((res) => res)
			.catch(() => null)

		if (hubAreaList.status === 200) {
			commit('SET_HUB_AREA_LIST', hubAreaList.data.data)
		} else {
			const errorMessage = hubAreaList?.statusText || Vue.prototype.$t("No available hub area list data", "No available hub area list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_HUB_AREA_LIST', hubAreaList.data.data || [])
		}
	},
	async getKiosKList({ commit }, districtId = null) {
		const kiosKList = await axios
			.get(urlApi.others.kiosKList(districtId), headers)
			.then((res) => res)
			.catch(() => null)

		if (kiosKList.status === 200) {
			commit('SET_KIOSK_LIST', kiosKList.data.results.data)
		} else {
			const errorMessage = kiosKList?.error_message || Vue.prototype.$t("No available kiosk list data", "No available kiosk list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_KIOSK_LIST', kiosKList.data.results.data || [])
		}
	},
	async getLegalStatusList({ commit }) {
		const legalStatusList = await axios
			.get(urlApi.others.legalStatusList, headers)
			.then((res) => res)
			.catch(() => null)

		if (legalStatusList.status === 200) {
			commit('SET_LEGAL_STATUS_LIST', legalStatusList.data.data)
		} else {
			const errorMessage = legalStatusList?.statusText || Vue.prototype.$t("No available legal status list data", "No available legal status list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_LEGAL_STATUS_LIST', legalStatusList.data.data || [])
		}
	},
	async getOrganisationNameList({ commit }) {
		const organisationNameList = await axios
			.get(urlApi.others.organisationNameList, headers)
			.then((res) => res)
			.catch(() => null)

		if (organisationNameList.status === 200) {
			commit('SET_ORGANISATION_NAME_LIST', organisationNameList.data.data)
		} else {
			const errorMessage = organisationNameList?.statusText || Vue.prototype.$t("No available organisation name list data", "No available organisation name list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_ORGANISATION_NAME_LIST', organisationNameList.data.data || [])
		}
	},
	async getOrganisationRoleList({ commit }) {
		const organisationRoleList = await axios
			.get(urlApi.others.organisationRoleList, headers)
			.then((res) => res)
			.catch(() => null)

		if (organisationRoleList.status === 200) {
			commit('SET_ORGANISATION_ROLE_LIST', organisationRoleList.data.data)
		} else {
			const errorMessage = organisationRoleList?.statusText || Vue.prototype.$t("No available organisation role list data", "No available organisation role list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_ORGANISATION_ROLE_LIST', organisationRoleList.data.data || [])
		}
	},
	async getPackageTypeList({ commit }) {
		const packageTypeList = await axios
			.get(urlApi.others.packageTypes, headers)
			.then((res) => res)
			.catch(() => null)

		if (packageTypeList.status === 200) {
			commit('SET_PACKAGE_TYPE_LIST', packageTypeList.data.data)
		} else {
			const errorMessage = packageTypeList?.statusText || Vue.prototype.$t("No available package type list data", "No available package type list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PACKAGE_TYPE_LIST', packageTypeList.data.data || [])
		}
	},
	async getPaymentMethodList({ commit }) {
		const paymentMethodList = await axios
			.get(urlApi.others.paymentMethods, headers)
			.then((res) => res)
			.catch(() => null)

		if (paymentMethodList.status === 200) {
			commit('SET_PAYMENT_METHOD_LIST', paymentMethodList.data.data)
		} else {
			const errorMessage = paymentMethodList?.statusText || Vue.prototype.$t("No available payment method list data", "No available payment method list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PAYMENT_METHOD_LIST', paymentMethodList.data.data || [])
		}
	},
	getPriorityList({ commit }) {
		const priorityList = [
			{
				text: "1",
				value: 1,
			},
			{
				text: "2",
				value: 2,
			},
			{
				text: "3",
				value: 3,
			},
			{
				text: "4",
				value: 4,
			},
			{
				text: "5",
				value: 5,
			},
			{
				text: "6",
				value: 6,
			},
			{
				text: "7",
				value: 7,
			},
		]

		commit('SET_PRIORITY_LIST', priorityList)
	},
	async getProductList({ commit }, { kiosKId = null, params = null }) {
		const productList = await axios
			.get(urlApi.others.productList(kiosKId), {
				params: params,
				...headers
			})
			.then((res) => res)
			.catch(() => null)

		if (productList.status === 200) {
			commit('SET_PRODUCT_LIST', productList.data.results)
		} else {
			const errorMessage = productList?.error_message || Vue.prototype.$t("No available product list data", "No available product list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PRODUCT_LIST', productList.data.results || [])
		}
	},
	async getProductNameList({ commit }) {
		const productNameList = await axios
			.get(urlApi.others.productNames, { ...basicAuth, ...headers })
			.then((res) => res)
			.catch(() => null)

		if (productNameList.status === 200) {
			commit('SET_PRODUCT_NAME_LIST', productNameList.data.data)
		} else {
			const errorMessage = productNameList?.statusDescription || Vue.prototype.$t("No available product name list data", "No available product name list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PRODUCT_NAME_LIST', productNameList.data.data || [])
		}
	},
	async getProvinceList({ commit }, countryCode = 'ID') {
		const provinceList = await axios
			.get(
				urlApi.others.provinces(countryCode), {
				...basicAuth,
				...headers
			},
			)
			.then((res) => res)
			.catch(() => null)

		if (provinceList.status === 200) {
			commit('SET_PROVINCE_LIST', provinceList.data.data)
		} else {
			const errorMessage = provinceList?.error_message || Vue.prototype.$t("No available province list data", "No available province list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PROVINCE_LIST', provinceList.data.data || [])
		}
	},
	async getstatusPremiumList({ commit }, params) {
		const PremList = await axios
			.get(urlApi.others.statusPremium(params), { ...basicAuth, ...headers })
			.then((res) => res)
			.catch(() => null)

		if (PremList.status === 200) {
			commit('SET_STATUS_PREMIUM_LIST', PremList.data.data)
		} else {
			const errorMessage = PremList?.statusText || "No available unit list data"

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_STATUS_PREMIUM_LIST', PremList.data.data || [])
		}
	},
	async getStatusPremiumList({ commit }, type = null) {
		const statusPremiumList = await axios
			.get(urlApi.others.premiumStatuses(type), { ...basicAuth, ...headers })
			.then((res) => res)
			.catch(() => null)

		if (statusPremiumList.status === 200) {
			commit('SET_STATUS_PREMIUM_LIST', statusPremiumList.data.data)
		} else {
			const errorMessage = statusPremiumList?.statusText || Vue.prototype.$t("No available status premium list data", "No available status premium list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_STATUS_PREMIUM_LIST', statusPremiumList.data.data || [])
		}
	},
	async getSubDistrictList({ commit }, districtId = null) {
		const subDistrictList = await axios
			.get(
				urlApi.others.subDistricts(districtId), {
				...basicAuth,
				...headers
			},
			)
			.then((res) => res)
			.catch(() => null)

		if (subDistrictList.status === 200) {
			commit('SET_SUBDISTRICT_LIST', subDistrictList.data.data)
		} else {
			const errorMessage = subDistrictList?.error_message || Vue.prototype.$t("No available sub district list data", "No available sub district list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_SUBDISTRICT_LIST', subDistrictList.data.data || [])
		}
	},
	async getUnitInfoList({ commit }) {
		const unitInfoList = await axios
			.get(urlApi.others.unitInfos, { ...basicAuth, ...headers })
			.then((res) => res)
			.catch(() => null)

		if (unitInfoList.status === 200) {
			commit('SET_UNIT_INFO_LIST', unitInfoList.data.data)
		} else {
			const errorMessage = unitInfoList?.statusDescription || Vue.prototype.$t("No available unit info list data", "No available unit info list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_UNIT_INFO_LIST', unitInfoList.data.data || [])
		}
	},
	async getUnitList({ commit }) {
		const unitList = await axios
			.get(urlApi.others.units, { ...basicAuth, ...headers })
			.then((res) => res)
			.catch(() => null)

		if (unitList.status === 200) {
			commit('SET_UNIT_LIST', unitList.data.data)
		} else {
			const errorMessage = unitList?.statusText || Vue.prototype.$t("No available unit list data", "No available unit list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_UNIT_LIST', unitList.data.data || [])
		}
	},
	async getVillageList({ commit }, subDistrictId = null) {
		const villageList = await axios
			.get(
				urlApi.others.villages(subDistrictId), {
				...basicAuth,
				...headers
			},
			)
			.then((res) => res)
			.catch(() => null)

		if (villageList.status === 200) {
			commit('SET_VILLAGE_LIST', villageList.data.data)
		} else {
			const errorMessage = villageList?.error_message || Vue.prototype.$t("No available village list data", "No available village list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_VILLAGE_LIST', villageList.data.data || [])
		}
	},
}
