import Vue from "vue" 
/**
 * Mutate List example
  export const setExample= (state, value) => {
  state.ExampleData = value;
  };
*/
export const setCulture = (state, payload)=> { 
  let myLang = {}  
  let language = 'en'
  Object.keys(payload).forEach( el  => {  
    language = payload[el].lang
    if(payload[el] !== undefined){ 
      myLang[payload[el].key] = payload[el].text
    }  
  });    
  state.locale = language
  Vue.i18n.add(language, myLang ); 
  Vue.i18n.set(state.locale); 
}; 
