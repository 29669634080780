import { findWhere, map } from 'underscore'

export default {
	error: (state) => state.error,
	isUserLoggedIn: (state) => state.isUserLoggedIn,
	loading: (state) => state.loading,
	loanInstitutionActions: (state) => {
		let actions = []
		if (state.loggedInUser?.menu) {
			const loan = findWhere(state.loggedInUser.menu, { MenuName: 'Loan' })

			if (loan?.Child) {
				const loanInstitution = findWhere(loan.Child, { MenuName: 'Loan Institution' })

				if (loanInstitution) {
					actions = map(loanInstitution.Aksi, (action) => {
						return {
							function: action.AksiFungsi,
							id: parseInt(action.AksiId, 10),
							name: action.AksiName,
						}
					})
				}

			}
		}

		return actions
	},
	loggedInUser: (state) => state.loggedInUser,
	premiumPointActions: () => {
		return [
			{
				function: "view_list_all_disburse_premium_point",
				id: 1,
				name: "View List All Disburse Premium Point"
			},
			{
				function: "view_list_disburse_premium_point",
				id: 2,
				name: "View List Disburse Premium Point"
			},
			{
				function: "add",
				id: 3,
				name: "Add Disburse Premium Point"
			},
			{
				function: "detail",
				id: 4,
				name: "View Detail Disburse Premium Point"
			},
			{
				function: "delete",
				id: 5,
				name: "Delete Disburse Premium Point"
			},
			{
				function: "view_payment_instruction",
				id: 6,
				name: "View Payment Instruction Disburse Premium Point"
			},
			{
				function: "download_invoice",
				id: 7,
				name: "View Payment Instruction Disburse Premium Point"
			},
		]
	},
	token: () => localStorage.getItem("accessToken") || null,
	userEntityOrganizationID: (state) => state.loggedInUser?.EntityOrganizationID || null,
	userEntityOrganizationName: (state) => state.loggedInUser?.EntityOrganizationName || null,
	userId: (state) => state.loggedInUser?.UserId || null,
}
