
import AxiosCall from "@/libs/axios/axiosCall"
import { urlApi } from "@/constants/urlApi"

/**
 * Action ActionLang
 * @param payload
 * Method GET
 */
export const ActionLang = async ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Get([], urlApi.lang.language + `?language_id=${payload.id}&module=loan`)
			.then((response) => {
				let data = []
				response.results.forEach(element => {
					/*
					const obj = {
						key: element.key.toLowerCase().replace((/(?<=\b)\p{L}/gu), match => match.toUpperCase()),
						text: element.text.toLowerCase().replace((/(?<=\b)\p{L}/gu), match => match.toUpperCase()),
						lang: payload.label
					}
					*/
					const obj = {
						key: element.key,
						text: element.text,
						lang: payload.label
					}
					data.push(obj)
				})
				commit("setCulture", data)
				resolve(response.results)
			})
			.catch((err) => {
				reject(err)
			})
	})
}
