import Vue from 'vue'
import { urlLoan } from "@/constants/urlApi"
import AxiosCall from "@/libs/axios/axiosCall"


/**
 * Action ActionTablesGrid
 * @param payload
 * Tables Grid
 * Method GET
 */
export const ActionTablesGrid = async ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Get(
			Object.assign(payload), urlLoan + 'loanApplication/package')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setLoanFXRecords", response.data)
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}

export const ActionAddNewSubmit = ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Post(Object.assign(payload), urlLoan + 'loanApplication/insert')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setSuccess", response.data)
					Vue.prototype.$swal(Vue.prototype.$t("Add new loan application", "Add new loan application"),
						Vue.prototype.$t("New loan has been added successfully", "New loan has been added successfully"),
						"success")
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}


export const ActPlatform = async ({ commit }) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Get('', urlLoan + 'listOfValue/listOfCommodity')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setPlatform", response.data)
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}

export const ActSearch = async (_, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Get(payload, urlLoan + 'loanApplication/farmer')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}

export const ActDocumentListRegister = async ({ commit }) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Get('', urlLoan + 'listOfValue/idType')
			.then((response) => {
				if (response.statusDescription === 'Success' && response.data !== null) {
					commit("setdocumentTypeList", response.data)
					resolve(response.data)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}
			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}