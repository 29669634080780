export default {
	themeMode: {
		dark: false,
		light: true,
		semi_dark: false,
		theme_color: "lite-purple",
		layout: "large-sidebar",
		rtl: false,
	},
}
