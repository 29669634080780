/**
 * State register
 */

export default function () {
	return { 
		AgriinputRequest:[],
		AgriinputProgress:[],
		AgriinputProgressDetail:[],
		TotalRecordsCvc:0
	}
}
