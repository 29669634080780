export default {
	aoNumberList: [],
	categoryList: [],
	countryList: [],
	currencyList: [],
	customerTypeList: [],
	districtList: [],
	documentTypeList: [],
	genderList: [],
	hubAreaList: [],
	kiosKList: [],
	legalStatusList: [],
	organisationNameList: [],
	organisationRoleList: [],
	packageTypeList: [],
	paymentMethodList: [],
	priorityList: [],
	productList: [],
	productNameList: [],
	provinceList: [],
	statusPremiumList: [],
	subDistrictList: [],
	unitInfoList: [],
	unitList: [],
	villageList: [],
}
