<template>
	<div class="boxpagination">
		<b-row class="page-content" align-v="center">
			<b-col>
				<button
					v-if="jumpFirstOrLast"
					type="button"
					aria-controls="vgt-table"
					class="btn-page-custom"
					:class="{ disabled: !firstIsPossible }"
					title="first page"
					@click.prevent.stop="firstPage"
				>
					<span>
						<img :src="iconfirst" />
					</span>
				</button>

				<button
					type="button"
					title="previous page"
					aria-controls="vgt-table"
					class="btn-page-custom"
					:class="{ disabled: !prevIsPossible }"
					@click.prevent.stop="previousPage"
				>
					<span>
						<img :src="iconprev" />
					</span>
				</button>

				<form class="formpage" @submit.prevent>
					<label :for="id" style="margin: 0; padding: 0">
						<span class="text-gray-light">&nbsp;&nbsp;{{ $t(pageText, pageText) }}&nbsp;&nbsp;</span>
						<input
							:id="id"
							:disabled="disableCurrentPage"
							aria-describedby="change-page-hint"
							aria-controls="vgb-table"
							type="text"
							:value="currentPage"
							class="inputformpage"
							@keyup.enter.stop="changePageSub"
						/>
						<span class="text-gray-light">&nbsp;&nbsp;{{ $t(ofText, ofText) }}</span>&nbsp;&nbsp;{{ pagesCount }}&nbsp;&nbsp;
					</label>
					<span id="change-page-hint" style="display: none"> Type a page number and press Enter to change the page. </span>
				</form>

				<button
					type="button"
					title="next page"
					aria-controls="vgt-table"
					class="btn-page-custom"
					:class="{ disabled: !nextIsPossible }"
					@click.prevent.stop="nextPage"
				>
					<span>
						<img :src="iconnext" />
					</span>
				</button>

				<button
					v-if="jumpFirstOrLast"
					type="button"
					title="last page"
					aria-controls="vgt-table"
					class="btn-page-custom"
					:class="{ disabled: !lastIsPossible }"
					@click.prevent.stop="lastPage"
				>
					<span>
						<img :src="iconlast" />
					</span>
				</button>

				<button type="button" title="Refresh page" aria-controls="vgt-table" class="btn-page-custom" @click="refreshPage">
					<span>
						<img :src="iconRefresh" width="25px" />
					</span>
				</button>
			</b-col>
			<b-col class="text-right">
				<pagination-page-info :current-page="currentPage" :total-records="total" :current-per-page="currentPerPage" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import VgtPaginationPageInfo from "./VgtPaginationPageInfo.vue"
	import { DEFAULT_ROWS_PER_PAGE_DROPDOWN } from "./constants"

	import IconFirst from "@/assets/images/icon/iconfirst.png"
	import IconLast from "@/assets/images/icon/iconlast.png"
	import IconNext from "@/assets/images/icon/iconnext.png"
	import IconPrev from "@/assets/images/icon/iconprev.png"
	import IconRefresh from "@/assets/images/icon/refresh-icon-png-24.jpg"

	export default {
		name: "VgtPagination",

		components: {
			"pagination-page-info": VgtPaginationPageInfo,
		},

		props: {
			total: {
				default: null,
				required: false,
				type: [Number, String],
			},
			perPage: {
				default: null,
				required: false,
				type: [Number, String],
			},
			jumpFirstOrLast: {
				default: false,
				required: false,
				type: Boolean,
			},

			// text options
			firstText: {
				default: "First",
				required: false,
				type: String,
			},
			lastText: {
				default: "Last",
				required: false,
				type: String,
			},
			nextText: {
				default: "Next",
				required: false,
				type: String,
			},
			ofText: {
				default: "of",
				required: false,
				type: String,
			},
			pageText: {
				default: "Page",
				required: false,
				type: String,
			},
			prevText: {
				default: "Prev",
				required: false,
				type: String,
			},

			disableCurrentPage: {
				default: false,
				required: false,
				type: Boolean,
			},
		},

		emits: ["page-changed", "per-page-changed", "refresh-page"],

		data() {
			return {
				currentPage: 1,
				currentPerPage: 10,
				iconfirst: IconFirst,
				iconlast: IconLast,
				iconnext: IconNext,
				iconprev: IconPrev,
				iconRefresh: IconRefresh,
				id: this.getId(),
				prevPage: 0,
				rowsPerPageOptions: [],
			}
		},

		computed: {
			// Number of pages
			pagesCount() {
				// if the setting is set to 'all'
				if (this.currentPerPage === -1) {
					return 1
				}
				const quotient = Math.floor(this.total / this.currentPerPage)
				const remainder = this.total % this.currentPerPage

				return remainder === 0 ? quotient : quotient + 1
			},

			// Can go to first page
			firstIsPossible() {
				return this.currentPage > 1
			},

			// Can go to last page
			lastIsPossible() {
				return this.currentPage < Math.ceil(this.total / this.currentPerPage)
			},

			// Can go to next page
			nextIsPossible() {
				return this.currentPage < this.pagesCount
			},

			// Can go to previous page
			prevIsPossible() {
				return this.currentPage > 1
			},
		},

		watch: {
			perPage: {
				handler(_newValue, oldValue) {
					this.handlePerPage()
					this.perPageChanged(oldValue)
				},
				immediate: true,
			},

			total: {
				handler(newValue) {
					if (this.rowsPerPageOptions.indexOf(this.currentPerPage) === -1) {
						this.currentPerPage = newValue
					}
				},
			},
		},

		methods: {
			getId() {
				return `vgt-select-rpp-${Math.floor(Math.random() * Date.now())}`
			},

			changePageSub(event) {
				this.changePage(event.target.value)
			},

			// Change current page
			changePage(pageNumber, emit = true) {
				if (pageNumber > 0 && this.total > this.currentPerPage * (pageNumber - 1)) {
					this.prevPage = this.currentPage
					this.currentPage = pageNumber
					this.pageChanged(emit)
				}
			},

			// Go to first page
			firstPage() {
				if (this.firstIsPossible) {
					this.currentPage = 1
					this.prevPage = 0
					this.pageChanged()
				}
			},

			// Go to last page
			lastPage() {
				if (this.lastIsPossible) {
					this.currentPage = this.pagesCount
					this.prev = this.currentPage - 1
					this.pageChanged()
				}
			},

			// Go to next page
			nextPage() {
				if (this.nextIsPossible) {
					this.prevPage = this.currentPage
					++this.currentPage
					this.pageChanged()
				}
			},

			// Go Refresh Page
			refreshPage() {
				this.$emit("refresh-page")
			},

			// Go to previous page
			previousPage() {
				if (this.prevIsPossible) {
					this.prevPage = this.currentPage
					--this.currentPage
					this.pageChanged()
				}
			},

			// Indicate page changing
			pageChanged(emit = true) {
				const payload = {
					currentPage: this.currentPage,
					prevPage: this.prevPage,
				}
				if (!emit) payload.noEmit = true
				this.$emit("page-changed", payload)
			},

			// Indicate per page changing
			perPageChanged(oldValue) {
				// go back to first page
				if (oldValue) {
					//* only emit if this isn't first initialization
					this.$emit("per-page-changed", { currentPerPage: this.currentPerPage })
				}
				this.changePage(1, false)
			},

			// Handle per page changing
			handlePerPage() {
				this.rowsPerPageOptions = JSON.parse(JSON.stringify(DEFAULT_ROWS_PER_PAGE_DROPDOWN))

				if (this.perPage) {
					this.currentPerPage = this.perPage
					// if perPage doesn't already exist, we add it
					let found = false
					for (let i = 0; i < this.rowsPerPageOptions.length; i++) {
						if (this.rowsPerPageOptions[i] === this.perPage) {
							found = true
						}
					}
					if (!found && this.perPage !== -1) {
						this.rowsPerPageOptions.unshift(this.perPage)
					}
				} else {
					// reset to default
					this.currentPerPage = 10
				}
			},
		},
	}
</script>

<style>
	.boxpagination {
		height: 48px;
	}
	.page-content {
		height: 100%;
		padding: 0 14px;
		margin: 0;
	}
	.btn-page-custom {
		background: transparent;
		border: 0;
		cursor: pointer;
	}
	.btn-page-custom.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	.btn-page-custom:focus {
		outline: 0 !important;
	}
	.formpage {
		display: inline-block;
		margin: 0;
		padding: 0;
	}
	.inputformpage {
		border-radius: 4px;
		background: #ffffff;
		border: 1px solid #dbdbdb;
		width: 40px;
	}
	.inputformpage:focus {
		color: #374151;
		background-color: #fff;
		border-color: #beddf2;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgb(84 167 220 / 25%);
	}
</style>
