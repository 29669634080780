<template>
	<b-card :border-variant="borderVariant" :header="header" :header-bg-variant="headerColor" :header-text-variant="headerTextVariant" align="left">
		<slot name="content" />
	</b-card>
</template>
<script>
	export default {
		name: "KtvCardWithHeader",
		props: {
			borderVariant: {
				default: "primary",
				required: false,
				type: String,
			},
			header: {
				default: "Header",
				required: true,
				type: String,
			},
			headerColor: {
				default: "primary",
				required: false,
				type: String,
			},
			headerTextVariant: {
				default: "white",
				required: false,
				type: String,
			},
		},
	}
</script>
