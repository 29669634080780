/* eslint-disable no-unused-vars */
import AxiosCall from "@/libs/axios/axiosCall";
import { urlApi } from "@/constants/urlApi";
import Vue from "vue";

export default {
	pushData({ commit }, payload) {
		commit("setEntityID", payload.EntityID)
		commit("setOpsiDisplay", payload.opsiDisplay)
	},
	clearData({ commit }) {
		commit("setEntityID", null)
		commit("setOpsiDisplay", null)
	},
	async actGetDetail({ getters }) {
		let payload = {
			entityId : getters.getEntityID
		}
		if (getters.getOpsiDisplay === 'View' || getters.getOpsiDisplay === 'Update') {
			return new Promise((resolve, reject) => {
				AxiosCall.Get(payload, urlApi.fieldAgent.detail)
					.then((response) => {
						if (response.success) {
							resolve(response)
						} else {
							Vue.prototype.$swal("Failed!", response.error_message, "error")
							reject(response)
						}
					})
					.catch((error) => {
						Vue.prototype.$swal("Failed!", error.response.data.error_message, "error")
					})
			})
		}
		return null;
	},
	async actGetPartnerFA({ commit }) {
		return new Promise((resolve, reject) => {
			AxiosCall.Get([], urlApi.fieldAgent.getPartnerFA)
				.then((respone) => {
					if (respone.success) {
						commit("setPartnerFA", respone.results)
						resolve(respone)
					} else {
						Vue.prototype.$swal("Failed!", respone.error_message, "error")
						reject(respone)
					}
				})
		})
	},
	async actPostData({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(payload, urlApi.fieldAgent.createRole)
				.then((response) => {
					if (response.success) {
						resolve(response)
					} else {
						Vue.prototype.$swal("Failed!", response.error_message, "error")
						reject(response)
					}
				})
				.catch((error) => {
					Vue.prototype.$swal("Failed!", error.response.data.error_message, "error")
				})
		})
	},
}