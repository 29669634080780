const routesWeather = [
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		redirect: "/app/sessions/signIn",
		children: [
			{
				path: "/weather/list",
				component: () => import("../containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 50,
						},
						path: "/",
						component: () => import("@/views/modules/weather"),
					},
				],
			},
		],
	},
]

export default routesWeather
