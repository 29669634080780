// import EventEmitter from 'events'
// file baru
// class AuthService extends EventEmitter {
//   isAuthenticated() {
//     console.log('sini');
//     if (new Date(Date.now()) < new Date(localStorage.getItem('tokenExpiryKey')) && localStorage.getItem('loggedIn') === 'true') {
//       console.log('authenticated');
//       return true
//     } else {
//       console.log('unauthorized');
//       localStorage.removeItem("accessToken")
//       localStorage.removeItem('userInfo')
//       localStorage.removeItem('tokenExpiryKey')
//       localStorage.removeItem('loggedIn')
//       localStorage.removeItem('vuex')
//       return false
//     }
//   }
// }

// export default new AuthService()

export default (to, from, next) => {
	if (
		new Date(Date.now()) <
			new Date(localStorage.getItem("tokenExpiryKey")) &&
		localStorage.getItem("loggedIn") === "true"
	) {
		next()
	} else {
		localStorage.removeItem("accessToken")
		localStorage.removeItem("userInfo")
		localStorage.removeItem("tokenExpiryKey")
		localStorage.removeItem("loggedIn")
		localStorage.removeItem("vuex")
		next("/app/sessions/signIn")
	}
}
