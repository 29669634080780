const routesTraining = [
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		redirect: "/app/sessions/signIn",
		children: [
			{
				path: "/training",
				component: () => import("../containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 50,
						},
						path: "list",
						component: () => import("@/views/modules/training/training"),
					},
					{
						meta: {
							ParentId: 50,
						},
						path: "create",
						component: () => import("../views/modules/training/training/create"),
					},
					{
						meta: {
							ParentId: 50,
						},
						path: "category",
						component: () => import("@/views/modules/training/category"),
					},
					{
						meta: {
							ParentId: 50,
						},
						path: "sub-category",
						component: () => import("@/views/modules/training/subCategory"),
					},
				],
			},
		],
	},
]

export default routesTraining
