<template>
	<vue-good-table
		:id="id"
		:columns="columns"
		:compact-mode="compactMode"
		:is-loading="is.loading"
		:line-numbers="lineNumbers"
		:mode="mode"
		:pagination-options="paginationOptions"
		:rows="rows"
		:search-options="searchOptions"
		:select-options="selectOptions"
		:style-class="styleClass"
		:total-rows="totalRows"
		@on-column-filter="$emit('on-column-filter', $event)"
		@on-page-change="$emit('on-page-change', $event)"
		@on-per-page-change="$emit('on-per-page-change', $event)"
		@on-search="$emit('on-search', $event)"
		@on-sort-change="$emit('on-sort-change', $event)"
		@on-selected-rows-change="$emit('on-selected-rows-change', $event)"
	>
		<div slot="emptystate" class="text-center">
			<img :src="nodatatable" />
		</div>
		<template slot="table-column" slot-scope="props">
			<slot name="column" :props="props" />
		</template>
		<template slot="selected-row-actions">
			<ktv-button color="warning" size="sm" text="Delete" />
		</template>
		<template slot="table-actions">
			<b-row class="boxaction" align-v="center">
				<b-col style="padding: 0; margin: 0">
					<slot name="actionleft" />
				</b-col>
				<b-col class="text-right" style="padding: 0; margin: 0">
					<div class="parent-div">
						<div v-if="searchCustomEnabled" class="child-div">
							<b-form-group v-if="searchCustomEnabled" class="text-right inputsearch">
								<b-form-input
									v-model="searchTerm"
									class="input-form"
									type="text"
									:placeholder="searchPlaceholder"
									@input="onSearch"
									@keyup.enter="$emit('on-search', searchTerm)"
								/>
								<span class="searchicon">
									<img :src="iconsearch" />
								</span>
							</b-form-group>
						</div>
						<div class="child-div">
							<ktv-button
								v-if="filter"
								:text="$t('Filter', 'Filter')"
								class="ml-2"
								color="light"
								icon="filter"
								@click="$emit('on-open-filter')"
							/>
							<slot name="actionright" />
						</div>
					</div>
				</b-col>
			</b-row>
		</template>
		<template slot="table-column" slot-scope="props">
			<slot name="columns" :props="props" />
		</template>
		<template slot="table-row" slot-scope="props">
			<slot name="rows" :props="props" />
		</template>
		<template slot="pagination-bottom" slot-scope="props">
			<custom-pagination
				ref="paginationBottom"
				:first-text="paginationOptions.firstLabel"
				:jump-first-or-last="paginationOptions.jumpFirstOrLast"
				:last-text="paginationOptions.lastLabel"
				:next-text="paginationOptions.nextLabel"
				:of-text="paginationOptions.ofText"
				:page-text="paginationOptions.pageText"
				:per-page="paginationOptions.perPage"
				:prev-text="paginationOptions.prevLabel"
				:disable-current-page="disableCurrentPage"
				:total="totalRows"
				@page-changed="props.pageChanged"
				@per-page-changed="props.perPageChanged"
				@refresh-page="onRefresh"
			/>
		</template>
	</vue-good-table>
</template>

<script>
	import "vue-good-table/dist/vue-good-table.css"
	import { VueGoodTable } from "vue-good-table"

	import KtvButton from "./Button"
	import CustomPagination from "./VgtPagination"

	import EmptyTableImage from "@/assets/images/empty-datatable.png"
	import IconSearch from "@/assets/images/icon/iconsearch.png"

	export default {
		name: "Table",
		components: { KtvButton, VueGoodTable, CustomPagination },
		props: {
			columns: {
				default: () => [],
				required: true,
				type: Array,
			},
			compactMode: {
				default: false,
				required: false,
				type: Boolean,
			},
			disableCurrentPage: {
				default: false,
				type: Boolean,
			},
			filter: {
				default: true,
				required: false,
				type: Boolean,
			},
			hiddenHeader: {
				default: false,
				required: false,
				type: Boolean,
			},
			id: {
				default: null,
				required: false,
				type: String,
			},
			isLoading: {
				default: false,
				required: false,
				type: Boolean,
			},
			lineNumbers: {
				default: false,
				required: false,
				type: Boolean,
			},
			minHeight: {
				default: null,
				required: false,
				type: String,
			},
			mode: {
				default: "client",
				required: false,
				type: String,
			},
			pagination: {
				default: true,
				required: false,
				type: Boolean,
			},
			rows: {
				default: () => [],
				required: true,
				type: Array,
			},
			rowsPerPage: {
				default: 10,
				required: false,
				type: Number,
			},
			searchCustomEnabled: {
				default: true,
				required: false,
				type: Boolean,
			},
			searchEnabled: {
				default: false,
				required: false,
				type: Boolean,
			},
			searchOnEnter: {
				default: true,
				required: false,
				type: Boolean,
			},
			searchPlaceholder: {
				default: "Search this table",
				required: false,
				type: String,
			},
			selectOptions: {
				type: Object,
				default() {
					return { enabled: false }
				},
			},
			styleClass: {
				default: "tableOne vgt-table",
				required: false,
				type: String,
			},
			totalRows: {
				default: 0,
				required: false,
				type: Number,
			},
		},
		emits: [
			"on-open-filter",
			"on-column-filter",
			"on-page-change",
			"on-per-page-change",
			"on-search",
			"on-sort-change",
			"on-search-custom",
			"on-selected-rows-change",
		],
		data() {
			return {
				iconsearch: IconSearch,
				nodatatable: EmptyTableImage,
				searchTerm: "",
				is: {
					loading: false,
				},
				paginationOptions: {
					enabled: this.pagination,
					perPage: this.rowsPerPage,
					jumpFirstOrLast: true,
					firstLabel: "",
					lastLabel: "",
					nextLabel: "",
					prevLabel: "",
					ofText: "of",
					pageText: "Page",
				},
				searchOptions: {
					enabled: this.searchCustomEnabled,
					placeholder: this.searchPlaceholder,
					trigger: this.searchOnEnter ? "enter" : "input",
					externalQuery: this.searchTerm,
					onSearch: "on-search",
				},
			}
		},
		watch: {
			isLoading: {
				handler() {
					this.is = {
						...this.is,
						loading: this.isLoading,
					}
				},
				immediate: true,
			},
			searchEnabled: {
				handler() {
					let searchInput = null

					if (this.id) {
						searchInput = document.querySelector(`#${this.id} .vgt-global-search__input`)
					} else {
						searchInput = document.querySelector(".vgt-global-search__input")
					}

					if (searchInput) {
						if (!this.searchEnabled) {
							searchInput.classList.add("vgt-global-search__input--hidden")
						} else {
							searchInput.classList.remove("vgt-global-search__input--hidden")
						}
					}
				},
				immediate: true,
			},
		},
		mounted() {
			let searchInput = null

			if (this.id) {
				searchInput = document.querySelector(`#${this.id} .vgt-global-search__input`)
			} else {
				searchInput = document.querySelector(".vgt-global-search__input")
			}

			if (searchInput) {
				if (!this.searchEnabled) {
					searchInput.classList.add("vgt-global-search__input--hidden")
				} else {
					searchInput.classList.remove("vgt-global-search__input--hidden")
				}
			}

			if (this.hiddenHeader) {
				let divhead = document.querySelector(`#${this.id} .vgt-global-search`)
				divhead.classList.add("hiddenheader")
			}

			if (this.minHeight) {
				let tableContent = document.querySelector(".vgt-responsive")
				tableContent.style.minHeight = this.minHeight
			}
		},
		methods: {
			onRefresh() {
				this.searchTerm = ""
				this.$emit("on-search", "")
			},
			onSearch($event) {
				if (!this.searchOnEnter) {
					this.$emit("on-search", $event)
				}
			},
		},
	}
</script>

<style>
	/* header action */
	.hiddenheader {
		height: 0px !important;
	}
	.boxaction {
		height: 100%;
		padding: 0;
		margin: 0;
	}
	.vgt-global-search.vgt-clearfix {
		padding: 0 14px;
		border-radius: 4px;
		height: 62px;
	}
	.searchicon {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background: none;
		border: none;
		color: #718096;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin: 0 8px;
		outline: none;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
	}
	.input-form {
		background: #ffffff;
		border: 1px solid #dbdbdb;
		border-radius: 4px;
		padding: 16.5px 8px 16.5px 34px;
	}
	.parent-div {
		text-align: right;
	}
	.child-div {
		display: inline-block;
		vertical-align: middle;
	}
	.inputsearch {
		margin: 0;
		width: 300px;
	}
	/* hide search default */
	.vgt-global-search__actions {
		flex-grow: 1;
		margin: 0;
	}
	.vgt-global-search__input--hidden {
		display: none !important;
	}
	/* end hide search default */
	/* end header action */

	/* header */
	.vgt-table thead th {
		vertical-align: middle !important;
		background-color: #f5f5f5 !important;
		color: #3c3d3e;
		height: 46px;
		font-size: 14px;
		padding: 0 8px;
	}

	.vgt-table thead th.text-right.sortable {
		padding-right: 20px;
	}
	/* end header */

	/* row */
	table.vgt-table td {
		vertical-align: middle !important;
		color: #000000;
		height: 46px;
		font-size: 14px;
		font-weight: 400;
		padding: 0 8px;
	}
	/* end row */

	/* footer */
	div.vgt-wrap__footer.vgt-clearfix {
		padding: 14px 14px;
		border-radius: 4px;
	}
	.vgt-inner-wrap {
		border: 1px solid #dcdfe6 !important;
		border-radius: 4px;
	}
	.footer__row-count__label {
		margin-bottom: 0;
	}
	.vgt-wrap__footer .footer__navigation {
		font-size: 14px;
		color: #000000;
		font-weight: 400;
	}
	.vgt-wrap__footer .footer__navigation > button:first-of-type {
		margin-right: 0;
	}
	/* end footer */
	/* .vgt-global-search__input .vgt-pull-left {
		display: none !important;
	} */
</style>
