import Vue from 'vue'
import AxiosCall from "../../../libs/axios/axiosCall"
import { urlApi } from "@/constants/urlApi"

export default {
	async actGetPartner({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.sme.getPartner).then((response) => {
                if (response.success) {
                    commit("setPartner", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetProdCateg({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.transaction.getProdCateg).then((response) => {
                if (response.success) {
                    commit("setProdCateg", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetPayMethod({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.transaction.getPayMethod).then((response) => {
                if (response.success) {
                    commit("setPayMethod", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetProvince({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.customer.getProvince).then((response) => {
                if (response.success) {
                    commit("setProvince", response.results) 
                    // console.log('setProvince', setProvince)
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetDistrict({ commit }, payload) {
        let params = { entityProvinceID: payload }
        return new Promise((resolve, reject) => { 
            AxiosCall.Get(params, urlApi.customer.getDistrict).then((response) => {
                if (response.success) {
                    commit("setDistrict", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetSubDistrict({ commit }, payload) {
        let params = { entityDistrictID: payload }
        return new Promise((resolve, reject) => { 
            AxiosCall.Get(params, urlApi.customer.getSubDistrict).then((response) => {
                if (response.success) {
                    commit("setSubDistrict", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
    /**
     * Modules ActionMasterLender
     * Aprianto
     * @param none
     * @returns 
     */
    async ActionMasterLender({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get( [],  urlApi.loanapplication.lender )
                .then((response) => {  
                    if (response.statusDescription === 'Success') {
                        commit("setMasterLender", response.data) 
                        resolve(response.data)  
                    } 
                })
                .catch((err) => { 
                    Vue.prototype.$swal("Failed!", err.response.data.error, "error") 
                    reject(err)
                })
        }) 
    },
    /**
     * Modules ActionMasterLender
     * Aprianto
     * @param none
     * @returns 
     */
     async ActionLoanPaymentMethod({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get( [],  urlApi.others.paymentMethods )
                .then((response) => {  
                    if (response.statusDescription === 'Success') {
                        commit("setPaymentLoanMethodList", response.data) 
                        resolve(response.data)  
                    } 
                })
                .catch((err) => { 
                    Vue.prototype.$swal("Failed!", err.response.data.error, "error") 
                    reject(err)
                })
        }) 
    },
    /**
     * Modules ActionMasterCategory
     * Aprianto
     * @param none
     * @returns 
     */
     async ActionMasterCategory({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get( [], urlApi.loanapplication.category  )
                .then((response) => {  
                    if (response.statusDescription === 'Success') {
                        commit("setMasterCategory", response.data) 
                        resolve(response.data)  
                    } 
                })
                .catch((err) => { 
                    Vue.prototype.$swal("Failed!", err.response.data.error, "error") 
                    reject(err)
                })
        }) 
    },
    /**
     * Modules ActionMasterStatus
     * Aprianto
     * @param none
     * @returns 
     */
     async ActionMasterStatus({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get( [], urlApi.loanapplication.status  )
                .then((response) => {  
                    if (response.statusDescription === 'Success') {
                        commit("setMasterStatus", response.data) 
                        resolve(response.data)  
                    } 
                })
                .catch((err) => { 
                    Vue.prototype.$swal("Failed!", err.response.data.error, "error") 
                    reject(err)
                })
        })  
    },
    /**
     * Modules actGetGender
     * ginarizkaa@gmail.com
     * 19/01/2022
     * @param none
     * @returns 
     */
	async actGetGender({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.sme.getGender).then((response) => {
                if (response.success) {
                    commit("setGender", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetCountry({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.others.getCountries).then((response) => {
                if (response.success) {
                    commit("setCountry", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetLanguage({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.others.getLanguanges).then((response) => {
                if (response.success) {
                    commit("setLanguage", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetYesNo({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.sme.getYesNo).then((response) => {
                if (response.success) {
                    commit("setYesNo", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetRole({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.user.getRole).then((response) => {
                if (response.success) {
                    commit("setRole", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },
	async actGetPartnerUser({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.user.getPartner).then((response) => {
                if (response.success) {
                    commit("setPartnerUser", response.results) 
                    resolve(response)  
                } else {
                    Vue.prototype.$swal("Failed!", response.error_message, "error")
                    reject(response)
                }
            })
		})
    },

    async actGetDeliveryType({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.loanapplication.deliveryType).then((response) => {
                if (response.statusDescription === 'Success') {
                    commit("setDeliveryType", response.data) 
                    resolve(response.data)  
                }  
            }).catch((err) => { 
                Vue.prototype.$swal("Failed!", err.response.data.error, "error") 
                reject(err)
            })
		})
    },

    async actGetBankLoan({ commit }) {
        return new Promise((resolve, reject) => { 
            AxiosCall.Get([], urlApi.loanapplication.listOfBank).then((response) => {
                if (response.statusDescription === 'Success') {
                    commit("setlistOfBank", response.data) 
                    resolve(response.data)  
                }  
            }).catch((err) => { 
                Vue.prototype.$swal("Failed!", err.response.data.error, "error") 
                reject(err)
            })
		})
    },
    
}
