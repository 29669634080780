import actions from "@/store/modules/FIELDAGENT/actions";
import getters from "@/store/modules/FIELDAGENT/getters";
import state from "@/store/modules/FIELDAGENT/state";
import mutations from "@/store/modules/FIELDAGENT/mutations";

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}