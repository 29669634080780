export default {
	agriInputHandoverReportData: (state) => state.agriInputHandoverReport?.data ? state.agriInputHandoverReport.data : [],
	agriInputHandoverReportStatusType: (state) => state.agriInputHandoverReport?.statusType ? state.agriInputHandoverReport.statusType : null,
	agriInputHandoverReportTotalRows: (state) => state.agriInputHandoverReport?.totalRow ? state.agriInputHandoverReport.totalRow : 0,
	cvcTransactionReportData: (state) => state.cvcTransactionReport?.data ? state.cvcTransactionReport.data : [],
	cvcTransactionReportStatusType: (state) => state.cvcTransactionReport?.statusType ? state.cvcTransactionReport.statusType : null,
	cvcTransactionReportTotalRows: (state) => state.cvcTransactionReport?.totalRow ? state.cvcTransactionReport.totalRow : 0,
	disburseData: (state) => state.disburseList?.data ? state.disburseList.data : null,
	disburseDetailData: (state) => state.disburseDetail?.data ? state.disburseDetail.data : [],
	disburseDetailTotalRows: (state) => state.disburseDetail?.totalRow ? state.disburseDetail.totalRow : 0,
	disburseEndDate: (state) => state.disburseList?.endDate ? state.disburseList.endDate : null,
	disburseHubArea: (state) => state.disburseList?.hubArea ? state.disburseList.hubArea : null,
	disburseId: (state) => state.disburseList?.disburseId ? state.disburseList.disburseId : null,
	disburseStartDate: (state) => state.disburseList?.startDate ? state.disburseList.startDate : null,
	disburseSumFarmer: (state) => state.disburseList?.sumFarmer ? state.disburseList.sumFarmer : 0,
	disburseSumFee: (state) => state.disburseList?.sumFee ? state.disburseList.sumFee : 0,
	disburseSumNetto: (state) => state.disburseList?.sumnNetto ? state.disburseList.sumnNetto : 0,
	disburseSumPremiumPoints: (state) => state.disburseList?.sumPremiumPoints ? state.disburseList.sumPremiumPoints : 0,
	disburseUnit: (state) => state.disburseList?.unit ? state.disburseList.unit : null,
	premiumPaymentReportData: (state) => state.premiumPaymentReport?.data ? state.premiumPaymentReport.data : [],
	premiumPaymentReportStatusType: (state) => state.premiumPaymentReport?.statusType ? state.premiumPaymentReport.statusType : null,
	premiumPaymentReportTotalRows: (state) => state.premiumPaymentReport?.totalRow ? state.premiumPaymentReport.totalRow : 0,
}
