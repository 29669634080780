<template>
	<div>
		<b-card no-body border-variant="primary" class="accor">
			<b-card-header class="bg-primary header-accor">
				<b-row align-h="center" align-v="center">
					<b-col class="text-title">{{ title }}</b-col>
					<b-col class="text-right">
						<img v-b-toggle="name" class="accorimg" :src="iconaccor" />
					</b-col>
				</b-row>
			</b-card-header>
			<b-collapse :id="name" visible role="tabpanel">
				<b-card-body>
					<slot name="content" />
				</b-card-body>
				<b-card-footer v-if="footer" class="accor-footer">
					<div class="parent-div">
						<slot name="footerleft" class="child-div text-left" style="width: 30%" />
						<slot name="footercenter" class="child-div text-center" style="width: 40%" />
						<slot name="footerright" class="child-div" style="width: 30%" />
					</div>
					<!-- <slot name="content" />
					    <slot name="content" />
					    <slot name="content" /> -->
				</b-card-footer>
			</b-collapse>
		</b-card>
	</div>
</template>
<script>
	import IconAccordion from "@/assets/images/icon/iconaccor-up.png"

	export default {
		name: "KtvAccor",
		props: {
			name: {
				default: "",
				required: true,
				type: String,
			},
			title: {
				default: "",
				required: true,
				type: String,
			},
			footer: {
				default: false,
				required: false,
				type: Boolean,
			},
		},
		data() {
			return {
				iconaccor: IconAccordion,
			}
		},
	}
</script>
<style>
	.accor {
		border: 2px solid;
		border-radius: 4px;
	}
	.header-accor {
		padding: 10px !important;
		border-radius: 1px 1px 0 0 !important;
	}
	.accor-footer {
		padding: 16px;
		background-color: #f5f5f5;
	}
	.content-footer {
		display: flex;
		flex-direction: column;
	}
	.footer-left {
		justify-content: flex-start;
	}
	.footer-center {
		justify-content: center;
	}
	.footer-right {
		justify-content: flex-end;
	}
	.text-title {
		font-weight: 700;
		font-size: 14px;
		line-height: 16px;
		color: #fff;
	}
	.collapsed {
		transform: rotate(180deg) scale(1);
	}
	.accorimg {
		cursor: pointer;
	}
	.parent-div {
		width: 100%;
	}
	.child-div {
		display: inline-block;
		vertical-align: middle;
	}
</style>
