export default {
	optionPartner: [],
	optionProdCateg: [],
	optionPayMethod: [],
	optionProvince: [],
	optionDistrict: [],
	optionSubDistrict: [],
	masterLender :[],
	masterCategory:[],
	masterStatus:[],
	optionGender: [],
	optionCountry: [],
	optionLanguage: [],
	optionYesNo: [],
	optionRole: [],
	optionLoanMethodList:[],
	optionDeliveryType:[],
	optionlistOfBank:[],
	optionPartnerUser: []
}
