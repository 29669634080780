export default {
	toggleSidebarProperties: (state) => {
		state.sidebarToggleProperties.isSideNavOpen =
			!state.sidebarToggleProperties.isSideNavOpen
	},
	toggleSecondarySidebarProperties: (state) => {
		state.sidebarToggleProperties.isSecondarySideNavOpen =
			!state.sidebarToggleProperties.isSecondarySideNavOpen
	},
	toggleSecondarySidebarPropertiesViaMenuItem(state, data) {
		state.sidebarToggleProperties.isSecondarySideNavOpen = data
		state.sidebarToggleProperties.isActiveSecondarySideNav = data
	},
	toggleSecondarySidebarPropertiesViaOverlay(state) {
		state.sidebarToggleProperties.isSecondarySideNavOpen =
			!state.sidebarToggleProperties.isSecondarySideNavOpen
	},
}
