<template>
	<b-button
		v-b-tooltip.hover
		:disabled="disabled || loading"
		:size="size"
		:variant="color"
		:pill="pill"
		class="btncomponent"
		:hidden="hidden"
		:title="tooltip"
		:type="type"
		@click="$emit('click')"
	>
		<b-row class="d-flex mx-1" align-h="center" align-v="center">
			<img :src="iconreturn" />
			<div v-if="icon && text" style="width: 4px" />
			<b-spinner v-if="loading" label="Spinning" />
			<span v-if="text">{{ text }}</span>
		</b-row>
	</b-button>
</template>

<script>
	import { isObject, keys } from "underscore"

	import AddDefaultIcon from "@/assets/images/icon/iconadd.png"
	import AddGreenLightIcon from "@/assets/images/icon/iconadd-green.png"
	import CloseDefaultIcon from "@/assets/images/icon/close-gray.svg"
	import CloseLightIcon from "@/assets/images/icon/close-blue.svg"
	import CreditCardIcon from "@/assets/images/icon/credit-card-white.svg"
	import DownloadDefaultIcon from "@/assets/images/icon/download-black.svg"
	import DownloadLightIcon from "@/assets/images/icon/download-blue.svg"
	import DownloadOutlinePrimaryIcon from "@/assets/images/icon/download-blue.svg"
	import FilterIcon from "@/assets/images/icon/iconfilter.png"
	import FilterWhiteIcon from "@/assets/images/icon/filter.png"
	import RemoveIcon from "@/assets/images/icon/icondelete-white.png"
	import ResetIcon from "@/assets/images/icon/reset-blue.svg"
	import SaveIcon from "@/assets/images/icon/iconsave.png"
	import SearchIcon from "@/assets/images/icon/iconsearch.png"
	import ViewDefaultIcon from "@/assets/images/icon/iconview-gray.svg"
	import ViewLightIcon from "@/assets/images/icon/iconview-blue.svg"

	const icons = {
		add: {
			default: AddDefaultIcon,
			"green-light": AddGreenLightIcon,
		},
		close: {
			default: CloseDefaultIcon,
			light: CloseLightIcon,
		},
		"credit-card": CreditCardIcon,
		download: {
			default: DownloadDefaultIcon,
			light: DownloadLightIcon,
			"outline-primary": DownloadOutlinePrimaryIcon,
		},
		filter: FilterIcon,
		filterwhite: FilterWhiteIcon,
		remove: RemoveIcon,
		reset: ResetIcon,
		save: SaveIcon,
		search: SearchIcon,
		view: {
			default: ViewDefaultIcon,
			light: ViewLightIcon,
		},
	}
	const iconOptions = keys(icons)

	export default {
		name: "KtvButton",
		props: {
			color: {
				default: "primary",
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return (
						[
							"primary",
							"secondary",
							"success",
							"danger",
							"warning",
							"info",
							"light",
							"dark",
							"gray",
							"gray-light",
							"green-light",
							"green-dark",
							"outline-primary",
							"outline-secondary",
							"outline-success",
							"outline-danger",
							"outline-warning",
							"outline-info",
							"outline-light",
							"outline-dark",
							"outline-gray-light",
							"outline-green-light",
							"outline-green-dark",
						].indexOf(value) !== -1
					)
				},
			},
			disabled: {
				default: false,
				required: false,
				type: Boolean,
			},
			hidden: {
				default: false,
				required: false,
				type: Boolean,
			},
			icon: {
				default: null,
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return iconOptions.indexOf(value) !== -1
				},
			},
			loading: {
				default: false,
				required: false,
				type: Boolean,
			},
			pill: {
				default: false,
				required: false,
				type: Boolean,
			},
			size: {
				default: "md",
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return ["sm", "md", "lg"].indexOf(value) !== -1
				},
			},
			text: {
				default: null,
				required: false,
				type: [String, Number],
			},
			tooltip: {
				default: null,
				required: false,
				type: String,
			},
			type: {
				default: "button",
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return ["button", "submit"].indexOf(value) !== -1
				},
			},
		},
		emits: ["click"],
		data() {
			return {
				iconreturn: "",
			}
		},
		watch: {
			icon: {
				handler(val) {
					if (val) {
						if (isObject(icons[val])) {
							if (keys(icons[val]).indexOf(this.color) !== -1) {
								this.iconreturn = icons[val][this.color]
							} else {
								this.iconreturn = icons[val].default
							}
						} else {
							this.iconreturn = icons[val]
						}
					}
				},
				deep: true,
				immediate: true,
			},
		},
	}
</script>
<style>
	.btncomponent {
		height: 34px;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 16px;
		padding: 0 8px;
	}
</style>
