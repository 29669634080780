/**
 * Team Koltipay : Rudi
 */
import agriinputpayment from "@/router/agriinputpayment"
import loanRouter from "@/router/loan"
import premiumPointRouter from "@/router/premium-point"

/**
 * Team WebCentral : Sonny
 */
import farmRetailRouter from "@/router/farmRetailRouter"
import trainingRouter from "@/router/training"
import weatherRouter from "@/router/weather"
import routesFarmer from "@/router/farmer"
import fieldAgentRouter from "@/router/field_agent"
import routerCMS from "@/router/cms"


const routes = [
	{
		path: "/dummy",
		component: () => import("@/views/dummy"),
	},
	{
		path: "/",
		component: () => import("@/views"),
		// beforeEnter: authenticate,
		redirect: "/app/sessions/signIn",
		children: [
			// dashboard
			{
				path: "/dashboards",
				component: () => import("@/containers/layouts/common/mainlayout"), // dashboard
				children: [
					{
						meta: {
							ParentId: 48,
							requiresUser: true,
						},
						path: "dashboard.v1",
						name: "dashboard.v1",
						component: () => import("@/views/modules/dashboard/Dashboard"),
					},
				],
			},

			// system
			{
				path: "/system",
				// component: () => import("@/views/modules/system"),
				component: () => import("@/containers/layouts/common/mainlayout"),
				children: [
					{
						meta: {
							ParentId: 1,
							requiresUser: true,
						},
						path: "users",
						component: () => import("@/views/modules/system/users/UsersGrid"),
					},
					{
						meta: {
							ParentId: 1,
							requiresUser: true,
						},
						path: "users/usersform",
						component: () => import("@/views/modules/system/users/UsersForm"),
					},
					{
						meta: {
							ParentId: 1,
							requiresUser: true,
						},
						path: "groups",
						component: () => import("@/views/modules/system/groups/GroupsGrid"),
					},
					{
						meta: {
							ParentId: 1,
							requiresUser: true,
						},
						path: "menus",
						component: () => import("@/views/modules/system/menus/menus"),
					},
					{
						meta: {
							ParentId: 1,
							requiresUser: true,
						},
						path: "roles",
						component: () => import("@/views/modules/system/roles/RolesGrid"),
					},
				],
			},
		],
	},
	// sessions
	{
		path: "/app/sessions",
		component: () => import("@/containers/layouts/common/mainlayout"),
		redirect: "/app/sessions/signIn",
		children: [
			{
				path: "signIn",
				name: "Sign In",
				component: () => import("@/views/modules/sessions/Login"),
				meta: {
					requiresGuest: true,
				},
			},
		],
	},
	{
		path: "*",
		component: () => import("@/views/pages/notFound"),
	},
	/**
	 * Team Koltipay
	 */
	...agriinputpayment,
	...loanRouter,
	...premiumPointRouter,

	/**
	 * Team Web Central
	 */
	...farmRetailRouter,
	...trainingRouter,
	...weatherRouter,
	...routesFarmer,
	...fieldAgentRouter,
	...routerCMS,
]

export default routes
