<template>
	<validation-provider
		:ref="`input_${toSnakeCase(label)}`"
		v-slot="{ errors }"
		:immediate="immediate"
		:name="label"
		:rules="fullRules"
		class="col-md-12 mb-2"
		tag="div"
	>
		<b-form-group :label="isRequired ? `${label}*` : label" :state="!errors[0]" :invalid-feedback="errors[0]">
			<b-input-group :append="append" :prepend="prepend">
				<b-form-input
					v-model="localValue"
					:disabled="disabled"
					:max="max"
					:min="min"
					:placeholder="placeholder ? placeholder : `${$t('Insert', 'Insert')} ${label}`"
					:readonly="readonly"
					:state="!errors[0]"
					:type="localType"
					trim
					@blur="onBlur()"
					@input="$emit('input', localValue)"
				/>
			</b-input-group>
		</b-form-group>
	</validation-provider>
</template>

<script>
	import { ValidationProvider } from "vee-validate"

	export default {
		name: "KtvInput",
		components: {
			ValidationProvider,
		},
		props: {
			append: {
				default: "",
				required: false,
				type: [Number, String],
			},
			disabled: {
				default: false,
				required: false,
				type: Boolean,
			},
			immediate: {
				default: false,
				required: false,
				type: Boolean,
			},
			label: {
				default: null,
				required: true,
				type: String,
			},
			max: {
				default: null,
				required: false,
				type: [Number, String],
			},
			min: {
				default: null,
				required: false,
				type: [Number, String],
			},
			placeholder: {
				default: "",
				required: false,
				type: String,
			},
			prepend: {
				default: "",
				required: false,
				type: [Number, String],
			},
			readonly: {
				default: false,
				required: false,
				type: Boolean,
			},
			rules: {
				default: "",
				required: false,
				type: String,
			},
			type: {
				default: "text",
				required: false,
				type: String,
			},
			value: {
				default: null,
				required: false,
				type: [String, Number],
			},
		},
		emits: ["input"],
		data() {
			return {
				isRequired: this.rules.search("required") >= 0,
				localValue: null,
				localType: null,
			}
		},
		computed: {
			fullRules() {
				let rules = this.rules

				if (this.type !== "date") {
					if (this.max) {
						rules += `|max_value:${this.max}`
					}
					if (this.min) {
						rules += `|min_value:${this.min}`
					}
				} else {
					rules += `|date_format:yyyy-MM-dd`

					if (this.max && this.min) {
						rules += `|date_format:yyyy-MM-dd|date_between:${this.min},${this.max}`
					}
				}

				return rules
			},
		},
		watch: {
			type: {
				handler(type) {
					this.localType = type === "currency" ? "text" : type
				},
				immediate: true,
			},
			value: {
				handler(value) {
					this.localValue = value
				},
				immediate: true,
			},
		},
		methods: {
			formatCashAmount() {
				this.localValue = this.$options.filters.Number(this.localValue)
			},
			onBlur() {
				if (this.type === "currency") {
					this.formatCashAmount()
				}
			},
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>
