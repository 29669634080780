import Vue from "vue"
import { urlAuth, urlApi } from "@/constants/urlApi"
import AxiosCall from "@/libs/axios/axiosCall"

export default {
	async actGetOption({ commit }, type) {
		let payload = { Type: type }
		return new Promise((resolve, reject) => {
			AxiosCall.Get(payload, urlApi.weather.getSelectionList).then((response) => {
				if (response.success) {
					commit("setOption", { type: type, res: response.results })
					resolve(response)
				} else {
					Vue.prototype.$swal("Failed!", response.error_message, "error")
					reject(response)
				}
			})
		})
	},
	ActionTablesGrid({ commit }, payload) {
		return new Promise((resolve, reject) => {
			AxiosCall.Post(Object.assign(payload), urlAuth + "weather/list")
				.then((response) => {
					if (response.success && response.results !== null) {
						commit("setTotalRecords", response.results.total)
						resolve(response.results.data)
					} else {
						Vue.prototype.$swal("Empty!", response.data, "error")
					}
				})
				.catch((err) => {
					Vue.prototype.$swal("Failed!", err.response.data.error, "error")
					reject(err)
				})
		})
	},
}
