<template>
	<div>
		<b-card
			:border-variant="border"
			:header="title"
			:header-bg-variant="bg"
			:header-text-variant="color"
			:align="align"
			no-body
		/>
	</div>
</template>
<script>
	export default {
		name: "KtvHeadForm",
		props: {
			title: {
				default: "",
				required: true,
				type: String      
			},
			border: {
				default: "secondary",
				required: false,
				type: String
			},
			bg: {
				default: "secondary",
				required: false,
				type: String
			},
			color: {
				default: "white",
				required: false,
				type: String
			},
			align: {
				default: "left",
				required: false,
				type: String
			}
		}
	}
</script>