export default [
	{
		path: "/",
		component: () => import("@/views"),
		redirect: "/app/sessions/signIn",
		children: [
			{
				name: "Premium Points",
				path: "/premium-point",
				component: () => import("@/containers/layouts/common/mainlayout"),
				redirect: {
					name: "Premium Points Disburse List",
				},
				children: [
					{
						name: "Premium Points AO Package Setting List",
						path: "ao-package-setting",
						component: () => import("@/views/modules/premium-point/ao-package/list/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points AO Package Setting Add",
						path: "ao-package-setting/add",
						component: () => import("@/views/modules/premium-point/ao-package/list/add/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points AO Package Setting Detail",
						path: "ao-package-setting/:district/:ao",
						component: () => import("@/views/modules/premium-point/ao-package/view/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points Product List",
						path: "product",
						component: () => import("@/views/modules/premium-point/product/list/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points Disburse List",
						path: "disburse",
						component: () => import("@/views/modules/premium-point/disburse/list/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points Disburse Detail",
						path: "disburse/:id",
						component: () => import("@/views/modules/premium-point/disburse/view/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points Disburse Generated",
						path: "disburse/generated",
						component: () => import("@/views/modules/premium-point/disburse/view/Generated"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						}
					},
					{
						name: "Premium Points Disburse Request List",
						path: "disburse-request",
						component: () => import("@/views/modules/premium-point/disburse-request/list/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points Disburse Request Detail",
						path: "disburse-request/:id",
						component: () => import("@/views/modules/premium-point/disburse-request/view/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						}
					},
					{
						name: "Premium Points Agri Input",
						path: "agri-input",
						redirect: {
							name: "Premium Points Agri Input Payment List"
						},
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points Agri Input Payment List",
						path: "agri-input/payment",
						component: () => import("@/views/modules/premium-point/agri-input/payment/list/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						}
					},
					{
						name: "Premium Points Agri Input Payment Detail",
						path: "agri-input/payment/:id",
						component: () => import("@/views/modules/premium-point/agri-input/payment/view/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						}
					},
					{
						name: "Premium Points Agri Input Request List",
						path: "agri-input/request",
						component: () => import("@/views/modules/agriinputrequest/index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						}
					},
					{
						name: "Premium Points Agri Input Progress List",
						path: "agri-input/progress",
						component: () => import("@/views/modules/premium-point/agri-input/progress/list/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						}
					},
					{
						name: "Premium Points Agri Input Progress Detail",
						path: "agri-input/progress/:id",
						component: () => import("@/views/modules/premium-point/agri-input/progress/view/Index"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						}
					},
					{
						name: "Premium Points Agri Input Handover Report",
						path: "report/agri-input-handover/:id",
						component: () => import("@/views/modules/premium-point/report/view/AgriInputHandover"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points CVC Transaction Report",
						path: "report/cvc-transaction/:id",
						component: () => import("@/views/modules/premium-point/report/view/CVCTransaction"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
					{
						name: "Premium Points Premium Payment Report",
						path: "report/premium-payment/:id",
						component: () => import("@/views/modules/premium-point/report/view/PremiumPayment"),
						meta: {
							parent: "Premium Points",
							requiresUser: true,
						},
					},
				]
			}
		]
	}
]
