<template>
	<b-modal
		v-model="open"
		:centered="centered"
		:lazy="lazy"
		:no-close-on-backdrop="noCloseOnBackdrop"
		:no-close-on-esc="noCloseOnEsc"
		:size="size"
		:title="title"
	>
		<slot name="content" />
		<template #modal-footer>
			<div class="w-100">
				<slot name="footer" />
				<div class="parent-div">
					<slot name="footerleft" class="child-div text-left" style="width: 30%" />
					<slot name="footercenter" class="child-div text-center" style="width: 40%" />
					<slot name="footerright" class="child-div" style="width: 30%" />
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: "KtvModal",
		props: {
			centered: {
				default: false,
				required: false,
				type: Boolean,
			},
			lazy: {
				default: true,
				required: false,
				type: Boolean,
			},
			noCloseOnBackdrop: {
				default: true,
				required: false,
				type: Boolean,
			},
			noCloseOnEsc: {
				default: false,
				required: false,
				type: Boolean,
			},
			size: {
				default: "md",
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return ["sm", "md", "lg", "xl"].indexOf(value) !== -1
				},
			},
			title: {
				default: null,
				required: false,
				type: [String, Number],
			},
			value: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				open: this.value || false,
			}
		},
		watch: {
			open: {
				handler(open) {
					if (!open) {
						this.$emit("close")
					}
				},
			},
			value: {
				handler(value) {
					this.open = value
				},
			},
		},
	}
</script>
<style>
	.parent-div {
		width: 100%;
	}
	.child-div {
		display: inline-block;
		vertical-align: middle;
	}
</style>
