<template>
	<validation-provider
		:ref="`image_upload_${toSnakeCase(label)}`"
		v-slot="{ errors }"
		:immediate="immediate"
		:name="label"
		:rules="rules"
		class="col-md-12 mb-2"
		tag="div"
	>
		<b-form-group :label="isRequired ? `${label}*` : label" :state="!errors[0]" :invalid-feedback="errors[0]">
			<b-row>
				<b-col v-if="currentFile" class="align-self-center col-md-4">
					<b-img :src="currentFile" fluid thumbnail :alt="label" />
				</b-col>
				<b-col class="align-self-center" :class="currentFile ? 'col-md-8' : 'col-md-12'">
					<vue-dropzone
						:id="`image-upload-${toKebabCase(label)}`"
						:ref="`upload_${toSnakeCase(label)}`"
						:options="dropzoneOptions"
						:duplicate-check="true"
						@vdropzone-file-added="onAdded"
					/>
				</b-col>
			</b-row>
		</b-form-group>
	</validation-provider>
</template>
<script>
	import { urlApi } from "@/constants/urlApi"
	import { ValidationProvider } from "vee-validate"

	import vue2Dropzone from "vue2-dropzone"
	import "vue2-dropzone/dist/vue2Dropzone.min.css"

	export default {
		name: "KtvImageUpload",
		components: { ValidationProvider, vueDropzone: vue2Dropzone },
		props: {
			currentFile: {
				default: null,
				required: false,
				type: String,
			},
			file: {
				default: null,
				required: false,
				type: File,
			},
			immediate: {
				default: false,
				required: false,
				type: Boolean,
			},
			label: {
				default: "",
				required: false,
				type: String,
			},
			rules: {
				default: "",
				required: false,
				type: String,
			},
		},
		emits: ["on-added"],
		data() {
			return {
				dropzoneOptions: {
					autoProcessQueue: false,
					acceptedFiles: ".jpg,.jpeg,.png",
					headers: null,
					maxFiles: 1,
					maxFilesize: 5,
					parallelUploads: 1,
					thumbnailWidth: 150,
					url: urlApi.others.uploadImage,
					withCredentials: true,
					dictDefaultMessage: `<i class='fa fa-cloud-upload mr-2'></i>${this.$t(
						"Drop here or click to upload image (.jpg,.jpeg,.png)",
						"Drop here or click to upload image (.jpg,.jpeg,.png)",
					)}`,
				},
				isRequired: this.rules.search("required") >= 0,
				localFile: null,
			}
		},
		watch: {
			file: {
				handler() {
					this.localFile = Object.assign({}, this.file)
				},
				immediate: true,
			},
		},
		methods: {
			async onAdded(file) {
				const isValid = await this.$refs[`image_upload_${this.toSnakeCase(this.label)}`].validate(file)

				if (isValid) {
					if (this.localFile) {
						this.$refs[`upload_${this.toSnakeCase(this.label)}`].removeFile(this.localFile)
					}

					this.localFile = file
					this.$emit("on-added", file)
				}
			},
			toKebabCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "-") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>

<style lang="sass">
	.dropzone
		min-height: 0
</style>
