/**
 * State register
 */

export default function () {
	return { 
		TotalRecords : 0, 
		loanID : null,
		CashProductApproved : 0,
		SummaryResult :[]
	}
}
