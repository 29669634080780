import Vue from "vue"
import App from "./App.vue"
import router from "./router/routes"
import store from "./store"

// GullKit theme
import GullKit from "./plugins/gull.kit"
Vue.use(GullKit)

// Fontawesome
import "font-awesome/css/font-awesome.min.css"
import "@/assets/styles/css/custom.css"
Vue.component(
	"VueFontawesome",
	require("vue-fontawesome-icon/VueFontawesome.vue").default,
)

// Global component multiselect
import vMultiselectListbox from "vue-multiselect-listbox"
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css"
Vue.component("VMultiselectListbox", vMultiselectListbox)

// Global component breadcrumb
import { KtvBreadcrumb } from '@/components'
Vue.component("KtvBreadcrumb", KtvBreadcrumb)
// Global component back
import { KtvBack } from '@/components'
Vue.component("KtvBack", KtvBack)
// Global component accordion
import { KtvAccor } from '@/components'
Vue.component("KtvAccor", KtvAccor)

// Global axiosCall
import axiosCall from "./libs/axios/axiosCall"
const plugin = {
	install() {
		Vue.axiosCall = axiosCall
		Vue.prototype.$http = axiosCall
	},
}
Vue.use(plugin)

// Global currency filter
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, [{
	avoidEmptyDecimals: undefined,
	fractionCount: 0,
	fractionSeparator: '.',
	name: 'idr',
	symbolSpacing: false,
	thousandsSeparator: ',',
}, {
	avoidEmptyDecimals: undefined,
	fractionCount: 0,
	fractionSeparator: '.',
	name: 'IDR',
	symbol: 'Rp',
	symbolPosition: 'front',
	symbolSpacing: false,
	thousandsSeparator: ',',
}])

import CurrencyFilter from '@anggiedimasta/vue-currency-filter'
Vue.use(CurrencyFilter, [{
	avoidEmptyDecimals: '',
	fractionCount: 2,
	fractionSeparator: '.',
	name: 'Number',
	symbolSpacing: false,
	thousandsSeparator: ',',
}, {
	avoidEmptyDecimals: '',
	fractionCount: 0,
	fractionSeparator: '.',
	name: 'Rupiah',
	symbol: 'Rp',
	symbolPosition: 'front',
	symbolSpacing: false,
	thousandsSeparator: ',',
}])

// Global urlApi
import { urlApi } from "./constants/urlApi"
Vue.prototype.$urlApi = urlApi

Vue.config.productionTip = false

// skeleton loader
// https://lucasleandro1204.github.io/vue-content-loading/#/guide?id=usage
import { VclFacebook, VclList } from 'vue-content-loading'
Vue.component("VclFacebook", VclFacebook)
Vue.component("VclList", VclList)

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import OpenIndicator from './components/OpenIndicator'

vSelect.props.components.default = () => ({ OpenIndicator })
Vue.component('VSelect', vSelect)

// helper function
import Mixin from './libs/helper'
Vue.mixin(Mixin)

/** language */
import vuexI18n from "vuex-i18n"
const config = {
	warnings: false
}
Vue.use(vuexI18n.plugin, store, config)

// Excel Plugin
// https://www.npmjs.com/package/vue-excel-xlsx
// import VueExcelXlsx from "vue-excel-xlsx"
// Vue.use(VueExcelXlsx)
import JsonExcel from "vue-json-excel"
Vue.component("DownloadExcel", JsonExcel)

import x5GMaps from 'x5-gmaps'
// Option 2: With libraries
Vue.use(x5GMaps, { key: 'AIzaSyACXVwWCJen2OZeCAEYdRxP_HEh7CkxOvs', libraries: ['places'] })

// https://github.com/hellocomet/vue-svg-gauge
import VueSvgGauge from 'vue-svg-gauge'
Vue.use(VueSvgGauge)
new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app")
