export default {
	getTransactionCode(state) {
		return state.transactionCode
	},
	getOpsiDisplay(state) {
		return state.opsiDisplay
	},
	getError(state) {
		return state.error
	}
}
