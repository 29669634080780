import axios from "axios"

const getToken = () => {
	return localStorage.getItem("accessToken") !== undefined ? "Bearer " + localStorage.getItem("accessToken") : ""
}

export const headers = {
	headers: {
		"Content-Type": "application/json",
		Authorization: getToken(),
		token: getToken(),
		commodityid: 1,
	}
}

const axiosOBJ = axios.create(headers)

const contentType = (type) => {
	axiosOBJ.interceptors.request.use((config) => {
		config.headers["Content-Type"] = type
		config.headers.Authorization = getToken()
		config.headers.token = getToken()
		return config
	})
}

const crossOrigin = (cross) => {
	axiosOBJ.interceptors.request.use((config) => {
		config.headers["Access-Control-Allow-Origin"] = cross
		config.headers.Authorization = getToken()
		config.headers.token = getToken()
		return config
	})
}

// axiosOBJ.interceptors.request.use(req => {
//     // store.commit("TOGGLE_LOADING", true)
//     req.headers.Authorization = getToken();
//     return req;
// });

axiosOBJ.interceptors.response.use(
	(res) => {
		// store.commit("TOGGLE_LOADING", false)
		if (typeof res.data !== "string") {
			res.data.notify = {
				title: "Success",
				text: res.data.message,
				iconPack: "feather",
				icon: "icon-check-square",
				color: "success",
				position: "top-right",
			}
		}

		return Promise.resolve(res.data)
	},
	function (error) {
		// store.commit("TOGGLE_LOADING", false)
		if (error.code === "ECONNABORTED") {
			this.$store.dispatch("AUTHDATA/logoutJWT")
			window.location = "/login"
		} else if (error.response.status === 401) {
			this.$store.dispatch("AUTHDATA/logoutJWT")
			window.location = "/login?redirect=" + this.$store.state["AUTHDATA/page"]
		}

		/* else if (error.response.status === 500) {
	console.log(error)
  } else if (error.response.status === 400) {
	console.log(error)
  } */

		return Promise.reject(error)
	},
)

class AxiosCall {
	static Post(payload, url) {
		contentType("application/json")
		crossOrigin("*")
		return axiosOBJ.post(url, payload)
	}

	static Put(payload, url) {
		contentType("application/json")
		return axiosOBJ.put(url, payload)
	}

	static Delete(payload, url) {
		contentType("application/json")
		return axiosOBJ.delete(url, { data: payload })
	}

	static Get(payload, url) {
		contentType("application/json")
		return axiosOBJ.get(url, { params: payload })
	}

	static GetNoQueryParams(payload, url) {
		contentType("application/json")
		// crossOrigin('*')
		return axiosOBJ.get(url + payload)
	}
}

export default AxiosCall
