export default {
	getEntityID(state) {
		return state.entityID
	},
	getObjectID(state) {
		return state.objectID
	},
	getOptions(state) {
		return state.options
	},
	getOpsiDisplay(state) {
		return state.opsiDisplay
	},
	getError(state) {
		return state.error
	}
}
