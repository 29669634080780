export default {
	SET_AO_NUMBER_LIST(state, aoNumberList) {
		state.aoNumberList = aoNumberList
	},
	SET_CATEGORY_LIST(state, categoryList) {
		state.categoryList = categoryList
	},
	SET_COUNTRY_LIST(state, countryList) {
		state.countryList = countryList
	},
	SET_CURRENCY_LIST(state, currencyList) {
		state.currencyList = currencyList
	},
	SET_CUSTOMER_TYPE_LIST(state, customerTypeList) {
		state.customerTypeList = customerTypeList
	},
	SET_DISTRICT_LIST(state, districtList) {
		state.districtList = districtList
	},
	SET_DOCUMENT_TYPE_LIST(state, documentTypeList) {
		state.documentTypeList = documentTypeList
	},
	SET_GENDER_LIST(state, genderList) {
		state.genderList = genderList
	},
	SET_HUB_AREA_LIST(state, hubAreaList) {
		state.hubAreaList = hubAreaList
	},
	SET_KIOSK_LIST(state, kiosKList) {
		state.kiosKList = kiosKList
	},
	SET_LEGAL_STATUS_LIST(state, legalStatusList) {
		state.legalStatusList = legalStatusList
	},
	SET_ORGANISATION_NAME_LIST(state, organisationNameList) {
		state.organisationNameList = organisationNameList
	},
	SET_ORGANISATION_ROLE_LIST(state, organisationRoleList) {
		state.organisationRoleList = organisationRoleList
	},
	SET_PACKAGE_TYPE_LIST(state, packageTypeList) {
		state.packageTypeList = packageTypeList
	},
	SET_PAYMENT_METHOD_LIST(state, paymentMethodList) {
		state.paymentMethodList = paymentMethodList
	},
	SET_PRIORITY_LIST(state, priorityList) {
		state.priorityList = priorityList
	},
	SET_PRODUCT_LIST(state, productList) {
		state.productList = productList
	},
	SET_PRODUCT_NAME_LIST(state, productNameList) {
		state.productNameList = productNameList
	},
	SET_PROVINCE_LIST(state, provinceList) {
		state.provinceList = provinceList
	},
	SET_STATUS_PREMIUM_LIST(state, statusPremiumList) {
		state.statusPremiumList = statusPremiumList
	},
	SET_SUBDISTRICT_LIST(state, subDistrictList) {
		state.subDistrictList = subDistrictList
	},
	SET_UNIT_LIST(state, unitList) {
		state.unitList = unitList
	},
	SET_UNIT_INFO_LIST(state, unitInfoList) {
		state.unitInfoList = unitInfoList
	},
	SET_VILLAGE_LIST(state, villageList) {
		state.villageList = villageList
	},
}
