import { headers } from "@/libs/axios/axiosCall"
import { map, find } from "underscore"
import { urlApi } from "@/constants/urlApi"
import axios from 'axios'
import router from '@/router/routes'
import Vue from 'vue'

export default {
	addProduct({ commit }, product = null) {
		commit('ADD_PACKAGE_PRODUCT', product)
	},
	async createPackage(_context, pkg) {
		const createPackage = await axios
			.post(urlApi.package.create, pkg, headers)
			.then((res) => res)
			.catch(() => null)

		if (createPackage.status === 200) {
			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, createPackage.statusText, "success")

			router.push({ name: 'Loan Institution Data', params: { id: pkg.loanInstitutionId } })
		} else {
			const errorMessage = createPackage?.statusText || Vue.prototype.$t("Unable to create package", "Unable to create package")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}
	},
	deleteProduct({ commit }, productId = null) {
		commit('DELETE_PACKAGE_PRODUCT', productId)
	},
	async getPackage({ commit }, packageId = null) {
		const pkg = await axios
			.get(urlApi.package.view(packageId), headers)
			.then((res) => res)
			.catch(() => null)

		if (pkg.status === 200) {
			commit('SET_PACKAGE', pkg.data.data[0])
		} else {
			const errorMessage = pkg?.statusText || Vue.prototype.$t("No available package data", "No available package data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PACKAGE', pkg)
		}
	},
	async getPackageList({ commit }, params = null) {
		const packageList = await axios
			.get(urlApi.package.list, {
				params: params,
				headers: { ...headers }
			})
			.then((res) => res)
			.catch(() => null)

		if (packageList.status === 200) {
			commit('SET_PACKAGE_LIST', {
				packageList: packageList.data.data,
				totalRows: packageList.data.count
			})
		} else {
			const errorMessage = packageList?.statusText || Vue.prototype.$t("No available package list data", "No available package list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PACKAGE_LIST', {
				packageList: packageList.data.data || [],
				totalRows: 0
			})
		}
	},
	async getProductList({ commit }, { kioskId = null, product = [] }) {
		const productList = await axios
			.get(urlApi.others.productList(kioskId), headers)
			.then((res) => res)
			.catch(() => null)

		if (productList.status === 200) {
			const products = map(product, (prd) => {
				const productDetail = find(productList.data.results, (productOption) => parseInt(productOption.ProductID, 10) === prd.productId)

				return {
					...prd,
					...productDetail,
				}
			})

			commit('SET_PACKAGE_PRODUCT_LIST', products)
		} else {
			const errorMessage = productList?.error_message || Vue.prototype.$t("No available product list data", "No available product list data")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
			commit('SET_PACKAGE_PRODUCT_LIST', productList.data.results || [])
		}
	},
	resetPackage({ commit }) {
		commit('RESET_PACKAGE')
	},
	resetProductList({ commit }) {
		commit('RESET_PACKAGE_PRODUCT_LIST')
	},
	async updatePackage(_context, pkg) {
		const updatePackage = await axios
			.put(urlApi.package.update, pkg, headers)
			.then((res) => res)
			.catch(() => null)

		if (updatePackage.status === 200) {
			Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, updatePackage.statusText, "success")

			router.push({ name: 'Loan Institution Data', params: { id: pkg.loanInstitutionId } })
		} else {
			const errorMessage = updatePackage?.statusText || Vue.prototype.$t("Unable to update package", "Unable to update package")

			Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, errorMessage, "error")
		}
	},
	updateProduct({ commit }, product = null) {
		commit('UPDATE_PACKAGE_PRODUCT', product)
	},
}
