<template>
	<validation-provider
		:ref="`input_${toSnakeCase(label)}`"
		v-slot="{ errors }"
		:immediate="immediate"
		:name="label"
		:rules="rules"
		class="col-md-12 mb-2"
		tag="div"
	>
		<b-form-group :label="isRequired ? `${label}*` : label" :state="!errors[0]" :invalid-feedback="errors[0]">
			<b-form-textarea
				v-model="localValue"
				:disabled="disabled"
				:readonly="readonly"
				:state="!errors[0]"
				:placeholder="placeholder ? placeholder : `Insert ${label}`"
				:rows="rows"
				:max-rows="maxRows"
				type="text"
				@input="$emit('input', localValue)"
			/>
		</b-form-group>
	</validation-provider>
</template>

<script>
	import { ValidationProvider } from "vee-validate"

	export default {
		name: "KtvInput",
		components: {
			ValidationProvider,
		},
		props: {
			disabled: {
				default: false,
				required: false,
				type: Boolean,
			},
			immediate: {
				default: false,
				required: false,
				type: Boolean,
			},
			label: {
				default: null,
				required: true,
				type: String,
			},
			maxRows: {
				default: 6,
				required: false,
				type: Number,
			},
			placeholder: {
				default: "",
				required: false,
				type: String,
			},
			readonly: {
				default: false,
				required: false,
				type: Boolean,
			},
			rules: {
				default: "",
				required: false,
				type: String,
			},
			rows: {
				default: 3,
				required: false,
				type: Number,
			},
			type: {
				default: "text",
				required: false,
				type: String,
			},
			value: {
				default: null,
				required: false,
				type: String,
			},
		},
		emits: ["input"],
		data() {
			return {
				isRequired: this.rules.search("required") >= 0,
				localValue: null,
			}
		},
		watch: {
			value: {
				handler(value) {
					this.localValue = value
				},
				immediate: true,
			},
		},
		methods: {
			toSnakeCase(string) {
				return string
					.replace(/([a-z])([A-Z])/g, "$1-$2") // get all lowercase letters that are near to uppercase ones
					.replace(/[\s-]|[\s/]+/g, "_") // replace all spaces and dash
					.toLowerCase() // convert to lower case
			},
		},
	}
</script>
