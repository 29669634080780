export default {
	SET_PAYMENT_LANGUAGE_LIST(state, { data, type }) {
		let key = null
		if (type === "loan-to-retailer") {
			key = "retailer"
		} else if (type === "loan-to-farmer") {
			key = "farmer"
		} else if (type === "premium-point") {
			key = "premiumPoint"
		}

		state.paymentLanguageList = {
			...state.paymentLanguageList,
			[key]: data
		}
	},
	SET_PAYMENT_METHOD_LIST(state, { data, type }) {
		let key = null
		if (type === "loan-to-retailer") {
			key = "retailer"
		} else if (type === "loan-to-farmer") {
			key = "farmer"
		} else if (type === "premium-point") {
			key = "premiumPoint"
		}

		state.paymentMethodList = {
			...state.paymentMethodList,
			[key]: data
		}
	},
	SET_PAYMENT_STATUS_LIST(state, { data, type }) {
		let key = null
		if (type === "loan-to-retailer") {
			key = "retailer"
		} else if (type === "loan-to-farmer") {
			key = "farmer"
		} else if (type === "premium-point") {
			key = "premiumPoint"
		}

		state.paymentStatusList = {
			...state.paymentStatusList,
			[key]: data
		}
	},
}
