import Vue from 'vue'
import { basicAuth, urlApi, urlAuth } from "@/constants/urlApi"
import axios from 'axios'
import AxiosCall, { headers } from "@/libs/axios/axiosCall"
import router from '@/router/routes'

/**
 * Action ActionTablesGrid
 * @param payload
 * Tables Grid
 * Method GET
 */
export const ActionTablesGrid = async ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				urlApi.premiumPoint.list, {
				params: params,
				...basicAuth,
				...headers,
			}

			)
			.then((response) => {
				commit("setAgriinputRequest", response.data.data)
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No available data', 'No available data'), "error")
			})
	})
}


export const ActionTablesGridProgress = async ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				urlApi.premiumPoint.disburseDetailAgriInput, {
				params: params,
				...basicAuth,
				...headers,
			},
			)
			.then((response) => {
				commit("setAgriinputProgress", response.data.data)
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
				router.push('/premium-point/agri-input/request')
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No available data', 'No available data'), "error")
			})
	})
}

export const ActionTablesGridProgressDetail = async ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				urlApi.premiumPoint.getRecomenPackageFarmer, {
				params: params,
				...basicAuth,
				...headers,
			},
			)
			.then((response) => {
				commit("setAgriinputProgressDetail", response.data.data)
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
				router.push('/premium-point/agri-input/request')
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No available data', 'No available data'), "error")
			})
	})
}

/**
 * Action ActionTablesGrid
 * @param payload
 * Tables Grid
 * Method GET
 */
export const ActionCVCTablesGrid = async ({ commit }, params) => {
	return new Promise((resolve, reject) => {
		axios
			.get(
				urlApi.premiumPoint.getCvcData, {
				params: params,
				...basicAuth,
				...headers,
			},
			)
			.then((response) => {
				commit("setTotalRecordsCvc", response.data.data.length)
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No available data', 'No available data'), "error")
			})
	})
}
export const ActionCVCTablesGridDetil = async ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		AxiosCall.Get(payload, urlAuth + 'transaction/premium-point/kiosbydistrict')
			.then((response) => {
				if (response.success === true) {
					commit("setTotalRecordsCvc", parseFloat(response.results.total_data, 10000))
					resolve(response.results)
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, response.data, "error")
				}

			})
			.catch((err) => {
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, err.response.data.error, "error")
				reject(err)
			})
	})
}


export const ActionAddNewSubmit = async (_, payload) => {
	return new Promise((resolve, reject) => {
		axios
			.post(urlApi.premiumPoint.insertCvcData, Object.assign(payload), {
				...basicAuth,
				...headers,
			})
			.then((response) => {
				if (response.data.statusDescription === "Success") {
					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, Vue.prototype.$t('Insert data success', 'Insert data success'), "success")
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('Insert data failed', 'Insert data failed'), "error")
				}
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No available data', 'No available data'), "error")
			})
	})
}

export const ActionDeleteCVC = async (_, cvcid) => {
	return new Promise((resolve, reject) => {
		axios
			.delete(urlApi.premiumPoint.deleteCvcData(cvcid), {
				...basicAuth,
				...headers,
			})
			.then((response) => {
				if (response.data.statusDescription === "Success") {
					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, Vue.prototype.$t('Delete data success', 'Delete data success'), "success")
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('Delete data failed', 'Delete data failed'), "error")
				}
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No available data', 'No available data'), "error")
			})
	})
}


export const ActionSentToCvc = async (_, payload) => {
	return new Promise((resolve, reject) => {
		axios
			.post(urlApi.premiumPoint.sentToCvc, Object.assign(payload), {
				...basicAuth,
				...headers,
			})
			.then((response) => {
				if (response.data.statusDescription === "Success") {
					Vue.prototype.$swal(`${Vue.prototype.$t("Success", "Success")}!`, Vue.prototype.$t('Insert data success', 'Insert data success'), "success")
				} else {
					Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('Insert data failed', 'Insert data failed'), "error")
				}
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
				Vue.prototype.$swal(`${Vue.prototype.$t("Failed", "Failed")}!`, Vue.prototype.$t('No available data', 'No available data'), "error")
			})
	})
}
