export default {
	getOptionMonth(state) {
		return state.optionMonth
	},
	getOptionPlantPhase(state) {
		return state.optionPlantPhase
	},
	getOptionHumidity(state) {
		return state.optionHumidity
	},
	getOptionEvent(state) {
		return state.optionEvent
	},
	getOptionRainfall(state) {
		return state.optionRainfall
	},
	getOptionTemp(state) {
		return state.optionTemp
	},
	getTotalRecords(state) {
		return state.TotalRecords
	},
}
