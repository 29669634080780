<template>
	<!-- <b-card :class="`card-icon-bg card-icon-bg-${color} o-hidden mb-30 text-center`">
		<i :class="icon" />
		<div class="content text-center">
			<p :class="`text-${titleColor} mt-2 mb-0`">
				{{ title }}
			</p>
			<p :class="`text-${color} text-24 line-height-1 mb-2`">
				{{ subtitle }}
			</p>
		</div>
	</b-card> -->
	<b-card no-body class="maincard">
		<b-row align-v="stretch" class="contentcard">
			<b-col cols="8" class="textcard">
				<p :class="`text-${color} textcolor text-23 line-height-1 mb-2`">
					{{ subtitle }}
				</p>
				<p :class="`text-${titleColor} textcolor text-14 mt-2 mb-0`">
					{{ title }}
				</p>
				<p :class="`text-${titleColor} textcolor text-11 mt-2 mb-0`">
					{{ desc }}
				</p>
			</b-col>
			<b-col cols="4">
				<img :src="icon" class="iconcard">
				<!-- <i :class="`${icon} iconcard text-78`" /> -->
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
	export default {
		name: "KtvCard",
		props: {
			color: {
				default: "primary",
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return (
						[
							"danger",
							"dark",
							"gray-100",
							"gray-200",
							"gray-300",
							"gray-400",
							"gray-500",
							"gray-600",
							"gray-700",
							"gray-800",
							"gray-900",
							"info",
							"light",
							"primary",
							"secondary",
							"success",
							"warning",
						].indexOf(value) !== -1
					)
				},
			},
			icon: {
				default: null,
				required: true,
				type: String,
			},
			subtitle: {
				default: null,
				required: false,
				type: [String, Number],
			},
			title: {
				default: null,
				required: false,
				type: [String, Number],
			},
			desc: {
				default: "",
				required: false,
				type: [String, Number],
			},
			titleColor: {
				default: "muted",
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return (
						[
							"danger",
							"dark",
							"gray-100",
							"gray-200",
							"gray-300",
							"gray-400",
							"gray-500",
							"gray-600",
							"gray-700",
							"gray-800",
							"gray-900",
							"info",
							"light",
							"muted",
							"primary",
							"secondary",
							"success",
							"warning",
						].indexOf(value) !== -1
					)
				},
			},
		},
	}
</script>

<style>
	.maincard {
		height: 140px;
		margin-bottom: 20px;
		padding: 24px 20px;
		border: 1px solid lightgray;
	}
	.contentcard {
		height: 100%;
	}
	.textcard {
		margin: auto;
		padding-right: 0;
	}
	.iconcard {
		height: 100%;
	}
	.maincard:hover {
		background-color: #54A7DC;
  		transition: 0.3s;
	}
	.maincard:hover .iconcard {
		filter: brightness(0) invert(1);
	}
	.maincard:hover .textcolor {
		color: white !important;
	}
</style>