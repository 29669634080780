<template>
	<b-modal v-model="open" :no-close-on-backdrop="noCloseOnBackdrop" :no-close-on-esc="noCloseOnEsc" :size="size" :title="title">
		<div style="display: flex; align-items: center">
			<div v-if="icon" class="p-4 text-secondary" style="align-items: center">
				<i v-if="!loading" :class="`nav-icon ${icon}`" style="font-size: 4rem" />
				<b-spinner v-if="loading" label="Spinning" />
			</div>
			<div v-if="text" class="p-2" style="align-items: center">
				<b-card-body :title="text" />
			</div>
		</div>
		<template #modal-footer>
			<div class="w-100" align="right">
				<slot name="footer" />
			</div>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: "KtvDialog",
		props: {
			icon: {
				default: "i-Speach-Bubble-Asking",
				required: false,
				type: String,
			},
			loading: {
				default: false,
				required: false,
				type: Boolean,
			},
			noCloseOnBackdrop: {
				default: true,
				required: false,
				type: Boolean,
			},
			noCloseOnEsc: {
				default: false,
				required: false,
				type: Boolean,
			},
			size: {
				default: "md",
				required: false,
				type: String,
				validator: function (value) {
					// The value must match one of these strings
					return ["sm", "md", "lg", "xl"].indexOf(value) !== -1
				},
			},
			text: {
				default: "",
				required: true,
				type: String,
			},
			title: {
				default: "",
				required: true,
				type: String,
			},
			value: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close"],
		data() {
			return {
				open: this.value || false,
			}
		},
		watch: {
			open: {
				handler(open) {
					if (!open) {
						this.$emit("close")
					}
				},
			},
			value: {
				handler(value) {
					this.open = value
				},
			},
		},
	}
</script>
