 
export const setLoanFXRecords = (state, value) => {
	state.LoanFXRecords = value
}  

export const setLoanProductFX = (state, value) => {
	state.LoanProductFX = value
}
export const setLoanPetaniFX = (state, value) => {
	state.LoanPetaniFX = value
}
export const setLoanBusinnessFX = (state, value) => {
	state.LoanBusinnessFX = value
}

export const setAmbilBarangFX = (state, value) => {
	state.LoanAmbilBarangFX = value
} 

export const setPlatform = (state, value) => {
	state.PlatformList = value
} 

export const setSearchResult = (state, value) => {
	state.SearchResult = value
} 

export const setdocumentTypeList = (state, value) => {
	state.documentTypeList = value
} 

export const setdistrict = (state, value) => {
	state.districtName = value
} 

