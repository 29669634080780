<template>
	<div>
		<div class="breadcrumb">
			<slot name="header">
				<div class="row">
					<div class="col-12">
						<h2 class="titlebreadcumb">{{ title }}</h2>
					</div>
					<div class="col-12"> 
						<div class="menubreadcumb">
							{{ folder }} | {{ page }}
						</div>
						<!-- <ul class="menubreadcumb">
							<li>
								<a href=""> {{ folder }} </a>
							</li>
							<li>{{ page }}</li>
						</ul> -->
					</div>
				</div>
			</slot>
		</div>
		<div class="separator-breadcrumb border-top" />
	</div>
</template>
<script>
	export default {
		name: "KtvBreadcrumb",
		props: {
			folder: {
				default: null,
				required: false,
				type: [String, Number],
			},
			page: {
				default: null,
				required: false,
				type: [String, Number],
			},
			title: {
				default: null,
				required: false,
				type: [String, Number],
			},
		},
	}
</script>
<style>
	.titlebreadcumb {
		font-size: 27px;
		color: #555555;
		margin: 10px 0 9px;
	}
	.menubreadcumb {
		padding: 6px 0; 
		color: #555555;
		font-size: 13px;
	}
</style>
